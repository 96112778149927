import React, { useState } from "react";
import { Button, Col, Container, Form, Modal } from "react-bootstrap";
import { User } from "../../models/user";
import { CreateUserFields } from "../../views/CreateAccount";

type UserEdit = User & { user_username?: string };

interface UserProfileProps {
  userProfile: UserEdit;
  setUserProfile: React.Dispatch<React.SetStateAction<UserEdit | undefined>>;
  updateProfile: () => boolean;
  errors: CreateUserFields;
}

interface ProfileFieldProps {
  editEnabled: boolean;
  label: string;
  value: string;
  onChange: (value: string) => void;
  type?: string;
  autoComplete?: string;
  error?: string;
}

const ProfileField: React.VFC<ProfileFieldProps> = (props: ProfileFieldProps) => (
  <Form.Group className="col-md-6 col-12">
    <Form.Label>{props.label}</Form.Label>
    <Form.Control
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
      plaintext={!props.editEnabled}
      disabled={!props.editEnabled}
      className={props.editEnabled ? "" : "font-weight-bold text-dark"}
      type={props.type}
      autoComplete={props.autoComplete}
      isInvalid={props.error !== undefined && props.error !== ""}
    />
    {props.error && <Form.Text className="text-danger">{props.error}</Form.Text>}
  </Form.Group>
);

const UserProfile: React.VFC<UserProfileProps> = (props: UserProfileProps) => {
  const [editEnabled, setEditEnabled] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleClose = () => setShowConfirm(false);

  return (
    <>
      <Modal show={showConfirm} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Veuillez confirmer les modifications</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            variant="success"
            onClick={() => {
              const valid = props.updateProfile();
              setEditEnabled(!valid);
              handleClose();
            }}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
      <Container className="shadow mt-4 mb-4 adaptative-width">
        <Form className="pb-4 pt-0 row pt-0">
          <Col className="col-12  justify-content-center d-flex  pb-2 pt-2 mb-3  bg-primary text-light  border-bottom">
            MES INFORMATIONS
          </Col>
          <ProfileField
            editEnabled={editEnabled}
            label="Prénom"
            value={props.userProfile?.first_name}
            error={props.errors.first_name}
            onChange={(value) => props.setUserProfile({ ...props.userProfile, first_name: value })}
            autoComplete="given-name"
          />
          <ProfileField
            editEnabled={editEnabled}
            label="Nom"
            value={props.userProfile?.last_name}
            error={props.errors.last_name}
            onChange={(value) => props.setUserProfile({ ...props.userProfile, last_name: value })}
            autoComplete="family-name"
          />
          <ProfileField
            editEnabled={editEnabled}
            label="Nom d'utilisateur"
            value={props.userProfile?.username}
            error={props.errors.user_username}
            onChange={(value) => props.setUserProfile({ ...props.userProfile, user_username: value })}
            autoComplete="username"
          />
          <ProfileField
            editEnabled={editEnabled}
            label="Email"
            value={props.userProfile?.email}
            error={props.errors.email}
            onChange={(value) => props.setUserProfile({ ...props.userProfile, email: value })}
            autoComplete="email"
          />
          <ProfileField
            editEnabled={editEnabled}
            label="Date de naissance"
            value={props.userProfile?.birthdate}
            error={props.errors.birthdate}
            onChange={(value) => props.setUserProfile({ ...props.userProfile, birthdate: value })}
            type="date"
            autoComplete="bday"
          />
          <ProfileField
            editEnabled={editEnabled}
            label="Numéro de téléphone"
            value={props.userProfile?.phone_number}
            error={props.errors.phone_number}
            onChange={(value) => props.setUserProfile({ ...props.userProfile, phone_number: value })}
            type="tel"
            autoComplete="tel"
          />
        </Form>
      </Container>
      <Container className="shadow  mt-4 mb-4 adaptative-width">
        <Form className="pb-4 pt-0 row pt-0">
          <Col className="col-12  justify-content-center d-flex pb-2 pt-2 mb-3  bg-primary text-light border-bottom">
            MON ENTREPRISE
          </Col>
          <ProfileField
            editEnabled={editEnabled}
            label="Nom de l'entreprise"
            value={props.userProfile?.company_name}
            error={props.errors.company_name}
            onChange={(value) => props.setUserProfile({ ...props.userProfile, company_name: value })}
            autoComplete="organization"
          />
          <ProfileField
            editEnabled={editEnabled}
            label="Siret de l'entreprise"
            value={props.userProfile?.company_siret}
            error={props.errors.company_siret}
            onChange={(value) => props.setUserProfile({ ...props.userProfile, company_siret: value })}
          />
          <ProfileField
            editEnabled={editEnabled}
            label="Adresse ligne 1"
            value={props.userProfile?.address_street_line1}
            error={props.errors.address_street_line1}
            onChange={(value) => props.setUserProfile({ ...props.userProfile, address_street_line1: value })}
            autoComplete="address-line1"
          />
          <ProfileField
            editEnabled={editEnabled}
            label="Adresse ligne 2"
            value={props.userProfile?.address_street_line2}
            error={props.errors.address_street_line2}
            onChange={(value) => props.setUserProfile({ ...props.userProfile, address_street_line2: value })}
            autoComplete="address-line2"
          />
          <ProfileField
            editEnabled={editEnabled}
            label="Code postal"
            value={props.userProfile?.address_zip_code.toString()}
            onChange={(value) => props.setUserProfile({ ...props.userProfile, address_zip_code: +value })}
            autoComplete="postal-code"
          />
          <ProfileField
            editEnabled={editEnabled}
            label="Ville"
            value={props.userProfile?.address_town}
            error={props.errors.address_town}
            onChange={(value) => props.setUserProfile({ ...props.userProfile, address_town: value })}
            autoComplete="address-level2"
          />
          <ProfileField
            editEnabled={editEnabled}
            label="Pays"
            value={props.userProfile?.address_country}
            error={props.errors.address_country}
            onChange={(value) => props.setUserProfile({ ...props.userProfile, address_country: value })}
            autoComplete="country-name"
          />
          <ProfileField
            editEnabled={editEnabled}
            label="Fonction"
            value={props.userProfile?.company_position}
            error={props.errors.company_position}
            onChange={(value) => props.setUserProfile({ ...props.userProfile, company_position: value })}
            autoComplete="organization-title"
          />
        </Form>
      </Container>

      <Col className="col-8  d-flex justify-content-center mr-auto ml-auto mb-4">
        {editEnabled ? (
          <>
            <Button variant="outline-danger mx-2" type="button" onClick={() => setEditEnabled(false)}>
              Retour en arrière
            </Button>
            <Button variant="success mx-2" type="button" onClick={() => setShowConfirm(true)}>
              Valider les modifications
            </Button>
          </>
        ) : (
          <Button variant="outline-secondary" type="button" onClick={() => setEditEnabled(true)}>
            Modifier le profil
          </Button>
        )}
      </Col>
    </>
  );
};

export default UserProfile;
