import React from "react";
import { Container } from "react-bootstrap";

export const Privacy: React.VFC = () => (
  <div className="text-start w-100 h-100 d-flex flex-column overflow-auto w-100">
    <Container className="my-4">
      <p className="font-weight-bold">
        <span>
          <span>Politique de confidentialité</span>
        </span>
      </p>
      <p>
        <span> </span>
      </p>
      <p>
        <span>
          La présente politique de confidentialité définit et vous informe de la manière dont Affinis utilise et protège
          les informations que vous nous transmettez, le cas échéant, lorsque vous utilisez le présent site accessible à
          partir de l’URL suivante : (ci-après le « Site »).
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <p>
        <span>
          Veuillez noter que cette politique de confidentialité est susceptible d’être modifiée ou complétée à tout
          moment par Affinis, notamment en vue de se conformer à toute évolution législative, réglementaire,
          jurisprudentielle ou technologique. Dans un tel cas, la date de sa mise à jour sera clairement identifiée en
          tête de la présente politique. Ces modifications engagent l’Utilisateur dès leur mise en ligne. Il convient
          par conséquent que l’Utilisateur consulte régulièrement la présente politique de confidentialité et
          d’utilisation des cookies afin de prendre connaissance de ses éventuelles modifications.
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <p>
        <span>I. DONNÉES PERSONNELLES</span>
      </p>
      <p>
        <span> </span>
      </p>
      <p>
        <span>
          Il vous est possible de visiter le Site de sans communiquer aucune information personnelle vous concernant. En
          toute hypothèse, vous n&apos;êtes en aucune manière obligé de transmettre ces informations à Affinis.
        </span>
      </p>
      <p>
        <span>
          Néanmoins, en cas de refus, il se peut que vous ne puissiez pas bénéficier de certaines informations ou
          services que vous avez demandés. A ce titre en effet, Affinis peut être amené dans certains cas à vous
          demander de renseigner vos nom, prénom, adresse mail, numéro de téléphone, entreprise et fonction (ci-après
          vos « Informations Personnelles »). En fournissant ces informations, vous acceptez expressément qu’elles
          soient traitées par Affinis, aux fins indiquées au point 2 ci-dessous ainsi qu’aux fins rappelées à la fin de
          chaque formulaire.
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <p>
        <span>
          Conformément au Règlement Général sur la Protection des Données (General Data Protection Régulation) adopté
          par le Parlement européen le 14 avril 2016, et à la Loi Informatique et Libertés du 6 janvier 1978 modifiée,
          Affinis vous informe des points suivants :
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <ul>
        <li>
          <p>
            <span>1. Identité du responsable du traitement </span>
            <span />
          </p>
        </li>
      </ul>
      <p>
        <span> </span>
      </p>
      <p>
        <span>
          Le responsable du traitement est la société Affinis, ayant son siège social au 81 Rue d&apos;Amsterdam 75008
          PARIS – France , SIRET 50845799100042.
        </span>
      </p>
      <p>
        <span>Tél. : + 33 (0)1 86 95 95 50</span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <ul>
        <li>
          <p>
            <span>2. Finalités du traitement</span>
            <span />
          </p>
        </li>
      </ul>
      <p>
        <span>Affinis est susceptible de traiter vos Informations Personnelles :</span>
      </p>
      <p>
        <span>
          (a) aux fins de vous fournir les informations ou les services que vous avez demandés (notamment : l’envoi de
          la Newsletter, offre commerciale, livres blancs ou encore l’évaluation de votre niveau de conformité via un
          quizz) ; et/ou
        </span>
      </p>
      <p>
        <span>
          (b) aux fins de recueillir des informations nous permettant d’améliorer notre Site, nos produits et services
          (notamment par le biais de cookies) ; et/ou
        </span>
      </p>
      <p>
        <span>
          (c) aux fins de pouvoir vous contacter à propos de différents événements relatifs à Affinis, incluant
          notamment la mise à jour des produits et le support client.
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <ul>
        <li>
          <p>
            <span>3. Destinataires</span>
            <span />
          </p>
        </li>
      </ul>
      <p>
        <span>
          Seul Affinis est destinataire de vos Informations Personnelles. Celles-ci, que ce soit sous forme individuelle
          ou agrégée, ne sont jamais transmises à un tiers, nonobstant les sous-traitants auxquels Affinis fait appel
          (vous trouverez de plus amples informations à leur sujet au point 7 ci-dessous). Ni Affinis, ni l’un
          quelconque de ses sous-traitants, ne procèdent à la commercialisation des données personnelles des visiteurs
          et Utilisateurs de son Site.
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <ul>
        <li>
          <p>
            <span>4. Durée de conservation</span>
            <span />
          </p>
        </li>
      </ul>
      <p>
        <span>
          Vos Informations Personnelles sont conservées par Affinis uniquement pour le temps correspondant à la finalité
          de la collecte tel qu’indiqué en 2 ci-dessus qui ne saurait en tout état de cause excéder 24 mois.
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <ul>
        <li>
          <p>
            <span>5. Droits Informatique et Libertés</span>
            <span />
          </p>
        </li>
      </ul>
      <p>
        <span>
          Vous disposez des droits suivants concernant vos Informations Personnelles, que vous pouvez exercer en nous
          écrivant à l’adresse postale mentionnée au point 1 ou en remplissant le formulaire ci-contre.
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <p>
        <span>- Droit d’accès et de communication des données</span>
      </p>
      <p>
        <span>Vous avez la faculté d’accéder aux Informations Personnelles qui vous concernent.</span>
      </p>
      <p>
        <span>
          Cependant, en raison de l’obligation de sécurité et de confidentialité dans le traitement des données à
          caractère personnel qui incombe à Affinis, vous êtes informé que votre demande sera traitée sous réserve que
          vous rapportiez la preuve de votre identité, notamment par la production{" "}
        </span>
        <span>
          d’un scan de votre titre d’identité valide (en cas de demande par notre formulaire électronique dédié) ou
          d’une photocopie signée de votre titre d’identité valide (en cas de demande adressée par écrit).
        </span>
      </p>
      <p>
        <span>
          Affinis vous informe qu’il sera en droit, le cas échéant, de s’opposer aux demandes manifestement abusives (de
          par leur nombre, leur caractère répétitif ou systématique).
        </span>
      </p>
      <p>
        <span>
          Pour vous aider dans votre démarche, notamment si vous désirez exercer votre droit d’accès par le biais d’une
          demande écrite à l’adresse postale mentionnée au point 1, vous trouverez{" "}
        </span>
        <a href="https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces">
          <span>
            <span>en cliquant sur le lien suivant</span>
          </span>
        </a>
        <span>
          {" "}
          un modèle de courrier élaboré par la Commission Nationale de l’Informatique et des Libertés (la « CNIL »).
        </span>
      </p>
      <p>
        <a href="https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces">
          <span>
            <span>https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces</span>
          </span>
        </a>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <p>
        <span>- Droit de rectification des données</span>
      </p>
      <p>
        <span>
          Au titre de ce droit, la législation vous habilite à demander la rectification, la mise à jour, le
          verrouillage ou encore l’effacement des données vous concernant qui peuvent s’avérer le cas échéant inexactes,
          erronées, incomplètes ou obsolètes.
        </span>
      </p>
      <p>
        <span>
          Également, vous pouvez définir des directives générales et particulières relatives au sort des données à
          caractère personnel après votre décès. Le cas échéant, les héritiers d’une personne décédée peuvent exiger de
          prendre en considération le décès de leur proche et/ou de procéder aux mises à jour nécessaires.
        </span>
      </p>
      <p>
        <span>
          Pour vous aider dans votre démarche, notamment si vous désirez exercer, pour votre propre compte ou pour le
          compte de l’un de vos proches décédé, votre droit de rectification par le biais d’une demande écrite à
          l’adresse postale mentionnée au point 1, vous trouverez{" "}
        </span>
        <a href="https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees">
          <span>
            <span>en cliquant sur le lien suivant</span>
          </span>
        </a>
        <span> un modèle de courrier élaboré par la CNIL.</span>
      </p>
      <p>
        <a href="https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees">
          <span>
            <span>https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees</span>
          </span>
        </a>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <p>
        <span>- Droit d’opposition</span>
      </p>
      <p>
        <span
          className="
T2"
        >
          L’exercice de ce droit n’est possible que dans l’une des deux situations suivantes :
        </span>
      </p>
      <ol>
        <li>
          <p>
            <span>1.</span>
            <span>Lorsque l’exercice de ce droit est fondé sur des motifs légitimes ; ou</span>
            <span />
          </p>
        </li>
        <li>
          <p>
            <span>2.</span>
            <span>
              Lorsque l’exercice de ce droit vise à faire obstacle à ce que les données recueillies soient utilisées à
              des fins de prospection commerciale.
            </span>
            <span />
          </p>
        </li>
      </ol>
      <p>
        <span>
          Pour vous aider dans votre démarche, notamment si vous désirez exercer votre droit d’opposition par le biais
          d’une demande écrite adressée à l’adresse postale indiquée au point 1, vous trouverez{" "}
        </span>
        <a href="https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees">
          <span>
            <span>en cliquant sur le lien suivant</span>
          </span>
        </a>
        <span> un modèle de courrier élaboré par la CNIL.</span>
      </p>
      <p>
        <a href="https://www.cnil.fr/fr/modele/courrier/supprimer-des-informations-vous-concernant-dun-site-internet">
          <span>
            <span>
              https://www.cnil.fr/fr/modele/courrier/supprimer-des-informations-vous-concernant-dun-site-internet
            </span>
          </span>
        </a>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <ul>
        <li>
          <p>
            <span>6. Délais de réponse </span>
            <span />
          </p>
        </li>
      </ul>
      <p>
        <span>
          Affinis s’engage à répondre à votre demande d’accès, de rectification ou d’opposition ou toute autre demande
          complémentaire d’informations dans un délai raisonnable qui ne saurait dépasser 1 mois à compter de la
          réception de votre demande.
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <ul>
        <li>
          <p>
            <span>7. Prestataires habilités et transfert vers un pays tiers de l’Union Européenne</span>
            <span />
          </p>
        </li>
      </ul>
      <p>
        <span>
          Affinis vous informe qu’il a recours à ses prestataires habilités pour faciliter le recueil et le traitement
          des données que vous nous avez communiqué. Ces prestataires peuvent être situés en dehors de l’Union
          Européenne et ont communication des données recueillies par le biais des divers formulaires présents sur le
          Site (à l’exception du formulaire vous permettant d’exercer vos droits Informatique et Libertés qui est
          proposé et exploité par Affinis).
        </span>
      </p>
      <p>
        <span>
          Affinis s’est préalablement assuré de la mise en œuvre par ses prestataires de garanties adéquates et du
          respect de conditions strictes en matière de confidentialité, d’usage et de protection des données. Tout
          particulièrement, la vigilance s’est portée sur l’existence d’un fondement légal pour effectuer un quelconque
          transfert de données vers un pays tiers. A ce titre, l’un de nos prestataires est soumis à des règles internes
          d’entreprise (ou « Binding Corporate Rules ») qui ont été approuvées par la CNIL en 2016 quand les autres
          obéissent non seulement à des Clauses Contractuelles Types mais également au Privacy Shield.
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <ul>
        <li>
          <p>
            <span>8. Plainte auprès de l’autorité compétente</span>
            <span />
          </p>
        </li>
      </ul>
      <p>
        <span>
          Si vous considérez que Affinis ne respecte pas ses obligations au regard de vos Informations Personnelles,
          vous pouvez adresser une plainte ou une demande auprès de l’autorité compétente. En France, l’autorité
          compétente est la CNIL à laquelle vous pouvez adresser une demande par voie électronique en cliquant sur le
          lien suivant :{" "}
        </span>
        <a href="https://www.cnil.fr/fr/plaintes/internet">
          <span>
            <span>https://www.cnil.fr/fr/plaintes/internet</span>
          </span>
        </a>
        <span>.</span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <p>
        <span>II. POLITIQUE RELATIVE AUX COOKIES</span>
      </p>
      <p>
        <span> </span>
      </p>
      <p>
        <span>
          Lors de votre première connexion sur le site web de vous êtes avertis par un bandeau en bas de votre écran que
          des informations relatives à votre navigation sont susceptibles d’être enregistrées dans des fichiers dénommés
          « cookies ». Notre politique d’utilisation des cookies vous permet de mieux comprendre les dispositions que
          nous mettons en œuvre en matière de navigation sur notre site web. Elle vous informe notamment sur l’ensemble
          des cookies présents sur notre site web, leur finalité (partie I.) et vous donne la marche à suivre pour les
          paramétrer (partie II.)
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <ul>
        <li>
          <p>
            <span>1. Informations générales sur les cookies présents sur le site de </span>
            <span />
          </p>
        </li>
      </ul>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <p>
        <span>
          en tant qu’éditeur du présent site web, pourra procéder à l’implantation d’un cookie sur le disque dur de
          votre terminal (ordinateur, tablette, mobile etc.) afin de vous garantir une navigation fluide et optimale sur
          notre site Internet.
        </span>
      </p>
      <p>
        <span>
          Les « cookies » (ou témoins de connexion) sont des petits fichiers texte de taille limitée qui nous permettent
          de reconnaître votre ordinateur, votre tablette ou votre mobile aux fins de personnaliser les services que
          nous vous proposons.
        </span>
      </p>
      <p>
        <span>
          Les informations recueillies par le biais des cookies ne permettent en aucune manière de vous identifier
          nominativement. Elles sont utilisées exclusivement pour nos besoins propres afin d’améliorer l’interactivité
          et la performance de notre site web et de vous adresser des contenus adaptés à vos centres d’intérêts. Aucune
          de ces informations ne fait l’objet d’une communication auprès de tiers sauf lorsque Affinis a obtenu au
          préalable votre consentement ou bien lorsque la divulgation de ces informations est requise par la loi, sur
          ordre d’un tribunal ou toute autorité administrative ou judiciaire habilitée à en connaître.
        </span>
      </p>
      <p>
        <span>
          Pour mieux vous éclairer sur les informations que les cookies identifient, vous trouverez ci-dessous un
          tableau listant les différents types de cookies susceptibles d’être utilisés sur le site web de leur nom, leur
          finalité ainsi que leur durée de conservation.
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <ul>
        <li>
          <p>
            <span>2. Configuration de vos préférences sur les cookies</span>
            <span />
          </p>
        </li>
      </ul>
      <p>
        <span>Vous pouvez accepter ou refuser le dépôt de cookies à tout moment</span>
      </p>
      <p>
        <span>
          Lors de votre première connexion sur le site web de une bannière présentant brièvement des informations
          relatives au dépôt de cookies et de technologies similaires apparaît en bas de votre écran. Cette bannière
          vous avertit qu’en poursuivant votre navigation sur le site web de (en chargeant une nouvelle page ou en
          cliquant sur divers éléments du site par exemple), vous acceptez le dépôt de cookies sur votre terminal. Vous
          êtes également réputé avoir donné votre accord au dépôt de cookies en cliquant sur l’icône « X » à droite de
          la bannière figurant en bas de votre écran.
        </span>
      </p>
      <p>
        <span>
          Selon le type de cookie en cause, le recueil de votre consentement au dépôt et à la lecture de cookies sur
          votre terminal peut être impératif.
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <p>
        <span>a. Les cookies exemptés de consentement</span>
      </p>
      <p>
        <span>
          Conformément aux recommandations de la Commission Nationale de l’Informatique et des Libertés (CNIL), certains
          cookies sont dispensés du recueil préalable de votre consentement dans la mesure où ils sont strictement
          nécessaires au fonctionnement du site internet ou ont pour finalité exclusive de permettre ou faciliter la
          communication par voie électronique. Il s’agit notamment des cookies d’identifiant de session,
          d’authentification, de session d’équilibrage de charge ainsi que des cookies de personnalisation de votre
          interface. Ces cookies sont intégralement soumis à la présente politique dans la mesure où ils sont émis et
          gérés par Affinis.
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <p>
        <span>b. Les cookies nécessitant le recueil préalable de votre consentement</span>
      </p>
      <p>
        <span>
          Cette exigence concerne les cookies émis par des tiers et qui sont qualifiés de « persistants » dans la mesure
          où ils demeurent dans votre terminal jusqu’à leur effacement ou leur date d’expiration.
        </span>
      </p>
      <p>
        <span>
          De tels cookies étant émis par des tiers, leur utilisation et leur dépôt sont soumis à leurs propres
          politiques de confidentialité dont vous trouverez un lien ci-dessous. Cette famille de cookie regroupe les
          cookies de mesure d’audience (notamment PIWIK), les cookies publicitaires (auxquels Affinis n’a pas recours)
          ainsi que les cookies de partage de réseaux sociaux (notamment de Facebook, YouTube, Twitter et LinkedIn).
        </span>
      </p>
      <p>
        <span>
          Les cookies de mesure d’audience établissent des statistiques concernant la fréquentation et l’utilisation de
          divers éléments du site web (comme les contenus/pages que vous avez visité). Ces données participent à
          l’amélioration de l’ergonomie du site web de Un outil de mesure d’audience est utilisé sur le présent site
          internet :
        </span>
      </p>
      <p>
        <span>
          - PIWIK dont la politique de confidentialité est disponible (uniquement en anglais) à partir du lien suivant :{" "}
        </span>
        <a href="https://piwik.org/privacy-policy/">
          <span>
            <span>https://piwik.org/privacy-policy/</span>
          </span>
        </a>
      </p>
      <p>
        <span>
          Les cookies de partage des réseaux sociaux sont émis et gérés par l’éditeur du réseau social concerné. Sous
          réserve de votre consentement, ces cookies vous permettent de partager facilement une partie du contenu publié
          sur le site de notamment par l’intermédiaire d’un « bouton » applicatif de partage selon le réseau social
          concerné. Quatre types de cookies de partage des réseaux sociaux sont présents sur le site de :
        </span>
      </p>
      <p>
        <span>
          - Facebook, dont vous pouvez consulter la politique en matière de cookies en cliquant sur le lien suivant :{" "}
        </span>
        <a href="https://fr-fr.facebook.com/policies/cookies/">
          <span>
            <span>https://fr-fr.facebook.com/policies/cookies/</span>
          </span>
        </a>
      </p>
      <p>
        <span>
          - LinkedIn, dont vous trouverez la politique relative aux cookies en cliquant sur le lien suivant :{" "}
        </span>
        <a href="https://www.linkedin.com/legal/cookie-policy?_l=fr_FR">
          <span>
            <span>https://www.linkedin.com/legal/cookie-policy?_l=fr_FR</span>
          </span>
        </a>
      </p>
      <p>
        <span>
          - Twitter, dont vous trouverez les options dédiées au contrôle ou à la restriction de l’utilisation des
          cookies ainsi que la politique d’utilisation des cookies :{" "}
        </span>
        <a href="https://support.twitter.com/articles/20170518">
          <span>
            <span>https://support.twitter.com/articles/20170518#</span>
          </span>
        </a>
      </p>
      <p>
        <span>
          - YouTube, dont vous trouverez l’aide nécessaire pour supprimer les cookies à partir du navigateur Google
          Chrome en cliquant sur le lien suivant :{" "}
        </span>
        <a href="https://support.google.com/youtube/answer/32050?hl=fr">
          <span>
            <span>https://support.google.com/youtube/answer/32050?hl=fr</span>
          </span>
        </a>
        <span> mais également la politique complète en matière de cookies à partir du lien suivant : </span>
        <a href="https://www.google.fr/intl/fr/policies/technologies/cookies/">
          <span>
            <span>https://www.google.fr/intl/fr/policies/technologies/cookies/</span>
          </span>
        </a>
      </p>
      <p>
        <span>
          - Wix, service hébergeant notre site internet et auprès duquel nous effectuerons en l&apos;occurrence les
          requêtes de droit à la communication, rectification, à l&apos;oubli, si vous nous en faites la demande selon
          les conditions formulées dans cette politique de confidentialité. 
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <p>
        <span>c. Vous disposez de divers outils de paramétrage des cookies</span>
      </p>
      <p>
        <span>
          La plupart des navigateurs Internet sont configurés par défaut de façon à ce que le dépôt de cookies soit
          autorisés. Votre navigateur vous offre l’opportunité de modifier ces paramètres{" "}
        </span>
        <span>
          standards de manière à ce que l’ensemble des cookies soit rejeté systématiquement ou bien à ce qu’une partie
          seulement des cookies soit acceptée ou refusée en fonction de leur émetteur.
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <p>
        <span>ATTENTION :</span>
        <span>
          {" "}
          Nous attirons votre attention sur le fait que le refus du dépôt de cookies sur votre terminal est néanmoins
          susceptible d’altérer votre expérience d’utilisateur ainsi que votre accès à certains services ou
          fonctionnalités du présent site web. Le cas échéant, Affinis décline toute responsabilité concernant les
          conséquences liées à la dégradation de vos conditions de navigation qui interviennent en raison de votre choix
          de refuser, supprimer ou bloquer les cookies nécessaires au fonctionnement du site. Ces conséquences ne
          sauraient constituer un dommage et vous ne pourrez prétendre à aucune indemnité de ce fait.
        </span>
      </p>
      <p>
        <span>
          Votre navigateur vous permet également de supprimer les cookies existants sur votre terminal ou encore de vous
          signaler lorsque de nouveaux cookies sont susceptibles d’être déposés sur votre terminal. Ces paramétrages
          n’ont pas d’incidence sur votre navigation mais vous font perdre tout le bénéfice apporté par le cookie.
        </span>
      </p>
      <p>
        <span>
          Veuillez ci-dessous prendre connaissance des multiples outils mis à votre disposition afin que vous puissiez
          paramétrer les cookies déposés sur votre terminal.
        </span>
      </p>
      <p>
        <span>
          <span>​</span>
        </span>
      </p>
      <p>
        <span>d. Le paramétrage de votre navigateur Internet</span>
      </p>
      <p>
        <span>
          Chaque navigateur Internet propose ses propres paramètres de gestion des cookies. Pour savoir de quelle
          manière modifier vos préférences en matière de cookies, vous trouverez ci-dessous les liens vers l’aide
          nécessaire pour accéder au menu de votre navigateur prévu à cet effet :
        </span>
      </p>
      <p>
        <span>Chrome : </span>
        <a href="https://support.google.com/chrome/answer/95647?hl=fr">
          <span>
            <span>https://support.google.com/chrome/answer/95647?hl=fr</span>
          </span>
        </a>
      </p>
      <p>
        <span>Firefox : </span>
        <a href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies">
          <span>
            <span>https://support.mozilla.org/fr/kb/activer-desactiver-cookies</span>
          </span>
        </a>
      </p>
      <p>
        <span>Internet Explorer : </span>
        <a href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11">
          <span>
            <span>
              https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11
            </span>
          </span>
        </a>
      </p>
      <p>
        <span>Opera : </span>
        <a href="http://help.opera.com/Windows/10.20/fr/cookies.html">
          <span>
            <span>http://help.opera.com/Windows/10.20/fr/cookies.html</span>
          </span>
        </a>
      </p>
      <p>
        <span>Safari : </span>
        <a href="https://support.apple.com/kb/PH21411?viewlocale=fr_FR&amp;locale=fr_FR">
          <span>
            <span>https://support.apple.com/kb/PH21411?viewlocale=fr_FR&amp;locale=fr_FR</span>
          </span>
        </a>
      </p>
      <p>
        <span> </span>
      </p>
      <p>
        <span>
          Pour de plus amples informations concernant les outils de maîtrise des cookies, vous pouvez consulter le site
          internet de la CNIL :{" "}
        </span>
        <a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser">
          <span>
            <span>https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser</span>
          </span>
        </a>
        <span>.</span>
      </p>
      <p>
        <span>e. Le paramétrage de l’outil tarteaucitron</span>
      </p>
      <p>
        <span>
          La désactivation des cookies peut également se faire directement via l’outil tarteaucitron mis en place sur le
          site SOS RGPD. Interactif, il permet de gérer vos cookies via un bandeau disponible en bas à droite de la page
          visionnée (bouton « Gestion des services ») et liste l’ensemble des cookies actifs. Pour désactiver un cookie,
          il suffit de cliquer sur la croix proche du service{" "}
        </span>
        <span>
          proposé. Si l’ensemble des cookies sont désactivés, le bandeau dédié à recueillir le consentement de
          l’utilisateur réapparaîtra lors du rafraîchissement de la page.
        </span>
      </p>
      <p>
        <span>
          Pour toute interrogation ou demande complémentaire d’informations relative à la présente politique des
          cookies, merci de bien vouloir nous contacter par le biais de notre formulaire.
        </span>
      </p>
      <p> </p>
    </Container>
  </div>
);
