import React from "react";
import { Button, Container } from "react-bootstrap";
import { useHistory } from "react-router";

const PasswordResetDone: React.FC = () => {
  const history = useHistory();

  return (
    <div>
      <Container className="p-4">
        <div className="bg-white shadow m-auto p-4">
          <p>Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse.</p>
          <Button block variant="primary" onClick={() => history.push("/")}>
            Revenir à l&apos;accueil
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default PasswordResetDone;
