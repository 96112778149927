import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./Home.css";
import axios from "axios";
import { Website } from "../models/website";
import { useAuth } from "../utils/auth";
import BottomBar from "../components/BottomBar";
import { LoadingPage } from "../components/LoadingPage";

const Home: React.VFC = () => {
  const [proxxiID, setProxxiID] = useState("");
  const [proxxiINTEL, setProxxiINTEL] = useState("");
  const [proxxiMAP, setProxxiMAP] = useState("");
  const [proxxiEXPLORE, setProxxiEXPLORE] = useState("");

  const {
    state: { connected },
  } = useAuth();

  useEffect(() => {
    axios
      .get<Website[]>(process.env.REACT_APP_API_URL + "/website/")
      .then((res) => {
        setProxxiID(res.data[0].proxxi_id_bg);
        setProxxiINTEL(res.data[0].proxxi_intel_bg);
        setProxxiMAP(res.data[0].proxxi_map_bg);
        setProxxiEXPLORE(res.data[0].proxxi_explore_bg);
      })
      .catch(() => {
        return;
      });
  }, [connected]);

  return (
    <Container fluid className="w-100 h-100 cont px-0 d-flex flex-column">
      <LoadingPage loading={proxxiID === "" || proxxiINTEL === "" || proxxiMAP === "" || proxxiEXPLORE === ""} />
      <Row className="mb-0 justify-content-center">
        <Col className="overflow-hidden p-0 col-12 col-md-6 justify-content-center image cursor-pointer">
          <Link to="/proxxiid">
            <img className="bg-img" src={proxxiID} alt="PROXXI ID" />
            <div className="w-100 h-100 cover">
              <div className="d-1 bg-label text-center cursor-pointer ">
                <h2 className="title text-nowrap mb-4">PROXXI ID</h2>
                <p className="d-lg-inline comment">
                  <strong>+2000</strong> FICHES D&apos;ACTEURS STRATÉGIQUES DE DÉFENSE
                  <br />
                  DANS LE MONDE
                </p>
              </div>
            </div>
          </Link>
        </Col>
        <Col className="overflow-hidden p-0 col-12 col-md-6 justify-content-center image cursor-pointer">
          <Link to="/proxximap">
            <img className="bg-img" src={proxxiMAP} alt="PROXXI MAP" />
            <div className="w-100 h-100 cover">
              <div className="d-1 bg-label text-center cursor-pointer ">
                <h2 className="title text-nowrap mb-4">PROXXI MAP</h2>
                <p className="d-lg-inline comment">
                  GÉOLOCALISATION INSTANTANÉE DES ACTEURS CLÉS
                  <br />
                  DE VOTRE ÉCOSYSTÈME
                </p>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
      <Row className="mb-0 justify-content-center">
        <Col className="overflow-hidden p-0 col-12 col-md-6 justify-content-center image cursor-pointer ">
          <Link to="/proxxiexplore">
            <img className="bg-img" src={proxxiEXPLORE} alt="PROXXI EXPLORE" />
            <div className="w-100 h-100 cover">
              <div className="d-1 bg-label text-center cursor-pointer ">
                <h2 className="title text-nowrap mb-4">PROXXI EXPLORE</h2>
                <p className="d-lg-inline comment">
                  CARTOGRAPHIES DYNAMIQUES
                  <br />
                  ET INTERACTIVES
                  <br />
                  DES RÉSEAUX D&apos;ACTEURS
                </p>
              </div>
            </div>
          </Link>
        </Col>
        <Col className="overflow-hidden p-0 col-12 col-md-6 justify-content-center image cursor-pointer ">
          <Link to="/proxxiintel">
            <img className="bg-img" src={proxxiINTEL} alt="PROXXI INTEL" />
            <div className="w-100 h-100 cover">
              <div className="d-1 bg-label text-center cursor-pointer ">
                <h2 className="title text-nowrap mb-4">PROXXI INTEL</h2>
                <p className="d-lg-inline comment">
                  ÉTUDES DE MARCHÉ SUR DES SECTEURS, DES TECHNOLOGIES
                  <br />
                  ET DES ACTEURS CIBLES
                </p>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
      <BottomBar />
    </Container>
  );
};

export default Home;
