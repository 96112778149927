import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Container, Form, Col, Row, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { login, useAuth } from "../utils/auth";
import { wrapAsync } from "../utils/context";

import "./Profile.css";

export interface CreateUserFields {
  user_username?: string;
  email?: string;
  password?: string;
  passwordConfirm?: string;
  first_name?: string;
  last_name?: string;
  birthdate?: string;
  phone_number?: string;
  address_street_line1?: string;
  address_street_line2?: string;
  address_town?: string;
  address_zip_code?: string;
  address_country?: string;
  company_name?: string;
  company_siret?: string;
  company_position?: string;
}

type CreateUserErrors = CreateUserFields;

const CreateAccount: React.VFC = () => {
  const [userProfile, setUserProfile] = useState<CreateUserFields>({});
  const [errors, setErrors] = useState<CreateUserErrors>({});
  const [showConfirm, setShowConfirm] = useState(false);

  const history = useHistory();

  const {
    state: { connected },
    dispatch,
  } = useAuth();
  const asyncDispatch = useMemo(() => wrapAsync(dispatch), [dispatch]);

  useEffect(() => {
    if (connected) {
      history.push("/");
    }
  }, [connected]);

  const handleClose = () => setShowConfirm(false);

  const createUser = () => {
    setErrors({});
    if (!userProfile?.user_username) {
      setErrors((old) => ({ ...old, user_username: "Ce champ est obligatoire." }));
      return;
    }
    if (userProfile?.user_username.match(/[^\w@.+-]/) != null) {
      setErrors((old) => ({ ...old, user_username: "Nom d'utilisateur invalide." }));
      return;
    }
    if (!userProfile?.email) {
      setErrors((old) => ({ ...old, email: "Ce champ est obligatoire." }));
      return;
    }
    if (!userProfile?.password) {
      setErrors((old) => ({ ...old, password: "Ce champ est obligatoire." }));
      return;
    }
    if (!userProfile?.passwordConfirm) {
      setErrors((old) => ({ ...old, passwordConfirm: "Ce champ est obligatoire." }));
    }
    if (userProfile?.password !== userProfile?.passwordConfirm) {
      setErrors((old) => ({ ...old, password: "Les mots de passe ne correspondent pas." }));
      setErrors((old) => ({ ...old, passwordConfirm: "Les mots de passe ne correspondent pas." }));
      return;
    }
    axios
      .post(process.env.REACT_APP_API_URL + "/users/", userProfile)
      .then(() => history.push("/login"))
      .catch((err) =>
        Object.entries(err.response.data).forEach(([k, v]) =>
          setErrors((old) => ({ ...old, [k]: (v as string[]).join(" ") })),
        ),
      )
      .then(() => {
        if (userProfile.user_username && userProfile.password) {
          asyncDispatch(
            login(userProfile.user_username, userProfile.password, () => {
              return;
            }),
          );
        }
      });
  };

  return (
    <div className="w-100 h-100" style={{ overflowY: "scroll" }}>
      <Modal show={showConfirm} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Veuillez confirmer la création de l&apos;utilisateur</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            variant="success"
            onClick={() => {
              createUser();
              handleClose();
            }}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
      <Container className="shadow my-4">
        <Row>
          <Col className="col-12  justify-content-center d-flex  pb-2 pt-2 mb-3  bg-primary text-light  border-bottom">
            CRÉER UN COMPTE
          </Col>
        </Row>
        <Form className="pb-4">
          <Col>
            <Row>
              <h5>Informations de connexion</h5>
            </Row>
            <Row>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Nom d&apos;utilisateur</Form.Label>
                <Form.Control
                  value={userProfile?.user_username}
                  onChange={(e) => setUserProfile({ ...userProfile, user_username: e.target.value })}
                  autoComplete="username"
                  placeholder="Nom d'utilisateur"
                  isInvalid={errors?.user_username !== undefined && errors.user_username !== ""}
                />
                {errors?.user_username && <Form.Text className="text-danger">{errors?.user_username}</Form.Text>}
                <Form.Text className="text-muted">
                  {"Uniquement des lettres, nombres et les caractères « @ », « . », « + », « - » et « _ »."}
                </Form.Text>
              </Form.Group>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  value={userProfile?.email}
                  onChange={(e) => setUserProfile({ ...userProfile, email: e.target.value })}
                  type="email"
                  autoComplete="email"
                  placeholder="jean.dupont@entreprise.fr"
                  isInvalid={errors?.email !== undefined && errors.email !== ""}
                />
                {errors?.email && <Form.Text className="text-danger">{errors?.email}</Form.Text>}
              </Form.Group>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Mot de passe</Form.Label>
                <Form.Control
                  type="password"
                  value={userProfile?.password}
                  onChange={(e) => setUserProfile({ ...userProfile, password: e.target.value })}
                  autoComplete="new-password"
                  placeholder="Mot de passe"
                  isInvalid={errors?.password !== undefined && errors.password !== ""}
                />
                {errors?.password && <Form.Text className="text-danger">{errors?.password}</Form.Text>}
                <Form.Text className="text-muted">
                  Le mot de passe doit contenir au minimum 8 caractères. Privilégiez les mots de passes complexes avec
                  des lettres, des chiffres et des caractères spéciaux.
                </Form.Text>
              </Form.Group>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Confirmez votre mot de passe</Form.Label>
                <Form.Control
                  type="password"
                  value={userProfile?.passwordConfirm}
                  onChange={(e) => setUserProfile({ ...userProfile, passwordConfirm: e.target.value })}
                  autoComplete="new-password"
                  placeholder="Confirmez le mot de passe"
                  isInvalid={errors?.passwordConfirm !== undefined && errors.passwordConfirm !== ""}
                />
                {errors?.passwordConfirm && <Form.Text className="text-danger">{errors?.passwordConfirm}</Form.Text>}
              </Form.Group>
            </Row>
            <Row>
              <h5>Informations personnelles</h5>
            </Row>
            <Row>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Prénom</Form.Label>
                <Form.Control
                  value={userProfile?.first_name}
                  onChange={(e) => setUserProfile({ ...userProfile, first_name: e.target.value })}
                  autoComplete="given-name"
                  placeholder="Jean"
                  isInvalid={errors?.first_name !== undefined && errors.first_name !== ""}
                />
                {errors?.first_name && <Form.Text className="text-danger">{errors?.first_name}</Form.Text>}
              </Form.Group>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Nom</Form.Label>
                <Form.Control
                  value={userProfile?.last_name}
                  onChange={(e) => setUserProfile({ ...userProfile, last_name: e.target.value })}
                  autoComplete="family-name"
                  placeholder="Dupont"
                  isInvalid={errors?.last_name !== undefined && errors.last_name !== ""}
                />
                {errors?.last_name && <Form.Text className="text-danger">{errors?.last_name}</Form.Text>}
              </Form.Group>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Numéro de téléphone</Form.Label>
                <Form.Control
                  value={userProfile?.phone_number}
                  onChange={(e) => setUserProfile({ ...userProfile, phone_number: e.target.value })}
                  autoComplete="tel"
                  placeholder="0123456789"
                  isInvalid={errors?.phone_number !== undefined && errors.phone_number !== ""}
                />
                {errors?.phone_number && <Form.Text className="text-danger">{errors?.phone_number}</Form.Text>}
              </Form.Group>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Date de naissance</Form.Label>
                <Form.Control
                  value={userProfile?.birthdate}
                  onChange={(e) => setUserProfile({ ...userProfile, birthdate: e.target.value })}
                  type="date"
                  autoComplete="bday"
                  isInvalid={errors?.birthdate !== undefined && errors.birthdate !== ""}
                />
                {errors?.birthdate && <Form.Text className="text-danger">{errors?.birthdate}</Form.Text>}
              </Form.Group>
            </Row>
            <Row>
              <h5>Entreprise</h5>
            </Row>
            <Row>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Nom de l&apos;entreprise</Form.Label>
                <Form.Control
                  value={userProfile?.company_name}
                  onChange={(e) => setUserProfile({ ...userProfile, company_name: e.target.value })}
                  autoComplete="organization"
                  placeholder="Entreprise"
                  isInvalid={errors?.company_name !== undefined && errors.company_name !== ""}
                />
                {errors?.company_name && <Form.Text className="text-danger">{errors?.company_name}</Form.Text>}
              </Form.Group>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Siret de l&apos;entreprise</Form.Label>
                <Form.Control
                  value={userProfile?.company_siret}
                  onChange={(e) => setUserProfile({ ...userProfile, company_siret: e.target.value })}
                  placeholder="12345678912345"
                  isInvalid={errors?.company_siret !== undefined && errors.company_siret !== ""}
                />
                {errors?.company_siret && <Form.Text className="text-danger">{errors?.company_siret}</Form.Text>}
              </Form.Group>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Adresse ligne 1</Form.Label>
                <Form.Control
                  value={userProfile?.address_street_line1}
                  onChange={(e) => setUserProfile({ ...userProfile, address_street_line1: e.target.value })}
                  autoComplete="address-line1"
                  placeholder="1 rue de Paris"
                  isInvalid={errors?.address_street_line1 !== undefined && errors.address_street_line1 !== ""}
                />
                {errors?.address_street_line1 && (
                  <Form.Text className="text-danger">{errors?.address_street_line1}</Form.Text>
                )}
              </Form.Group>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Adresse ligne 2</Form.Label>
                <Form.Control
                  value={userProfile?.address_street_line2}
                  onChange={(e) => setUserProfile({ ...userProfile, address_street_line2: e.target.value })}
                  autoComplete="address-line2"
                  placeholder="3ème étage"
                  isInvalid={errors?.address_street_line2 !== undefined && errors.address_street_line2 !== ""}
                />
                {errors?.address_street_line2 && (
                  <Form.Text className="text-danger">{errors?.address_street_line2}</Form.Text>
                )}
              </Form.Group>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Code postal</Form.Label>
                <Form.Control
                  value={userProfile?.address_zip_code}
                  onChange={(e) => setUserProfile({ ...userProfile, address_zip_code: e.target.value })}
                  autoComplete="postal-code"
                  placeholder="75001"
                  isInvalid={errors?.address_zip_code !== undefined && errors.address_zip_code !== ""}
                />
                {errors?.address_zip_code && <Form.Text className="text-danger">{errors?.address_zip_code}</Form.Text>}
              </Form.Group>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Ville</Form.Label>
                <Form.Control
                  value={userProfile?.address_town}
                  onChange={(e) => setUserProfile({ ...userProfile, address_town: e.target.value })}
                  autoComplete="address-level2"
                  placeholder="Paris"
                  isInvalid={errors?.address_town !== undefined && errors.address_town !== ""}
                />
                {errors?.address_town && <Form.Text className="text-danger">{errors?.address_town}</Form.Text>}
              </Form.Group>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Pays</Form.Label>
                <Form.Control
                  value={userProfile?.address_country}
                  onChange={(e) => setUserProfile({ ...userProfile, address_country: e.target.value })}
                  autoComplete="country-name"
                  placeholder="France"
                  isInvalid={errors?.address_country !== undefined && errors.address_country !== ""}
                />
                {errors?.address_country && <Form.Text className="text-danger">{errors?.address_country}</Form.Text>}
              </Form.Group>
              <Form.Group className="col-sm-6 col-xs-12">
                <Form.Label>Fonction</Form.Label>
                <Form.Control
                  value={userProfile?.company_position}
                  onChange={(e) => setUserProfile({ ...userProfile, company_position: e.target.value })}
                  autoComplete="organization-title"
                  placeholder="Directeur"
                  isInvalid={errors?.company_position !== undefined && errors.company_position !== ""}
                />
                {errors?.company_position && <Form.Text className="text-danger">{errors?.company_position}</Form.Text>}
              </Form.Group>
            </Row>
            <Row className="d-flex justify-content-center">
              <Button size="lg" variant="primary" type="button" onClick={() => setShowConfirm(true)}>
                Créer le compte
              </Button>
            </Row>
          </Col>
        </Form>
      </Container>
    </div>
  );
};

export default CreateAccount;
