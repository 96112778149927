import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import Contact from "../components/Profile/Contact";

import Orders from "../components/Profile/Orders";
import Settings from "../components/Profile/Settings";
import UserProfile from "../components/Profile/UserProfile";
import { User } from "../models/user";
import "./Profile.css";
import Subscription from "../components/Profile/Subscription";
import { useAuth } from "../utils/auth";
import { CreateUserFields } from "./CreateAccount";

type Page = "profile" | "orders" | "contact" | "subscription" | "settings";

const Profile: React.FC<RouteComponentProps> = () => {
  const [userProfile, setUserProfile] = useState<User>();
  const [errors, setErrors] = useState<CreateUserFields>({});
  const [showSideMenu, setShowSideMenu] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  const {
    state: { connected },
  } = useAuth();

  const getUserProfile = () =>
    axios
      .get<User>(process.env.REACT_APP_API_URL + "/users/me/")
      .then((res) => setUserProfile(res.data))
      .catch(() => {
        return;
      });

  const { page } = useParams<{ page: Page }>();
  const history = useHistory();

  useEffect(() => {
    getUserProfile();
  }, []);

  const updateProfile = () => {
    let valid = true;
    axios.patch(process.env.REACT_APP_API_URL + `/users/${userProfile?.id}/`, userProfile).catch((err) => {
      Object.entries(err.response.data).forEach(([k, v]) =>
        setErrors((old) => ({ ...old, [k]: (v as string[]).join(" ") })),
      );
      valid = false;
    });
    return valid;
  };

  const switchPage = (p: Page) => {
    history.push(`/profile/${p}`);
    if (width <= 768) {
      setShowSideMenu(false);
    }
  };

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  return (
    <Row className="h-100 w-100 m-0">
      <Button
        id="collapse-btn"
        className="border"
        style={{ left: showSideMenu ? (width > 768 ? "25%" : "calc(100% - 49px)") : "-1px" }}
        onClick={() => setShowSideMenu(!showSideMenu)}
      >
        <ArrowRight
          id="collapse-arrow"
          style={{ transform: showSideMenu ? "rotate(180deg)" : "rotate(0deg)" }}
          size={24}
        />
      </Button>

      <Col className="p-0 position-fixed border col-md-3 col-12 side-menu overflow-hidden mh-100 bg-light">
        {userProfile && connected && (
          <>
            <div
              className={`p-4 w-100 border-bottom bg-${page === "profile" ? "chosen" : "white"} menu-option`}
              onClick={() => switchPage("profile")}
            >
              MON PROFIL
            </div>
            <div
              className={`p-4 w-100 border-bottom bg-${page === "orders" ? "chosen" : "white"} menu-option`}
              onClick={() => switchPage("orders")}
            >
              MES COMMANDES
            </div>
            <div
              className={`p-4 w-100 border-bottom bg-${page === "subscription" ? "chosen" : "white"} menu-option`}
              onClick={() => switchPage("subscription")}
            >
              MON ABONNEMENT
            </div>
            <div
              className={`p-4 w-100 border-bottom bg-${page === "settings" ? "chosen" : "white"} menu-option`}
              onClick={() => switchPage("settings")}
            >
              PARAMÈTRES
            </div>
          </>
        )}
        <div
          className={`p-4 w-100 border-bottom bg-${page === "contact" ? "chosen" : "white"} menu-option`}
          onClick={() => switchPage("contact")}
        >
          CONTACT
        </div>
      </Col>
      <Col
        className={`${showSideMenu ? "col-9" : "col-12"} results overflow-auto mh-100 bg-white w-100`}
        style={{ left: showSideMenu ? (width > 768 ? "25%" : "100%") : "0" }}
      >
        {page === "profile" &&
          (userProfile ? (
            <UserProfile
              userProfile={userProfile}
              setUserProfile={setUserProfile}
              updateProfile={updateProfile}
              errors={errors}
            />
          ) : null)}
        {page === "orders" && <Orders />}
        {page === "subscription" && <Subscription />}
        {page === "contact" && <Contact />}
        {page === "settings" && userProfile?.id && <Settings userId={userProfile.id} />}
      </Col>
    </Row>
  );
};

export default Profile;
