import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Cart4 } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";

import { useAuth } from "../utils/auth";
import { useCart } from "../utils/cart";
import logoWhite from "../utils/logo_white.png";

const Topbar: React.FC = () => {
  const [name, setName] = useState("");
  const {
    state: { connected, isAdmin },
    dispatch: authDispatch,
  } = useAuth();

  const {
    state: { items },
  } = useCart();

  useEffect(() => {
    if (connected) {
      axios.get(process.env.REACT_APP_API_URL + "/users/me/").then(({ data }) => {
        setName(`${data.first_name} ${data.last_name}`);
        if (data.is_staff) {
          authDispatch({ type: "setIsAdmin" });
        }
      });
    }
  }, [connected]);

  const closeNavbar = () => {
    const menuToggle = document.getElementById("navbar-toggle");
    if (menuToggle) {
      const style = window.getComputedStyle(menuToggle);
      if (style.display !== "none") {
        menuToggle?.click();
      }
    }
  };

  return (
    <Navbar expand="lg" bg="primary" variant="dark" style={{ zIndex: 999 }}>
      <LinkContainer to="/">
        <Navbar.Brand>
          <img src={logoWhite} height="28" alt="Affinis Défense" />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" id="navbar-toggle" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <LinkContainer onClick={closeNavbar} to="/proxxiid/" className="mx-3">
            <Nav.Link>PROXXI ID</Nav.Link>
          </LinkContainer>
          <LinkContainer onClick={closeNavbar} to="/proxximap/" className="mx-3">
            <Nav.Link>PROXXI MAP</Nav.Link>
          </LinkContainer>
          <LinkContainer onClick={closeNavbar} to="/proxxiexplore" className="mx-3">
            <Nav.Link>PROXXI EXPLORE</Nav.Link>
          </LinkContainer>
          <LinkContainer onClick={closeNavbar} to="/proxxiintel/" className="mx-3">
            <Nav.Link>PROXXI INTEL</Nav.Link>
          </LinkContainer>
          {/**
          <LinkContainer onClick={closeNavbar} to="/proxxi2d/" className="mx-3">
            <Nav.Link>PROXXI 2D</Nav.Link>
          </LinkContainer>
          <LinkContainer onClick={closeNavbar} to="/proxxi3d/" className="mx-3">
            <Nav.Link>PROXXI 3D</Nav.Link>
          </LinkContainer>
           */}
        </Nav>
        <Nav className="ml-auto">
          <LinkContainer onClick={closeNavbar} to="/product/" className="mx-3">
            <Nav.Link>NOS PRODUITS</Nav.Link>
          </LinkContainer>
          <LinkContainer onClick={closeNavbar} to="/about/" className="mx-3">
            <Nav.Link>À PROPOS</Nav.Link>
          </LinkContainer>
          {/**
          <LinkContainer onClick={closeNavbar} to="/profile/contact" className="mx-3">
            <Nav.Link>CONTACT</Nav.Link>
          </LinkContainer>
           */}
          {connected ? (
            <NavDropdown title={name} id="basic-nav-dropdown" active>
              <LinkContainer onClick={closeNavbar} to="/profile/profile">
                <NavDropdown.Item>Mon profil</NavDropdown.Item>
              </LinkContainer>
              {isAdmin ? (
                <LinkContainer onClick={closeNavbar} to="/ponQGwRfNz">
                  <NavDropdown.Item>Administration</NavDropdown.Item>
                </LinkContainer>
              ) : null}
              <NavDropdown.Item onClick={() => authDispatch({ type: "disconnect" })}>Se déconnecter</NavDropdown.Item>
            </NavDropdown>
          ) : (
            <LinkContainer onClick={closeNavbar} to="/login">
              <Nav.Link>Se connecter</Nav.Link>
            </LinkContainer>
          )}

          <LinkContainer onClick={closeNavbar} to="/cart">
            <Button variant="primary" disabled={!connected}>
              <Cart4 size={20} /> {connected && <Badge variant="warning">{items.length}</Badge>}
            </Button>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Topbar;
