import axios from "axios";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { LoadingPage } from "../components/LoadingPage";

import { Service } from "../models/service";
import "./About.css";
import { ChevronCompactDown } from "react-bootstrap-icons";
import BottomBar from "../components/BottomBar";
import { Website } from "../models/website";
import { useAuth } from "../utils/auth";
import { useHistory, useLocation } from "react-router-dom";

const About: React.VFC = () => {
  const params = useParams<{ id: string }>();
  const [website, setWebsite] = useState<Website>();
  const [services, setServices] = useState<Service[]>();

  const {
    state: { connected },
  } = useAuth();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#services") {
      document.getElementById("service-row")?.scrollIntoView({ behavior: "auto" });
    }
  });

  useEffect(() => {
    axios
      .get<Website[]>(process.env.REACT_APP_API_URL + "/website/")
      .then((res) => {
        setWebsite(res.data[0]);
      })
      .catch(() => {
        return;
      });
    axios
      .get<Service[]>(process.env.REACT_APP_API_URL + "/services/")
      .then((res) => {
        setServices(res.data);
      })
      .catch(() => {
        return;
      });
  }, [connected]);

  const goToService = (id: number) => {
    history.replace("/about#services");
    history.push(`/about/${id}`);
  };

  if (params.id && params.id != "") {
    return <ServicePage id={params.id} />;
  }

  return (
    <div className="w-100 h-100 cont">
      <LoadingPage loading={website === undefined} />
      <Row className="justify-content-center w-100 ml-0">
        <Col className="p-0 w-100 col-12 col-12 justify-content-center image">
          <img className="bg-img-about" src={website?.about_top_bg} alt="Découvrir Affinis Défense" />
          <div className="w-100 about-top-cover">
            <ChevronCompactDown
              onClick={() => document.getElementById("transition-text")?.scrollIntoView({ behavior: "smooth" })}
              size={100}
              color="white"
              className="arrow-down cursor-pointer"
            />
            <Row className="d-1 bg-label text-center w-75 mx-auto">
              <Col className="col-12 col-md-4 px-4">
                <div className="message-title justify-content-center">{website && parse(website.valeur_title)}</div>
                <div className="message-text d-none d-lg-block">{website && parse(website.valeur_text)}</div>
                {/* <div className="divider  d-none d-sm-block"></div> */}
              </Col>
              <Col className="col-12 col-md-4 px-4">
                <div className="message-title justify-content-center">{website && parse(website.mission_title)}</div>
                <div className="message-text d-none d-lg-block">{website && parse(website.mission_text)}</div>
                {/* <div className="divider  d-none d-sm-block"></div> */}
              </Col>
              <Col className="col-12 col-md-4 px-4">
                <div className="message-title vision-title justify-content-center">
                  {website && parse(website.vision_title)}
                </div>
                <div className="message-text d-none d-lg-block">{website && parse(website.vision_text)}</div>
                {/* <div className="divider  d-none d-sm-block"></div> */}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="transition-row mx-auto">
        <Col id="transition-text" className="text-center p-4 d-2 mt-4 w-75">
          {website && parse(website.transition_text)}
          <ChevronCompactDown
            onClick={() => document.getElementById("service-row")?.scrollIntoView({ behavior: "smooth" })}
            size={100}
            color="black"
            className="cursor-pointer"
          />
        </Col>
      </Row>
      <Row id="service-row" className="m-auto justify-content-center w-100 mb-4 pb-4 px-4">
        {services?.map((service) => (
          <ServiceCard key={service.name} service={service} onClick={() => goToService(service.id)} />
        ))}
      </Row>
      <BottomBar />
    </div>
  );
};

type ServicePageProps = {
  id: string;
};

const ServicePage: React.VFC<ServicePageProps> = (props: ServicePageProps) => {
  const [service, setService] = useState<Service>();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    axios
      .get<Service>(`${process.env.REACT_APP_API_URL}/services/${props.id}/`)
      .then((res) => setService(res.data))
      .catch((err) => console.log(err));
  }, [props.id]);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  return (
    <Container
      fluid
      className="h-100"
      style={{
        background: `linear-gradient(rgba(23, 2, 7, 0.7), rgba(23, 2, 7, 0.7)), url(${service?.background}) center/cover`,
        backgroundSize: "cover",
        overflowY: "auto",
      }}
    >
      <Row className="h-100" style={{ overflowX: "hidden" }}>
        <LoadingPage loading={service === undefined} />
        <Col lg={{ span: 5, offset: 1 }} sm={12} className="py-4 d-flex flex-column justify-content-center">
          <Row className="mx-lg-4 px-4 text-white">
            <h1 style={{ fontFamily: "Helvetica, sans-serif", fontSize: "2rem" }} className="text-uppercase">
              {service?.name}
            </h1>
          </Row>
          <Row className="mx-lg-4 px-4 mb-lg-1 mb-sm-3">
            <div className="divider" />
          </Row>
          <Row className="mx-lg-4 p-lg-4 text-white">
            <Col>
              {parse(service?.description.replaceAll("/media/", process.env.REACT_APP_API_URL + "/media/") || "")}
            </Col>
          </Row>
        </Col>
        <Col
          lg={6}
          xs={12}
          className="d-flex flex-column justify-content-center bg-white"
          style={{ transform: width > 992 ? "skewX(-10deg) translate(20%, 0)" : "" }}
        >
          <Row className="p-4 my-lg-4 d-flex flex-column align-items-center">
            <img
              src={service?.illustration}
              alt={`Illustration ${service?.name}`}
              style={{
                objectFit: "contain",
                maxHeight: "60vh",
                transform: width > 992 ? "skewX(10deg) translate(-10%, 0)" : "",
                maxWidth: width > 992 ? "35vw" : "100%",
              }}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

interface ServiceCardProps {
  service: Service;
  onClick: () => void;
}

const ServiceCard = (props: ServiceCardProps) => {
  return (
    <Col
      className="overflow-hidden p-2 col-12 col-md-6 col-lg-4 justify-content-center image cursor-pointer"
      onClick={props.onClick}
    >
      <img className="bg-card-service" src={props.service.background} alt={props.service.name} />
      <div className="service-cover m-2">
        <div className="d-1 bg-label text-center cursor-pointer ">
          <h3 className="mb-4">{props.service.name}</h3>
          <p className="d-none d-lg-block">{props.service.subtitle}</p>
        </div>
      </div>
    </Col>
  );
};

export default About;
