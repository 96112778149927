import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row, Modal, Form } from "react-bootstrap";
import { X, Cart4 } from "react-bootstrap-icons";
import { useAuth } from "../utils/auth";
import { removeCartItem, useCart } from "../utils/cart";
import { wrapAsync } from "../utils/context";
import { loadStripe } from "@stripe/stripe-js";
import "./Cart.css";
import { useHistory } from "react-router";

const stripePromise = process.env.REACT_APP_STRIPE_PK ? loadStripe(process.env.REACT_APP_STRIPE_PK) : null;

const Cart: React.FC = () => {
  const [subTotal, setSubTotal] = useState(0);
  const [showCGV, setShowCGV] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [CGVAccepted, setCGVAccepted] = useState(false);
  const history = useHistory();

  const {
    state: { connected },
  } = useAuth();
  const {
    state: { items },
    dispatch,
  } = useCart();
  const asyncDispatch = useMemo(() => wrapAsync(dispatch), [dispatch]);

  useEffect(
    () => setSubTotal(items.map((item) => item.study.price).reduce((acc, next) => (acc || 0) + (next || 0), 0) || 0),
    [items],
  );

  const launchOrder = async () => {
    // await axios.post(`${process.env.REACT_APP_API_URL}/orders/`, { study_id: items.map((item) => item.study.id) });
    // items.map((item) => asyncDispatch(removeCartItem(item, connected)));

    if (!connected) {
      history.push("/login");
      return;
    }
    const stripe = await stripePromise;
    await axios.get(`${process.env.REACT_APP_API_URL}/stripe/createSession/order/`).then((res) =>
      stripe
        ? stripe.redirectToCheckout({
            sessionId: res.data.id,
          })
        : null,
    );
  };

  const closeCGV = () => {
    setShowCGV(false);
    setCGVAccepted(false);
  };
  const closeLoading = () => {
    setShowLoading(false);
  };

  return (
    <div className="w-100 h-100" style={{ display: "grid" }}>
      <Modal show={showCGV} onHide={closeCGV}>
        <Modal.Header closeButton>{/* <Modal.Title>Conditions générales de vente</Modal.Title> */}</Modal.Header>
        <Modal.Body>
          Vous devez accepter les{" "}
          <a
            className="w-100 text-center"
            href="https://api.affinis-defense.fr/media/cgv.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <span className="d-inline">Conditions Générales de Vente</span>
          </a>
          <Form.Check
            type="checkbox"
            onChange={(e) => {
              setCGVAccepted(e.target.checked);
            }}
            label={"J'accepte les CGV"}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={`success`}
            disabled={!CGVAccepted}
            onClick={() => {
              launchOrder();
              closeCGV();
              setShowLoading(true);
            }}
          >
            Commander
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showLoading} onHide={closeLoading}>
        <Modal.Body>
          Votre commande est en cours de préparation, veuillez patienter. Vous serez bientôt rediriger vers une page de
          paiement sécurisée.
        </Modal.Body>
      </Modal>
      <Container fluid className="d-flex m-auto">
        <Row className="bg-white shadow-sm border mx-auto align-self-center shadow cart-adaptative-width">
          <Col className="d-flex flex-column">
            <Row>
              <Col className="w-100 bg-primary text-white border">
                <h1 className="my-1 ml-1 font-weight-normal">Votre Panier</h1>
              </Col>
            </Row>
            <Row className="flex-grow-1">
              <Col style={{ overflowY: "scroll", height: "60vh" }}>
                {items.length > 0 ? (
                  items.map((item) => (
                    <Row key={item.study.id} className="border rounded m-2 py-2">
                      <Col className="adaptative-width">
                        <h5>{item.study.title}</h5>
                        <Row className="text-muted">
                          <Col className="text-truncate">{item.study.subtitle}</Col>
                          <Col lg={4}>
                            | {item.study.publication_date} | {item.study.pages} pages.
                          </Col>
                        </Row>
                      </Col>
                      <Col lg="auto" className="d-flex align-items-center">
                        <h4 className="font-weight-normal my-0">{item.study.price}€</h4>
                      </Col>
                      <Col lg="auto" className="d-flex align-items-center">
                        <X
                          className="close-button border rounded-circle bg-light text-secondary"
                          size={32}
                          onClick={() => asyncDispatch(removeCartItem(item, connected))}
                        />
                      </Col>
                    </Row>
                  ))
                ) : (
                  <div className="text-center text-muted mt-5">Votre panier est vide</div>
                )}
              </Col>
            </Row>
            <Row className="border rounded-bottom d-flex justify-content-end">
              {items.length > 0 && (
                <Col className="col-9 col-sm-8 text-left">
                  <h2 className="my-2 font-weight-light">
                    <span className="d-lg-inline d-none">Sous-Total : </span>
                    {subTotal}€ HT
                  </h2>
                </Col>
              )}
              <Col className="col-3 col-sm-4 text-right pr-0">
                <Button className="h-100 rounded-0" disabled={items.length === 0} onClick={() => setShowCGV(true)}>
                  <h4 className="my-1 font-weight-normal d-sm-inline d-none">Commander</h4>
                  <Cart4 className="m-auto d-sm-none d-inline" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Cart;
