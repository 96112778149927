import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Row, Container, Form, Image } from "react-bootstrap";

import { useAuth, login } from "../utils/auth";
import { wrapAsync } from "../utils/context";
import logoDark from "../utils/logo_dark.png";
import { Link, useHistory } from "react-router-dom";

const LoginComponent: React.VFC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const history = useHistory();

  const {
    state: { connected },
    dispatch,
  } = useAuth();
  const asyncDispatch = useMemo(() => wrapAsync(dispatch), [dispatch]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      event.key === "Enter" ? document.getElementById("connect-btn")?.click() : null;
    };
    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    if (connected) {
      history.push("/");
    }
  }, [connected]);

  return (
    <div className="h-100 w-100 overflow-auto">
      <Container className="my-auto mx-auto h-100">
        <Row className="justify-content-center h-100">
          <Col className="col-12 col-sm-8 col-lg-6 align-self-center">
            <Image src={logoDark} fluid width="30%" className="mb-4 w-100" />
            <Form className="p-4 shadow rounded bg-white">
              <Form.Group controlId="formUsername">
                <Form.Label className="font-weight-bold">Nom d&apos;utilisateur</Form.Label>
                <Form.Control
                  name="username"
                  placeholder="Nom d'utilisateur"
                  autoComplete="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  isInvalid={usernameError !== ""}
                />
                {usernameError && <Form.Text className="text-danger">{usernameError}</Form.Text>}
              </Form.Group>
              <Form.Group controlId="formPassword" className="mb-1">
                <Form.Label className="font-weight-bold">Mot de passe</Form.Label>
                <Form.Control
                  autoComplete="current-password"
                  type="password"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  isInvalid={passwordError !== ""}
                />
                {passwordError && <Form.Text className="text-danger">{passwordError}</Form.Text>}
              </Form.Group>
              <Link to="/password_reset" className="text-secondary ml-2">
                Mot de passe oublié
              </Link>
              <br />
              <Button
                id="connect-btn"
                className="mt-3 w-50 mx-auto font-weight-bold"
                variant="success"
                type="button"
                block
                onClick={() =>
                  asyncDispatch(
                    login(username, password, (err) => {
                      setUsernameError((err.response.data.username || [err.response.data.detail] || []).join(" "));
                      setPasswordError((err.response.data.password || [err.response.data.detail] || []).join(" "));
                    }),
                  )
                }
              >
                Se connecter
              </Button>
              <p className="mt-4 mb-1">Pas encore de compte ? Cliquez ici :</p>
              <Link to="/create_account" className="font-weight-bold ml-2">
                Créer un compte
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export { LoginComponent };
