import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import { Subscription } from "../../models/subscription";
import { LinkContainer } from "react-router-bootstrap";

const SubscriptionComponent: React.VFC = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscription, setSubscription] = useState<Subscription>();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/subscriptions/is_subscribed/`)
      .then((res) => (res.data.is_subscribed ? setIsSubscribed(res.data.is_subscribed) : null));
  }, []);

  useEffect(() => {
    axios
      .get<Subscription>(`${process.env.REACT_APP_API_URL}/subscriptions/current/`)
      .then((res) => (res.data ? setSubscription(res.data) : null));
  }, []);

  return (
    <Container className="shadow my-4 adaptative-width">
      <Row>
        <Col className="col-12 justify-content-center d-flex py-2 mb-3 bg-primary text-light border-bottom">
          MON ABONNEMENT
        </Col>
      </Row>
      <Row>
        <Col className="p-4">
          {isSubscribed ? (
            <>
              <Row className="m-1">
                Votre abonnement {subscription ? subscription.subscription_type.name : null} est actif jusqu&apos;au{" "}
                {subscription ? new Date(Date.parse(subscription.end_date)).toLocaleDateString("fr") : null}
              </Row>
            </>
          ) : (
            <>
              <Row className="m-1">Vous n&apos;êtes pas encore abonné à Proxxi ID</Row>
              <Row className="justify-content-center mt-2">
                <LinkContainer to="/subscriptions/">
                  <Button> S&apos;abonner</Button>
                </LinkContainer>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SubscriptionComponent;
