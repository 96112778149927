import React from "react";

const Loading: React.FC = () => (
  <div className="text-primary">
    <div className="spinner-grow mx-1" />
    <div className="spinner-grow mx-1" role="status">
      <span className="sr-only">Chargement...</span>
    </div>
    <div className="spinner-grow mx-1" />
  </div>
);

export default Loading;
