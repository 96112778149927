import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { Order as OrderType } from "../models/order";
import { PaginatedResponse } from "../models/response";
import { fetchCart, useCart } from "../utils/cart";
import { OrderDisplay } from "./Order";

const OrderCheckoutResult: React.VFC = () => {
  const [orderStatus, setOrderStatus] = useState("");
  const [order, setOrder] = useState<OrderType>();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showCanceled, setShowCanceled] = useState(false);

  const {
    state: { items },
    dispatch,
  } = useCart();
  const history = useHistory();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setOrderStatus("success");
      setShowSuccess(true);
    } else if (query.get("canceled")) {
      setOrderStatus("canceled");
      setShowCanceled(true);
      axios.get(`${process.env.REACT_APP_API_URL}/stripe/cancelSession/order/?session_id=${query.get("session_id")}`);
    } else if (query.get("order_ref")) {
      history.push(`/order/${query.get("order_ref")}`);
    }

    if (query.get("session_id")) {
      axios
        .get<PaginatedResponse<OrderType>>(
          `${process.env.REACT_APP_API_URL}/orders/?checkout_session_id=${query.get("session_id")}`,
        )
        .then((res) => (res.data.results ? setOrder(res.data.results[0]) : null))
        .then(() => fetchCart({ items }, dispatch));
    }
  }, []);

  return (
    <div>
      <Modal show={showSuccess} onHide={() => setShowSuccess(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Commmande passée avec succès</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success" onClick={() => setShowSuccess(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCanceled} onHide={() => setShowCanceled(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Commmande annulée avec succès</Modal.Body>
        <Modal.Footer>
          <LinkContainer to="/cart">
            <Button variant="outline-danger" onClick={() => setShowCanceled(false)}>
              OK
            </Button>
          </LinkContainer>
        </Modal.Footer>
      </Modal>
      {orderStatus === "success" ? order ? <OrderDisplay order={order} /> : null : null}
    </div>
  );
};

export default OrderCheckoutResult;
