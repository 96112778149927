import React from "react";
import { GraphData, NodeObject } from "react-force-graph-2d";
//import { Container, Col, Row } from "react-bootstrap";

type MappingSidebarProps = {
  graphData: GraphData;
  currentNode?: NodeObject;
};

export const linkColors = {
  "1": "#339d2e", // stakeholder
  "2": "#ef5b5b", // international_part
  "3": "#494a4b", // private_part
  "4": "#ffba49", // public_part
  "5": "#20a39e", // french_part
};

export const linkNames = {
  "1": "Actionnariat", // stakeholder
  "2": "Partenariat international", // international_part
  "3": "Partenariat privé", // private_part
  "4": "Partenariat public", // public_part
  "5": "Partenariat français", // french_part
};

// const MappingSidebar: React.VFC<MappingSidebarProps> = (props: MappingSidebarProps) => {

const MappingSidebar: React.VFC<MappingSidebarProps> = () => {
  return null;
  /**
  return (
    <Container>
      <Row>
        <Col className="border bg-light py-3">
        </Col>
        <Col className="border bg-light py-3">
          <strong>Contrôles</strong>
        </Col>
        <Col className="border bg-light py-3">
          <strong>Element sélectionné:</strong>
        </Col>
      </Row>
    </Container>
  );
   */
};

export default MappingSidebar;
