import axios from "axios";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LoadingPage } from "../components/LoadingPage";

import "./ProductPresentation.css";
import BottomBar from "../components/BottomBar";
import { Product, ProductPage } from "../models/website";
import { useAuth } from "../utils/auth";
import { useHistory, useLocation, useParams } from "react-router-dom";

const ServicePresentation: React.VFC = () => {
  const params = useParams<{ id: string }>();
  const [productPage, setProductPage] = useState<ProductPage>();

  const {
    state: { connected },
  } = useAuth();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#products") {
      document.getElementById("product-row")?.scrollIntoView({ behavior: "auto" });
    }
  });

  useEffect(() => {
    axios
      .get<ProductPage[]>(process.env.REACT_APP_API_URL + "/productpage/")
      .then((res) => {
        setProductPage(res.data[0]);
      })
      .catch(() => {
        return;
      });
  }, [connected]);

  const goToProduct = (id: number) => {
    history.replace("/product#products");
    history.push(`/product/${id}`);
  };

  if (params.id && params.id != "") {
    return <ProductPageView id={params.id} />;
  }

  return (
    <div className="w-100 h-100 cont">
      <LoadingPage loading={productPage === undefined} />
      <Row className="justify-content-center w-100 ml-0">
        <div className="display-block">
          <h1 className="text-center text-secondary px-4 mt-4 h4 h1-sm">
            <span className="d-block h1">
              {productPage?.title} <br />
            </span>
            <span className="d-sm-block d-none">{productPage?.subtitle}</span>
          </h1>
        </div>
      </Row>
      <Row className="transition-row mx-auto">
        <Col id="transition-text" className="text-center p-4 d-2 mt-4 w-75">
          {productPage?.products?.map((prod) => (
            <>
              {
                //<img src={prod.logo} alt={prod.name} className="desc_photo clearfix" />
              }
              {prod &&
                parse(
                  prod.description?.replace(
                    "/media/django-summernote",
                    process.env.REACT_APP_API_URL + "/media/django-summernote",
                  ),
                )}
            </>
          ))}
        </Col>
      </Row>

      <Row id="product-row" className="m-auto justify-content-center w-100 mb-4 pb-4 px-4">
        {productPage?.products?.map((product) => (
          <ProductCard key={product.name} product={product} onClick={() => goToProduct(product.id)} />
        ))}
      </Row>

      <Row className="justify-content-center w-100 ml-0">
        <div className="display-block">
          <h1 className="text-center text-secondary px-4 mt-4 h4 h1-sm">
            <span className="d-block h1">
              {productPage?.subscribe_title} <br />
            </span>
            <span className="d-sm-block d-none">
              {productPage?.subscribe_description && parse(productPage?.subscribe_description)}
            </span>
          </h1>
        </div>
      </Row>

      <Row id="subscription-row" className="m-auto justify-content-center w-100 mb-4 pb-4 px-4">
        {productPage?.subscriptions?.map((sub) => (
          <Col className="p-2 col-12 col-md-6 col-lg-4 justify-content-center image" key={sub.name}>
            <img className="subscribe_card" src={sub.card} alt={sub.name} />
          </Col>
        ))}
      </Row>
      <Row className="mx-auto">
        <Col id="transition-text" className="text-center p-4 d-2 mt-4 w-75">
          {productPage && parse(productPage.contact_text)}
        </Col>
      </Row>

      <BottomBar />
    </div>
  );
};

type ProductPageProps = {
  id: string;
};

const ProductPageView: React.VFC<ProductPageProps> = (props: ProductPageProps) => {
  const [product, setProduct] = useState<Product>();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    axios
      .get<Product>(`${process.env.REACT_APP_API_URL}/products/${props.id}/`)
      .then((res) => setProduct(res.data))
      .catch((err) => console.log(err));
  }, [props.id]);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  return (
    <Container
      fluid
      className="h-100"
      style={{
        background: `linear-gradient(rgba(23, 2, 7, 0.7), rgba(23, 2, 7, 0.7)), url(${product?.detail_background}) center/cover`,
        backgroundSize: "cover",
        overflowY: "auto",
      }}
    >
      <Row className="h-100" style={{ overflowX: "hidden" }}>
        <LoadingPage loading={product === undefined} />
        <Col lg={{ span: 5, offset: 1 }} sm={12} className="py-4 d-flex flex-column justify-content-center">
          <Row className="mx-lg-4 px-4 text-white">
            <h1 style={{ fontFamily: "Helvetica, sans-serif", fontSize: "2rem" }} className="text-uppercase">
              {product?.name}
            </h1>
          </Row>
          <Row className="mx-lg-4 px-4 mb-lg-1 mb-sm-3">
            <div className="divider" />
          </Row>
          <Row className="mx-lg-4 p-lg-4 text-white">
            <Col>
              {parse(
                product?.detail_description.replaceAll("/media/", process.env.REACT_APP_API_URL + "/media/") || "",
              )}
            </Col>
          </Row>
        </Col>
        <Col
          lg={6}
          xs={12}
          className="d-flex flex-column justify-content-center bg-white"
          style={{ transform: width > 992 ? "skewX(-10deg) translate(20%, 0)" : "" }}
        >
          <Row className="p-4 my-lg-4 d-flex flex-column align-items-center">
            <img
              src={product?.card_illustration}
              alt={`Illustration ${product?.name}`}
              style={{
                objectFit: "contain",
                maxHeight: "60vh",
                transform: width > 992 ? "skewX(10deg) translate(-10%, 0)" : "",
                maxWidth: width > 992 ? "35vw" : "100%",
              }}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

interface ProductCardProps {
  product: Product;
  onClick: () => void;
}

const ProductCard = (props: ProductCardProps) => {
  return (
    <Col
      className="overflow-hidden p-2 col-12 col-md-6 col-lg-4 justify-content-center image cursor-pointer"
      onClick={props.onClick}
    >
      <img className="bg-card-service" src={props.product.card_illustration} alt={props.product.name} />
      <div className="service-cover m-2">
        <div className="d-1 bg-label text-center cursor-pointer ">
          <h3 className="mb-4">{props.product.name}</h3>
          <p className="d-none d-lg-block">{parse(props.product.card_description)}</p>
        </div>
      </div>
    </Col>
  );
};

export default ServicePresentation;
