import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import { useAuth } from "../../utils/auth";

interface SettingsProps {
  userId: number;
}

const Settings: React.VFC<SettingsProps> = ({ userId }: SettingsProps) => {
  const [passwordOld, setPasswordOld] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [passwordOldError, setPasswordOldError] = useState("");
  const [passwordNewError, setPasswordNewError] = useState("");
  const [showPwdConfirm, setShowPwdConfirm] = useState(false);
  const [showPwdSuccess, setShowPwdSuccess] = useState(false);
  const [showDelConfirm, setShowDelConfirm] = useState(false);

  const { dispatch: authDispatch } = useAuth();

  const updatePassword = () => {
    setPasswordOldError("");
    setPasswordNewError("");
    axios
      .patch(process.env.REACT_APP_API_URL + `/users/${userId}/`, { old_password: passwordOld, password: passwordNew })
      .then(() => {
        setPasswordOld("");
        setPasswordNew("");
        setShowPwdSuccess(true);
      })
      .catch((err) => {
        setPasswordOldError((err.response.data.old || []).join(" "));
        setPasswordNewError((err.response.data.new || []).join(" "));
      });
  };

  const deleteUser = () =>
    axios.delete(process.env.REACT_APP_API_URL + `/users/${userId}/`).then(() => authDispatch({ type: "disconnect" }));

  const closePwdConfirm = () => setShowPwdConfirm(false);

  const closePwdSuccess = () => setShowPwdSuccess(false);

  const closeDelConfirm = () => setShowDelConfirm(false);

  return (
    <>
      <Modal show={showPwdConfirm} onHide={closePwdConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous modifier votre mot de passe ?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={closePwdConfirm}>
            Annuler
          </Button>
          <Button
            variant="success"
            onClick={() => {
              updatePassword();
              closePwdConfirm();
            }}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPwdSuccess} onHide={closePwdSuccess}>
        <Modal.Header closeButton>
          <Modal.Title>Succès</Modal.Title>
        </Modal.Header>
        <Modal.Body>Mot de passe modifié avec succès !</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={closePwdSuccess}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDelConfirm} onHide={closeDelConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous supprimer votre compte ? Cette action est irréversible.</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={closeDelConfirm}>
            Annuler
          </Button>
          <Button
            variant="success"
            onClick={() => {
              deleteUser();
              closeDelConfirm();
            }}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
      <Container className="shadow mt-4 mb-4 adaptative-width">
        <Row>
          <Col className="justify-content-center d-flex py-2 mb-3 bg-primary text-light border-bottom">PARAMÈTRES</Col>
        </Row>
        <Row>
          <Col className="mb-4">
            <h4 className="my-3">Sécurité</h4>
            <h5 className="mb-3">Changement de mot de passe</h5>
            <Form.Group>
              <Form.Label>Ancien mot de passe</Form.Label>
              <Form.Control
                autoComplete="current-password"
                type="password"
                placeholder="Ancien mot de passe"
                value={passwordOld}
                onChange={(e) => setPasswordOld(e.target.value)}
                isInvalid={passwordOldError !== ""}
              />
              {passwordOldError && <Form.Text className="text-danger">{passwordOldError}</Form.Text>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Nouveau mot de passe</Form.Label>
              <Form.Control
                autoComplete="new-password"
                type="password"
                placeholder="Nouveau mot de passe"
                value={passwordNew}
                onChange={(e) => setPasswordNew(e.target.value)}
                isInvalid={passwordNewError !== ""}
              />
              {passwordNewError && <Form.Text className="text-danger">{passwordNewError}</Form.Text>}
              <Form.Text className="text-muted">
                Le mot de passe doit contenir au minimum 8 caractères. Privilégiez les mots de passes complexes avec des
                lettres, des chiffres et des caractères spéciaux.
              </Form.Text>
            </Form.Group>
            <Button
              id="connect-btn"
              className="my-3 font-weight-bold"
              variant="primary"
              type="button"
              onClick={() => setShowPwdConfirm(true)}
            >
              Changer de mot de passe
            </Button>

            <h4 className="my-4">Données personnelles</h4>
            <Button
              id="connect-btn"
              className="font-weight-bold"
              variant="danger"
              type="button"
              onClick={() => setShowDelConfirm(true)}
            >
              Supprimer mon compte
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
