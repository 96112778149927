import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, InputGroup, Row, FormCheck, Modal } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import { Option } from "react-multi-select-component/dist/lib/interfaces";

import { AutocompleteSearch } from "../Companies/CompaniesSearch";

import { ActivityDomain } from "../../models/activitydomain";
import { CompanyType } from "../../models/companytype";
import { Country } from "../../models/country";
import { PaginatedResponse } from "../../models/response";
import Select from "../Select";
import { namedSort } from "../../utils/sort";
import "./EcosystemsSearch.css";
import { LinkContainer } from "react-router-bootstrap";
import { useAuth } from "../../utils/auth";
import { Subscription } from "../../models/subscription";
import { useHistory } from "react-router-dom";

type SearchState = {
  activityDomains: string[];
  countries: string[];
  companyTypes: string[];
  search: string;
};

const relationshipTypes = [
  {
    label: "Actionnariat",
    value: 1,
  },
  {
    label: "Partenariat international",
    value: 2,
  },
  {
    label: "Partenariat privé",
    value: 3,
  },
  {
    label: "Partenariat public",
    value: 4,
  },
  {
    label: "Partenariat français",
    value: 5,
  },
];

const depthList = [
  {
    value: "1",
    label: "1er niveau",
  },
  {
    value: "2",
    label: "2ème niveau",
  },
  {
    value: "3",
    label: "3ème niveau",
  },
];
const defaultDepth = depthList[0];

interface EcosystemsSearchProps {
  search: (path: string) => void;
  inHome?: boolean;
  initState?: SearchState;
}

const EcosystemsSearch: React.FC<EcosystemsSearchProps> = (props: EcosystemsSearchProps) => {
  const [activityDomains, setActivityDomains] = useState<ActivityDomain[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [companyTypes, setCompanyTypes] = useState<CompanyType[]>([]);

  const [searchBar, setSearchBar] = useState<string>("");
  const [searchCountry, setSearchCountry] = useState<Option[]>([]);
  const [countryLoaded, setCountryLoaded] = useState(false);
  const [searchActivityDomains, setSearchActivityDomains] = useState<Option[]>([]);
  const [activityDomainsLoaded, setActivityDomainsLoaded] = useState(false);
  const [searchCompanyTypes, setSearchCompanyTypes] = useState<Option[]>([]);
  const [searchRelationshipTypes, setSearchRelationshipTypes] = useState<Option[]>(relationshipTypes);
  const [searchExtendActivityDomains, setSearchExtendActivityDomains] = useState<Option[]>([]);
  const [searchExtendCountry, setSearchExtendCountry] = useState<Option[]>([]);
  const [searchExtendCompanyTypes, setSearchExtendCompanyTypes] = useState<Option[]>([]);
  const [searchDepth, setSearchDepth] = useState<Option[]>([defaultDepth]);
  const [companyTypesLoaded, setCompanyTypesLoaded] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [textMode, setTextMode] = useState(false);
  const [colorLinks, setColorLinks] = useState(false);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  //const [validAccess, setValidAccess] = useState(false);
  const [showGetSubscribedModal, setShowGetSubscribedModal] = useState(false);

  const history = useHistory();

  const {
    state: { connected },
  } = useAuth();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/subscriptions/is_subscribed/`).then((res) => {
      res.data.is_subscribed ? setIsSubscribed(res.data.is_subscribed) : setIsSubscribed(false);
    });
    axios
      .get<Subscription>(`${process.env.REACT_APP_API_URL}/subscriptions/current/`)
      .then((res) => (res.data ? setSubscription(res.data) : null));
  }, []);

  /**
  useEffect(() => {
    if (connected && isSubscribed && subscription && subscription.subscription_type) {
      setValidAccess(subscription.subscription_type.proxxi_explore_access);
    }
  }, [connected, isSubscribed, subscription]);
   */

  useEffect(() => {
    if (props.initState && activityDomainsLoaded && countryLoaded && companyTypesLoaded) {
      setSearchActivityDomains(
        props.initState.activityDomains.map((id) => ({
          value: +id,
          label: activityDomains.find((ad) => ad.id === +id)?.name || "",
        })),
      );
      setSearchCountry(
        props.initState.countries.map((code) => ({
          value: code,
          label: countries.find((c) => c.code === code)?.name || "",
        })),
      );
      setSearchCompanyTypes(
        props.initState.companyTypes.map((id) => ({
          value: +id,
          label: companyTypes.find((ct) => ct.id === +id)?.name || "",
        })),
      );
      setSearchRelationshipTypes(
        relationshipTypes.map((id) => ({
          value: +id,
          label: relationshipTypes.find((ct) => ct.value === +id)?.label || "",
        })),
      );
      setSearchExtendActivityDomains(
        props.initState.activityDomains.map((id) => ({
          value: +id,
          label: activityDomains.find((ad) => ad.id === +id)?.name || "",
        })),
      );
      setSearchExtendCountry(
        props.initState.countries.map((code) => ({
          value: code,
          label: countries.find((c) => c.code === code)?.name || "",
        })),
      );
      setSearchExtendCompanyTypes(
        props.initState.companyTypes.map((id) => ({
          value: +id,
          label: companyTypes.find((ct) => ct.id === +id)?.name || "",
        })),
      );
      setSearchBar(props.initState.search);
    }
  }, [props.initState, activityDomainsLoaded, countryLoaded, companyTypesLoaded]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      event.key === "Enter" ? document.getElementById("search-bar-btn")?.click() : null;
    };
    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams();
    searchCountry.forEach((c) => params.append("country", c.value));
    searchCompanyTypes.map((t) => params.append("company_type", t.value));
    searchRelationshipTypes.forEach((c) => params.append("relationship_type", c.value));
    searchExtendCountry.forEach((c) => params.append("extend_country", c.value));
    searchExtendCompanyTypes.map((t) => params.append("extend_company_type", t.value));
    searchDepth.forEach((c) => params.append("search_depth", c.value));

    axios
      .get<PaginatedResponse<ActivityDomain>>(`${process.env.REACT_APP_API_URL}/activitydomains/?${params.toString()}`)
      .then((res) => (res.data.results ? setActivityDomains(res.data.results) : null))
      .then(() => setActivityDomainsLoaded(true));
  }, [
    searchCountry,
    searchCompanyTypes,
    searchRelationshipTypes,
    searchExtendCountry,
    searchExtendCompanyTypes,
    searchDepth,
  ]);

  useEffect(() => {
    const params = new URLSearchParams();
    searchActivityDomains.forEach((c) => params.append("activity_domains", c.value));
    searchCompanyTypes.map((t) => params.append("company_type", t.value));
    searchRelationshipTypes.forEach((c) => params.append("relationship_type", c.value));
    searchExtendActivityDomains.forEach((c) => params.append("extend_activity_domains", c.value));
    searchExtendCompanyTypes.map((t) => params.append("extend_company_type", t.value));
    searchDepth.forEach((c) => params.append("search_depth", c.value));

    axios
      .get<PaginatedResponse<Country>>(`${process.env.REACT_APP_API_URL}/countries/?${params.toString()}`)
      .then((res) => (res.data.results ? setCountries(namedSort(res.data.results)) : null))
      .then(() => setCountryLoaded(true));
  }, [
    searchActivityDomains,
    searchCompanyTypes,
    searchRelationshipTypes,
    searchExtendActivityDomains,
    searchExtendCompanyTypes,
    searchDepth,
  ]);

  useEffect(() => {
    const params = new URLSearchParams();
    searchActivityDomains.forEach((c) => params.append("activity_domains", c.value));
    searchCountry.map((t) => params.append("country", t.value));
    searchExtendActivityDomains.forEach((c) => params.append("extend_activity_domains", c.value));
    searchExtendCountry.map((t) => params.append("extend_country", t.value));

    axios
      .get<PaginatedResponse<CompanyType>>(`${process.env.REACT_APP_API_URL}/companytypes/?${params.toString()}`)
      .then((res) => (res.data.results ? setCompanyTypes(res.data.results) : null))
      .then(() => setCompanyTypesLoaded(true));
  }, [searchActivityDomains, searchCountry, searchExtendActivityDomains, searchExtendCountry]);

  useEffect(() => {
    axios
      .get<PaginatedResponse<ActivityDomain>>(process.env.REACT_APP_API_URL + "/activitydomains/")
      .then((res) => (res.data.results ? setActivityDomains(res.data.results) : null));
    axios
      .get<PaginatedResponse<Country>>(process.env.REACT_APP_API_URL + "/countries/")
      .then((res) => (res.data.results ? setCountries(namedSort(res.data.results)) : null));
    axios
      .get<PaginatedResponse<CompanyType>>(process.env.REACT_APP_API_URL + "/companytypes/")
      .then((res) => (res.data.results ? setCompanyTypes(res.data.results) : null));
  }, []);

  useEffect(() => {
    if (searchDepth.length > 1) {
      setSearchDepth([searchDepth[searchDepth.length - 1]]);
    }
  }, [searchDepth]);

  const searchPath = () => {
    let searchString = "?";
    const params = new URLSearchParams();

    searchCountry.forEach((option) => params.append("country", option.value));
    searchActivityDomains.forEach((option) => params.append("activity_domains", option.value));
    searchCompanyTypes.forEach((option) => params.append("company_type", option.value));
    searchRelationshipTypes.forEach((c) => params.append("relationship_type", c.value));
    searchExtendCountry.forEach((option) => params.append("extend_country", option.value));
    searchExtendActivityDomains.forEach((option) => params.append("extend_activity_domains", option.value));
    searchExtendCompanyTypes.forEach((option) => params.append("extend_company_type", option.value));
    searchDepth.forEach((c) => params.append("search_depth", c.value));

    if (searchBar !== "") {
      params.append("search", searchBar);
    }

    params.append("text_mode", String(textMode));
    params.append("color_links", String(colorLinks));

    searchString = searchString + params.toString();
    //props.search(searchString);
    return searchString;
  };

  useEffect(() => {
    if (!props.inHome) {
      const timeout = setTimeout(() => searchPath(), 400);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [searchBar]);

  useEffect(() => {
    if (!props.inHome) {
      searchPath();
    }
  }, [
    searchActivityDomains,
    searchCompanyTypes,
    searchCountry,
    searchRelationshipTypes,
    searchExtendActivityDomains,
    searchExtendCompanyTypes,
    searchExtendCountry,
    textMode,
    searchDepth,
    colorLinks,
  ]);

  const changeModeCheckBox = () => {
    setTextMode(!textMode);
  };

  const changeColorCheckBox = () => {
    setColorLinks(!colorLinks);
  };

  return (
    <>
      {showGetSubscribedModal ? (
        <Modal
          show={showGetSubscribedModal}
          onHide={() => {
            history.push("/");
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Abonnement requis</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Pour accéder à cette interface, vous devez bénéficier d&apos;un abonnement incluant Proxxi Explore.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                history.push("/subscriptions/");
              }}
            >
              {connected && isSubscribed ? "Mettre à niveau" : "Souscrire"}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      <div>
        <Row className="text-secondary pb-4 mx-0 pt-3">
          <Row className="bg-white p-3 shadow-sm border rounded justify-content-center mx-auto adaptative-width">
            <Row className="w-100 mt-3 text-left">
              <strong>Critères de sélection d&apos;un écosystème A</strong>
            </Row>
            <InputGroup>
              <AutocompleteSearch setSearchBar={setSearchBar} searchBar={searchBar} />
              {searchBar && (
                <X
                  size={32}
                  className="text-muted align-center font-weight-light h-100"
                  style={{ position: "absolute", cursor: "pointer", right: "3em" }}
                  onClick={() => setSearchBar("")}
                />
              )}
              <InputGroup.Append>
                <Button
                  id="2d-search-bar-btn"
                  onClick={() => {
                    if (!isSubscribed || !connected) {
                      setShowGetSubscribedModal(true);
                    } else {
                      window.open("/proxxi2d" + searchPath(), "_blank");
                    }
                  }}
                >
                  2D
                </Button>
                <Button
                  id="3d-search-bar-btn"
                  onClick={() => {
                    if (!isSubscribed || !connected) {
                      setShowGetSubscribedModal(true);
                    } else {
                      window.open("/proxxi3d" + searchPath(), "_blank");
                    }
                  }}
                >
                  3D
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <Row className="w-100 mt-3 text-left">
              <Select
                className="mb-2"
                id="activity-domains"
                label="Domaines d'activité"
                options={activityDomains.map((a) => ({ label: a.name, value: a.id }))}
                value={searchActivityDomains}
                size={4}
                onChange={setSearchActivityDomains}
              />
              <Select
                className="mb-2"
                id="country"
                label="Pays"
                options={countries
                  .map((c) => ({ label: c.name, value: c.code }))
                  .sort((a, b) =>
                    a.label.normalize("NFD").replace(/\p{Diacritic}/gu, "") <=
                    b.label.normalize("NFD").replace(/\p{Diacritic}/gu, "")
                      ? -1
                      : 1,
                  )}
                value={searchCountry}
                size={4}
                onChange={setSearchCountry}
              />
              <Select
                className="mb-2"
                id="company-type"
                label="Type d'entité"
                options={companyTypes.map((a) => ({ label: a.name, value: a.id }))}
                value={searchCompanyTypes}
                size={4}
                onChange={setSearchCompanyTypes}
              />
            </Row>
            <Row className="w-100 mt-3 text-left">
              <Select
                className="mb-2"
                id="relationship-type"
                label="Type de liens"
                options={relationshipTypes}
                value={searchRelationshipTypes}
                size={6}
                onChange={setSearchRelationshipTypes}
                hasSelectAll={true}
              />
              <Select
                //className="mb-2"
                id="search-depth"
                label="Niveau de relation"
                options={depthList}
                value={searchDepth}
                size={6}
                onChange={setSearchDepth}
              />
            </Row>
            <Row className="w-100 mt-3 text-left">
              <strong>Mise en relation avec un écosystème B</strong>
            </Row>
            <Row className="w-100 mt-3 text-left">
              <Select
                className="mb-2"
                id="extend-activity-domains"
                label="Domaines d'activité"
                options={activityDomains.map((a) => ({ label: a.name, value: a.id }))}
                value={searchExtendActivityDomains}
                size={4}
                onChange={setSearchExtendActivityDomains}
              />
              <Select
                className="mb-2"
                id="extend-country"
                label="Pays"
                options={countries
                  .map((c) => ({ label: c.name, value: c.code }))
                  .sort((a, b) =>
                    a.label.normalize("NFD").replace(/\p{Diacritic}/gu, "") <=
                    b.label.normalize("NFD").replace(/\p{Diacritic}/gu, "")
                      ? -1
                      : 1,
                  )}
                value={searchExtendCountry}
                size={4}
                onChange={setSearchExtendCountry}
              />
              <Select
                className="mb-2"
                id="extend-company-type"
                label="Type d'entité"
                options={companyTypes.map((a) => ({ label: a.name, value: a.id }))}
                value={searchExtendCompanyTypes}
                size={4}
                onChange={setSearchExtendCompanyTypes}
              />
            </Row>
            <Row className="w-100 mt-3 text-center">
              <FormCheck
                inline
                label="Colorer les liens"
                name="color-links"
                type="checkbox"
                id={`inline-checkbox-1`}
                checked={colorLinks}
                onChange={changeColorCheckBox}
              />
              <FormCheck
                inline
                label="Sphères"
                name="group1"
                type="radio"
                id={`inline-radio-1`}
                checked={!textMode}
                onChange={changeModeCheckBox}
              />
              <FormCheck
                inline
                label="Texte"
                name="group1"
                type="radio"
                id={`inline-radio-2`}
                checked={textMode}
                onChange={changeModeCheckBox}
              />
            </Row>
            {!isSubscribed || !connected ? (
              <LinkContainer to="/subscriptions/">
                <a id="subscription-link">S&apos;abonner</a>
              </LinkContainer>
            ) : subscription?.subscription_type.proxxi_explore_access ? (
              <a id="subscription-link">Abonnement à Proxxi Explore valide</a>
            ) : (
              <LinkContainer to="/subscriptions/">
                <a id="subscription-link">Mettre à niveau l&apos;abonnement</a>
              </LinkContainer>
            )}
          </Row>
        </Row>
      </div>
    </>
  );
};

export default EcosystemsSearch;
export type { SearchState };
