import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

import StudiesResults from "../components/Studies/StudiesResults";
import StudiesSearch, { SearchState } from "../components/Studies/StudiesSearch";
import { PaginatedResponse } from "../models/response";
import type { Study } from "../models/study";
// import { LoadingPage } from "../components/LoadingPage";

const Studies: React.VFC = () => {
  const [studies, setStudies] = useState<Study[]>([]);
  const [count, setCount] = useState(0);
  // const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [next, setNext] = useState<string | null>(null);
  const [studiesBought, setStudiesBought] = useState<number[]>([]);
  const [inlineSearch, setInlineSearch] = useState(window.innerWidth <= 990);
  const [searchState, setSearchState] = useState<SearchState>();

  const history = useHistory();
  const location = useLocation();

  window.addEventListener("resize", () => {
    window.innerWidth >= 990 ? setInlineSearch(false) : setInlineSearch(true);
  });

  const getStudies = async (path: string) => {
    await axios.get<PaginatedResponse<Study>>(process.env.REACT_APP_API_URL + "/studies/" + path).then((res) => {
      setStudies(res.data.results || []);
      setCount(res.data.count || 0);
      setHasMore(res.data.next !== null);
      setNext(res.data.next || null);
    });
    history.push(encodeURI(path));
  };

  const fetchMore = () => {
    if (next) {
      axios.get<PaginatedResponse<Study>>(next).then((res) => {
        setStudies((prev) => prev.concat(res.data.results || []));
        setNext(res.data.next || null);
        setHasMore(res.data.next !== null);
      });
    }
  };

  useEffect(() => {
    axios.get<number[]>(process.env.REACT_APP_API_URL + "/studies/bought/").then((res) => setStudiesBought(res.data));
    // .then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (location.search) {
      getStudies(decodeURI(location.search || ""));

      const params = new URLSearchParams(decodeURI(location.search));
      setSearchState({
        sectors: params.getAll("sectors"),
        studyTypes: params.getAll("study_type"),
        studyYears: params.getAll("year"),
        studyCountries: params.getAll("country"),
        search: params.get("search") || "",
      });
    }
  }, []);

  const studiesSearch = (
    <StudiesSearch search={(searchString) => getStudies(searchString)} inline={inlineSearch} initState={searchState} />
  );

  return (
    <Container fluid className="h-100">
      <Row className="h-100 overflow-auto">
        {/* <LoadingPage loading={loading} /> */}
        {!inlineSearch ? studiesSearch : null}
        <StudiesResults
          studies={studies}
          fetchMore={fetchMore}
          hasMore={hasMore}
          count={count}
          studiesBought={studiesBought}
          studiesSearchComponent={inlineSearch ? studiesSearch : null}
        />
      </Row>
    </Container>
  );
};

export default Studies;
