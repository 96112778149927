import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, LayersControl, ScaleControl, ZoomControl } from "react-leaflet";

const { BaseLayer } = LayersControl; // { BaseLayer, Overlay }

import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";
//import { CirclesWithBar } from "react-loader-spinner";

import { Company } from "../models/company";
import MarkerClusterGroup from "react-leaflet-cluster";

//import MapSidebar from "./MapSidebar";

import L from "leaflet";
import "./Map.css";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import LoadingBar from "react-top-loading-bar";

import axios, { AxiosError } from "axios";
import { PaginatedResponse } from "../models/response";
import parse from "html-react-parser";
import { Button, Modal } from "react-bootstrap";
import { useAuth } from "../utils/auth";
import { useHistory } from "react-router-dom";

const defaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = defaultIcon;

const greenIcon = L.icon({
  iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const Map: React.VFC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const params = new URLSearchParams(decodeURI(location.search));
  const [next, setNext] = useState<string | null>(process.env.REACT_APP_API_URL + "/map/?" + params.toString());
  const [hasMore, setHasMore] = useState(true);
  const [count, setCount] = useState(0);
  const [fromComp, setFromComp] = useState<Company>();
  const [showGetSubscribedModal, setShowGetSubscribedModal] = useState(false);
  //const [subscription, setSubscription] = useState<Subscription | null>(null);
  //const [validAccess, setValidAccess] = useState(true);

  const history = useHistory();

  const {
    state: { connected },
  } = useAuth();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/subscriptions/is_subscribed/`).then((res) => {
      res.data.is_subscribed ? setIsSubscribed(res.data.is_subscribed) : setIsSubscribed(false);
    });
    /**
    axios
      .get<Subscription>(`${process.env.REACT_APP_API_URL}/subscriptions/current/`)
      .then((res) => (res.data ? setSubscription(res.data) : null));
     */
  }, []);

  /**
  useEffect(() => {
    if (subscription !== null && subscription!.subscription_type !== null) {
      setValidAccess(subscription!.subscription_type.proxxi_map_access);
      setShowGetSubscribedModal(!subscription!.subscription_type.proxxi_map_access);
    }
  }, [subscription]);
   */

  useEffect(() => {
    if (params.get("from_comp") !== null) {
      axios
        .get<Company>(`${process.env.REACT_APP_API_URL}/companies/${params.get("from_comp")}/`)
        .then((res) => setFromComp(res.data));
    }
  }, []);

  const loadCompanies = async () => {
    if (!loading && !loaded) {
      setLoading(true);
    }
    if (hasMore) {
      await Promise.all([
        axios
          .get<PaginatedResponse<Company>>(next!)
          .then((res) => {
            if (res.data.results !== null && res.data.results !== [] && !companies.includes(res.data.results![0])) {
              setCompanies((prev) => prev.concat(res.data.results || []));
            }
            setNext(res.data.next || null);
            setHasMore(res.data.next !== null);
            setCount(res.data.count || 0);
          })
          .catch((err: AxiosError) => {
            if (err.response?.status == 403) {
              setShowGetSubscribedModal(true);
            }
          }),
      ]);
    } else {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (!loaded) {
      loadCompanies();
    }
  }, [loaded, companies]);

  function truncate(val: number) {
    return Math.floor(val * 100) / 100;
  }

  if (!showGetSubscribedModal && loading && !loaded) {
    return (
      <>
        {/**
        <div className="center-screen">
          <CirclesWithBar color="red" outerCircleColor="red" innerCircleColor="black" />
        </div>
         */}
        {loading && count !== 0 ? (
          <>
            <div className="center-screen">
              <strong>
                <br />
                {companies.length / 2}/{count}
                <br />
                {100 * truncate(companies.length / (2 * count))}%
              </strong>
            </div>
            <LoadingBar
              color="#f11946"
              progress={(100 * companies.length) / (2 * count)}
              onLoaderFinished={() => null}
            />
          </>
        ) : null}
      </>
    );
  }

  return (
    <>
      {showGetSubscribedModal ? (
        <Modal
          show={showGetSubscribedModal}
          onHide={() => {
            history.push("/");
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Abonnement requis</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Pour accéder à cette interface, vous devez bénéficier d&apos;un abonnement incluant Proxxi Map.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                history.push("/subscriptions/");
              }}
            >
              {connected && isSubscribed ? "Mettre à niveau" : "Souscrire"}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      <CompaniesMap companies={companies} isSubscribed={isSubscribed} fromComp={fromComp} />
    </>
  );
};

type CompaniesMapProps = {
  companies: Company[];
  isSubscribed?: boolean;
  fromComp?: Company;
};

const CompaniesMap: React.VFC<CompaniesMapProps> = (props: CompaniesMapProps) => {
  return (
    <div>
      <MapContainer
        className="markercluster-map"
        center={
          props.fromComp?.coordinates
            ? [props.fromComp.coordinates!.latitude, props.fromComp.coordinates!.longitude]
            : [51.505, -0.09]
        }
        zoom={props?.fromComp ? 12 : 3}
        scrollWheelZoom={true}
        //maxBounds={new L.LatLngBounds(new L.LatLng(49.5, -11.3), new L.LatLng(61.2, 2.5))}
        //maxBoundsViscosity={0.1}
        attributionControl={false}
      >
        <ScaleControl position="bottomleft" />
        <LayersControl>
          <BaseLayer checked name="OpenStreetMap">
            <TileLayer
              //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}.png"

              //url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png" // French tiles
              //url="https://cdn.lima-labs.com/{z}/{x}/{y}.png?free"
            />
          </BaseLayer>

          {/**
          <BaseLayer name="Satellite View 1">
            <TileLayer
              url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              maxZoom={20}
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
            />
          </BaseLayer>
           */}

          <BaseLayer name="Vue Satellite">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
              maxZoom={20}
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
            />
          </BaseLayer>
        </LayersControl>
        <ZoomControl position="topright" />
        <FullscreenControl position="topright" forceSeparateButton={true} />
        {/** ENLEVER POUR REMETTRE BAR
          <MapSidebar />
         */}

        {/**
           <Marker position={[43.5643436, 1.474901]}>
           <Popup>
           Connect : {String(props.isSubscribed)}
           <br />
           {props.companies.length}
           </Popup>
           </Marker>
           */}
        <MarkerClusterGroup chunkedLoading showCoverageOnHover={false}>
          {props.companies
            .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))
            .map((comp) =>
              props?.fromComp && comp.id == props?.fromComp.id ? null : <CompanyMarker company={comp} />,
            )}
        </MarkerClusterGroup>
        {props?.fromComp ? <CompanyMarker company={props.fromComp} specialIcon={true} /> : null}
      </MapContainer>
    </div>
  );
};

type CompanyMarkerProps = {
  company: Company;
  specialIcon?: boolean;
};

const CompanyMarker: React.VFC<CompanyMarkerProps> = (props: CompanyMarkerProps) => {
  const comp = props.company;

  return (
    <Marker
      key={comp.id}
      position={[comp.coordinates!.latitude, comp.coordinates!.longitude]}
      icon={props?.specialIcon ? greenIcon : defaultIcon}
    >
      <Popup>
        <div className="text-justify center">
          {comp?.logo && (
            <a href={"../company/" + comp.id} target="_blank" rel="noreferrer">
              <img
                className="mw-100 company_logo"
                style={{ maxHeight: "10rem" }}
                src={comp.logo}
                alt="logo not loading"
              />
            </a>
          )}
          <br />
          <a href={"../company/" + comp.id} target="_blank" rel="noreferrer">
            <strong className="centered_text">{comp.name}</strong>
          </a>
          <br />
          <p className="text-justify">
            <b>Adresse</b>
            <br />
            {comp.address}
          </p>
          {comp?.presentation ? (
            <p className="text-justify">
              <b>Description</b>
              <br />
              {parse(comp?.presentation)}
            </p>
          ) : null}
          {comp?.new_keywords ? (
            <p className="text-justify">
              <>
                <b>Mots-clés</b>
                <br />
                {comp?.new_keywords?.map((n_k, i) => (
                  <span key={n_k.name}>
                    {n_k.name}
                    {i < comp?.new_keywords!.length - 1 ? ", " : ""}
                  </span>
                ))}
              </>
            </p>
          ) : null}
          <strong className="centered_text">
            <a href={"../company/" + comp.id} target="_blank" rel="noreferrer">
              <Button>Voir la fiche</Button>
            </a>
          </strong>
          <br />
        </div>
      </Popup>
    </Marker>
  );
};

export default Map;
