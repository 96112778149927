import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { Order } from "../../models/order";
import { PaginatedResponse } from "../../models/response";
import "./Orders.css";

const Orders: React.VFC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  useEffect(() => {
    axios
      .get<PaginatedResponse<Order>>(`${process.env.REACT_APP_API_URL}/orders/`)
      .then((res) => (res.data.results ? setOrders(res.data.results) : null));
  }, []);
  return (
    <Container className="shadow  mt-4 mb-4 adaptative-width">
      <Row>
        <Col className="col-12  justify-content-center d-flex  pb-2 pt-2 mb-3  bg-primary text-light  border-bottom">
          MES COMMANDES
        </Col>
      </Row>
      <Row>
        <Col className="mb-4">
          {orders.length ? (
            orders
              .slice(0)
              .reverse()
              .map((item) => (
                <LinkContainer key={item.order_ref} to={`/order/${item.order_ref}`} style={{ cursor: "pointer" }}>
                  <Row
                    key={item.order_ref}
                    className={"border rounded py-2 m-2" + (item.payment_status !== "PAID" ? " nonvalid" : "")}
                  >
                    <Col className="adaptative-width">
                      <h5>
                        Commande n°{String(item.order_ref).padStart(10, "0")}{" "}
                        {item.payment_status !== "PAID" && (
                          <span className="text-primary">
                            {" "}
                            --{" "}
                            {item.payment_status === "PENDING"
                              ? "En attente de paiement"
                              : item.payment_status === "UNPAID"
                              ? "Non payé"
                              : item.payment_status}
                          </span>
                        )}
                      </h5>
                      <Row className="text-muted">
                        <Col className="text-truncate">{item.date}</Col>
                        <Col lg={4}></Col>
                      </Row>
                    </Col>
                    <Col lg="auto" className="d-flex align-items-center">
                      <h4 className="font-weight-normal my-0">
                        {item.studies.map((study) => (study.price ? study.price : 0)).reduce((a, b) => a + b, 0)}€
                      </h4>
                    </Col>
                  </Row>
                </LinkContainer>
              ))
          ) : (
            <div
              className="h2 h1-sm text-muted  m-auto h-100 w-100 text-center"
              style={{ display: "grid", minHeight: "50vh" }}
            >
              <div className="m-auto">AUCUNE COMMANDE</div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Orders;
