import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import EcosystemsSearch, { SearchState } from "../components/Ecosystems/EcosystemsSearch";

import "./Ecosystems.css";

const Ecosystems: React.VFC = () => {
  const [searchState, setSearchState] = useState<SearchState>();

  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(decodeURI(location.search));
      setSearchState({
        activityDomains: params.getAll("activity_domains"),
        countries: params.getAll("country"),
        companyTypes: params.getAll("company_type"),
        search: params.get("search") || "",
      });
    }
  }, []);

  /**
  const {
    state: { connected },
  } = useAuth();
   */

  return (
    <div
      className="w-100 h-100 overflow-auto"
      style={{
        backgroundColor: "#131516",
      }}
    >
      <div
        className={"w-100 d-flex flex-column overflow-auto scrollable-companies justify-content-around"}
        id="scrollable-companies"
      >
        <div className="display-block">
          <h1 className="text-center px-4 mt-4 h4 h1-sm" style={{ color: "rgb(212, 208, 202)" }}>
            <span className="d-sm-block d-none">UNE VISION À 360° DES ÉCOSYSTÈMES DE DÉFENSE</span>
            <span className="d-sm-none d-block h1">
              Proxxi Explore <br />
            </span>
          </h1>
        </div>
        <EcosystemsSearch search={() => null} initState={searchState} inHome={false} />

        <h5 className="text-center px-4 mb-4 mt-4" style={{ color: "rgb(212, 208, 202)" }}>
          <span className="d-sm-block d-none">
            Construisez vos cartographies tactiques et stratégiques :<br />
            écosystèmes d’innovation, environnement concurrentiel, liens capitalistiques, marchés potentiels
          </span>
        </h5>
      </div>
    </div>
  );
};

export default Ecosystems;
