import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, /**FormControl,*/ InputGroup, Row } from "react-bootstrap";
import { Search, X, Globe } from "react-bootstrap-icons";
import { Option } from "react-multi-select-component/dist/lib/interfaces";

import { ActivityDomain } from "../../models/activitydomain";
import { CompanyType } from "../../models/companytype";
import { Country } from "../../models/country";
import { PaginatedResponse } from "../../models/response";
import Select from "../Select";
import { namedSort } from "../../utils/sort";
import "./CompaniesSearch.css";
import { LinkContainer } from "react-router-bootstrap";
import { useAuth } from "../../utils/auth";
import { Subscription } from "../../models/subscription";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Company, Keyword } from "../../models/company";

type SearchState = {
  activityDomains: string[];
  countries: string[];
  companyTypes: string[];
  search: string;
};

interface CompaniesSearchProps {
  search: (path: string) => void;
  inHome?: boolean;
  initState?: SearchState;
}

export const CompaniesSearch: React.FC<CompaniesSearchProps> = (props: CompaniesSearchProps) => {
  const [activityDomains, setActivityDomains] = useState<ActivityDomain[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [companyTypes, setCompanyTypes] = useState<CompanyType[]>([]);

  const [searchBar, setSearchBar] = useState<string>("");
  const [searchCountry, setSearchCountry] = useState<Option[]>([]);
  const [countryLoaded, setCountryLoaded] = useState(false);
  const [searchActivityDomains, setSearchActivityDomains] = useState<Option[]>([]);
  const [activityDomainsLoaded, setActivityDomainsLoaded] = useState(false);
  const [searchCompanyTypes, setSearchCompanyTypes] = useState<Option[]>([]);
  const [companyTypesLoaded, setCompanyTypesLoaded] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscription, setSubscription] = useState<Subscription>();

  useEffect(() => {
    axios
      .get<Subscription>(`${process.env.REACT_APP_API_URL}/subscriptions/current/`)
      .then((res) => (res.data ? setSubscription(res.data) : null));
  }, []);

  const {
    state: { connected },
  } = useAuth();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/subscriptions/is_subscribed/`).then((res) => {
      res.data.is_subscribed ? setIsSubscribed(res.data.is_subscribed) : setIsSubscribed(false);
    });
  }, []);

  useEffect(() => {
    if (props.initState && activityDomainsLoaded && countryLoaded && companyTypesLoaded) {
      setSearchActivityDomains(
        props.initState.activityDomains.map((id) => ({
          value: +id,
          label: activityDomains.find((ad) => ad.id === +id)?.name || "",
        })),
      );
      setSearchCountry(
        props.initState.countries.map((code) => ({
          value: code,
          label: countries.find((c) => c.code === code)?.name || "",
        })),
      );
      setSearchCompanyTypes(
        props.initState.companyTypes.map((id) => ({
          value: +id,
          label: companyTypes.find((ct) => ct.id === +id)?.name || "",
        })),
      );
      setSearchBar(props.initState.search);
    }
  }, [props.initState, activityDomainsLoaded, countryLoaded, companyTypesLoaded]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      event.key === "Enter" ? document.getElementById("search-bar-btn")?.click() : null;
    };
    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams();
    searchCountry.forEach((c) => params.append("country", c.value));
    searchCompanyTypes.map((t) => params.append("company_type", t.value));

    axios
      .get<PaginatedResponse<ActivityDomain>>(`${process.env.REACT_APP_API_URL}/activitydomains/?${params.toString()}`)
      .then((res) => (res.data.results ? setActivityDomains(res.data.results) : null))
      .then(() => setActivityDomainsLoaded(true));
  }, [searchCountry, searchCompanyTypes]);

  useEffect(() => {
    const params = new URLSearchParams();
    searchActivityDomains.forEach((c) => params.append("activity_domains", c.value));
    searchCompanyTypes.map((t) => params.append("company_type", t.value));

    axios
      .get<PaginatedResponse<Country>>(`${process.env.REACT_APP_API_URL}/countries/?${params.toString()}`)
      .then((res) => (res.data.results ? setCountries(namedSort(res.data.results)) : null))
      .then(() => setCountryLoaded(true));
  }, [searchActivityDomains, searchCompanyTypes]);

  useEffect(() => {
    const params = new URLSearchParams();
    searchActivityDomains.forEach((c) => params.append("activity_domains", c.value));
    searchCountry.map((t) => params.append("country", t.value));

    axios
      .get<PaginatedResponse<CompanyType>>(`${process.env.REACT_APP_API_URL}/companytypes/?${params.toString()}`)
      .then((res) => (res.data.results ? setCompanyTypes(res.data.results) : null))
      .then(() => setCompanyTypesLoaded(true));
  }, [searchActivityDomains, searchCountry]);

  useEffect(() => {
    axios
      .get<PaginatedResponse<ActivityDomain>>(process.env.REACT_APP_API_URL + "/activitydomains/")
      .then((res) => (res.data.results ? setActivityDomains(res.data.results) : null));
    axios
      .get<PaginatedResponse<Country>>(process.env.REACT_APP_API_URL + "/countries/")
      .then((res) => (res.data.results ? setCountries(namedSort(res.data.results)) : null));
    axios
      .get<PaginatedResponse<CompanyType>>(process.env.REACT_APP_API_URL + "/companytypes/")
      .then((res) => (res.data.results ? setCompanyTypes(res.data.results) : null));
  }, []);

  const search = () => {
    const searchString = searchPath();
    props.search(searchString);
  };

  const searchPath = () => {
    let searchString = "?";
    const params = new URLSearchParams();

    searchCountry.forEach((option) => params.append("country", option.value));
    searchActivityDomains.forEach((option) => params.append("activity_domains", option.value));
    searchCompanyTypes.forEach((option) => params.append("company_type", option.value));

    if (searchBar !== "") {
      params.append("search", searchBar);
    }

    searchString = searchString + params.toString();
    //props.search(searchString);
    return searchString;
  };

  useEffect(() => {
    if (!props.inHome) {
      const timeout = setTimeout(() => search(), 400);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [searchBar]);

  useEffect(() => {
    if (!props.inHome) {
      search();
    }
  }, [searchActivityDomains, searchCompanyTypes, searchCountry]);

  return (
    <Row className="text-secondary pb-4 mx-0 pt-3">
      <Row className="bg-white p-3 shadow-sm border rounded justify-content-center mx-auto adaptative-width">
        <InputGroup className="mb-1">
          <AutocompleteSearch setSearchBar={setSearchBar} searchBar={searchBar} />
          {searchBar && (
            <X
              size={32}
              className="text-muted align-center font-weight-light h-100"
              style={{ position: "absolute", cursor: "pointer", right: "3em" }}
              onClick={() => setSearchBar("")}
            />
          )}
          <InputGroup.Append>
            <Button
              id="search-bar-btn"
              onClick={() => {
                search();
              }}
            >
              <Search />
            </Button>
            <Button
              id="map-bar-btn"
              onClick={() => {
                window.open("/proxximap/" + searchPath(), "_blank");
              }}
            >
              <Globe />
            </Button>
          </InputGroup.Append>
        </InputGroup>
        <Row className="w-100 mt-3 text-left">
          <Select
            className="mb-2"
            id="activity-domains"
            label="Domaines d'activité"
            options={activityDomains.map((a) => ({ label: a.name, value: a.id }))}
            value={searchActivityDomains}
            size={4}
            onChange={setSearchActivityDomains}
          />
          <Select
            className="mb-2"
            id="country"
            label="Pays"
            options={countries
              .map((c) => ({ label: c.name, value: c.code }))
              .sort((a, b) =>
                a.label.normalize("NFD").replace(/\p{Diacritic}/gu, "") <=
                b.label.normalize("NFD").replace(/\p{Diacritic}/gu, "")
                  ? -1
                  : 1,
              )}
            value={searchCountry}
            size={4}
            onChange={setSearchCountry}
          />
          <Select
            className="mb-2"
            id="company-type"
            label="Type d'entité"
            options={companyTypes.map((a) => ({ label: a.name, value: a.id }))}
            value={searchCompanyTypes}
            size={4}
            onChange={setSearchCompanyTypes}
          />
        </Row>
        {!isSubscribed || !connected ? (
          <LinkContainer to="/subscriptions/">
            <a id="subscription-link">S&apos;abonner</a>
          </LinkContainer>
        ) : subscription?.subscription_type.proxxi_ID_access ? (
          <a id="subscription-link">Abonnement à Proxxi ID valide</a>
        ) : (
          <LinkContainer to="/subscriptions/">
            <a id="subscription-link">Mettre à niveau l&apos;abonnement</a>
          </LinkContainer>
        )}
      </Row>
    </Row>
  );
};

import { Dispatch, SetStateAction } from "react";

export const AutocompleteSearch: React.VFC<AutocompleteSearchProps> = (props: AutocompleteSearchProps) => {
  const [loading, setLoading] = useState(false);
  const [keywordLoaded, setKeywordLoaded] = useState(false);
  const [companyloaded, setCompanyLoaded] = useState(false);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [companies, setCompanies] = useState<string[]>([]);
  const [keywordOptions, setKeywordOptions] = useState<AutoCompleteOptionType[]>([]);
  const [companyOptions, setCompanyOptions] = useState<AutoCompleteOptionType[]>([]);

  interface AutoCompleteOptionType {
    inputValue?: string;
    title: string;
    company?: Company;
    keyword?: Keyword;
  }

  const autoCompleteOptions: readonly AutoCompleteOptionType[] = companyOptions.concat(keywordOptions);

  const loadAutocompleteData = async () => {
    if (!loading) {
      setLoading(true);
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/keywords/autocomplete/`)
      .then((res) => {
        setKeywords(res.data.keywords);
      })
      .then(() => {
        setKeywordLoaded(true);
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}/companies/autocomplete/`)
      .then((res) => {
        setCompanies(res.data.companies);
      })
      .then(() => {
        setCompanyLoaded(true);
      });
  };

  useEffect(() => {
    if (!loading && !keywordLoaded && !companyloaded) {
      loadAutocompleteData();
    }
  }, []);

  useEffect(() => {
    setKeywordOptions(
      keywords.map((a) => ({
        title: a,
      })),
    );
  }, [keywords]);

  useEffect(() => {
    setCompanyOptions(
      companies.map((a) => ({
        title: a,
      })),
    );
  }, [companies]);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: AutoCompleteOptionType) => option.title,
    limit: 100,
  });

  return (
    <Autocomplete
      filterOptions={filterOptions}
      className="mx-auto adaptative-width"
      multiple
      id="tags-filled"
      options={autoCompleteOptions}
      getOptionLabel={(option) => {
        if (typeof option == "string") {
          return option;
        }
        return option.title;
      }}
      // defaultValue={null}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label="Entreprises, segments, technologies ..."
          placeholder="Rechercher"
        />
      )}
      onChange={(event, newValue: (string | AutoCompleteOptionType)[]) => {
        if (newValue !== undefined && newValue !== null && newValue.length > 0) {
          props.setSearchBar(
            newValue
              .map((a) => {
                if (typeof a == "string") {
                  return a;
                }
                return a.title;
              })
              .join(" "),
          );
        } else {
          props.setSearchBar("");
        }
      }}
    />
  );
};

export type { SearchState };

export interface AutocompleteSearchProps {
  searchBar: string;
  setSearchBar: Dispatch<SetStateAction<string>>;
}
