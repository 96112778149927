import React /**, { useEffect, useState }*/, { useEffect, useState } from "react";
import ForceGraph2D, { GraphData, NodeObject } from "react-force-graph-2d";

import axios, { AxiosError } from "axios";
import { useAuth } from "../utils/auth";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import GraphMappingSidebar, { linkColors } from "./GraphMappingSidebar";

/**
import axios from "axios";
import { PaginatedResponse } from "../models/response";
import { render } from "react-dom";
 */
//import { Link } from "react-router-dom";

const GraphMapping2D: React.VFC = () => {
  const [graphData, setGraphdata] = useState();
  const [nodeCount, setNodeCount] = useState();
  const [linkCount, setLinkCount] = useState();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [textMode, setTextMode] = useState(false);
  const [largeGraph, setLargeGraph] = useState(false);
  const [colorLinks, setColorLinks] = useState(false);
  const params = new URLSearchParams(decodeURI(location.search));
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showGetSubscribedModal, setShowGetSubscribedModal] = useState(false);

  const history = useHistory();

  const {
    state: { connected },
  } = useAuth();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/subscriptions/is_subscribed/`).then((res) => {
      res.data.is_subscribed ? setIsSubscribed(res.data.is_subscribed) : setIsSubscribed(false);
    });
  }, []);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      event.key === "r" ? setTextMode(true) : null;
      event.key === "t" ? setTextMode(false) : null;
      //event.key === "c" ? setColorLinks(true) : null;
      //event.key === "v" ? setColorLinks(false) : null;
    };
    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    if (params.get("text_mode") !== null) {
      setTextMode(params.get("text_mode")!.toLowerCase() == "true");
    }
  }, []);

  useEffect(() => {
    if (params.get("large_graph") !== null) {
      setLargeGraph(params.get("large_graph")!.toLowerCase() == "true");
    }
  }, []);

  useEffect(() => {
    if (params.get("color_links") !== null) {
      setColorLinks(params.get("color_links")!.toLowerCase() == "true");
    }
  }, []);

  const loadGraphData = async () => {
    if (!loading && !loaded) {
      setLoading(true);
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/ecosystemmapping/?` + params.toString())
      .then((res) => {
        setNodeCount(res.data.node_count || 0);
        setLinkCount(res.data.link_count || 0);
        setGraphdata(res.data);
      })
      .then(() => {
        setLoaded(true);
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status == 403) {
          setShowGetSubscribedModal(true);
        }
      });
  };

  useEffect(() => {
    if (nodeCount !== null && linkCount !== null) {
      document.title = nodeCount + " nodes / " + linkCount + " links";
    }
  }, [nodeCount, linkCount]);

  useEffect(() => {
    if (!loaded && !loading) {
      loadGraphData();
    }
  }, []);

  if (loading) {
    return (
      <div className="center-screen">
        <CirclesWithBar color="red" outerCircleColor="red" innerCircleColor="black" />
      </div>
    );
  }

  if (showGetSubscribedModal) {
    return (
      <Modal
        show={showGetSubscribedModal}
        onHide={() => {
          history.push("/");
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Abonnement requis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Pour accéder à cette interface, vous devez bénéficier d&apos;un abonnement incluant Proxxi Explore.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              history.push("/subscriptions/");
            }}
          >
            {connected && isSubscribed ? "Mettre à niveau" : "Souscrire"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <Mapping2DCard graphData={graphData!} textMode={textMode} largeGraph={largeGraph} colorLinks={colorLinks} />
    </>
  );
};

type Mapping2dProps = {
  graphData: GraphData;
  textMode: boolean;
  largeGraph: boolean;
  colorLinks?: boolean;
};

const Mapping2DCard: React.VFC<Mapping2dProps> = (props: Mapping2dProps) => {
  const currentNode: NodeObject | null = null;
  const colors = linkColors;

  return (
    <>
      <GraphMappingSidebar graphData={props.graphData} currentNode={currentNode!} />
      <ForceGraph2D
        graphData={props.graphData!}
        linkColor={(link) => {
          return colors[link["relation"]];
        }}
        nodeAutoColorBy="group"
        //linkAutoColorBy={props.colorLinks ? "relation" : null!}
        nodeCanvasObject={
          props.textMode
            ? (node, ctx, globalScale) => {
                const label = node["name"]; // + " " + <FlagIcon code={node["country"]["iso"].toLowerCase()} />;
                const fontSize = 12 / globalScale;
                ctx.font = `${fontSize}px Sans-Serif`;
                const textWidth = ctx.measureText(label).width;
                const bckgDimensions = [textWidth, fontSize].map((n) => n + fontSize * 0.2); // some padding

                ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
                ctx.fillRect(
                  node.x! - bckgDimensions[0] / 2,
                  node.y! - bckgDimensions[1] / 2,
                  bckgDimensions[2],
                  bckgDimensions[3],
                );

                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.fillStyle = node["color"];
                ctx.fillText(label, node.x!, node.y!);

                //node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
              }
            : null!
        }
        linkDirectionalParticles={props.largeGraph ? 1 : null!}
        onNodeDragEnd={(node) => {
          node.fx = node.x;
          node.fy = node.y;
        }}
      />
    </>
  );
};

export default GraphMapping2D;
