import axios from "axios";
import parse from "html-react-parser";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Cart4, CartCheckFill, Check } from "react-bootstrap-icons";
import { Link, useParams } from "react-router-dom";

import Loading from "../components/Loading";
import { Study } from "../models/study";
import { useAuth } from "../utils/auth";
import { addCartItem, useCart } from "../utils/cart";
import { wrapAsync } from "../utils/context";
import "./StudyPresentation.css";

const StudyPresentation: React.VFC = () => {
  const [study, setStudy] = useState<Study>();
  const [studiesBought, setStudiesBought] = useState<number[]>([]);

  const params = useParams<{ id: string }>();

  const {
    state: { connected },
  } = useAuth();

  const {
    state: { items },
    dispatch,
  } = useCart();
  const asyncDispatch = useMemo(() => wrapAsync(dispatch), [dispatch]);

  useEffect(() => {
    axios.get<Study>(`${process.env.REACT_APP_API_URL}/studies/${params.id}/`).then((res) => setStudy(res.data));
  }, [params.id]);

  useEffect(() => {
    axios.get<number[]>(process.env.REACT_APP_API_URL + "/studies/bought/").then((res) => setStudiesBought(res.data));
  }, []);

  const inCart = useMemo(() => items.filter((i) => i.study.id === study?.id).length > 0, [study]);
  const bought = useMemo(() => study?.id !== undefined && studiesBought.includes(study.id), [studiesBought, study]);

  const frenchCompanies = useMemo(
    () =>
      study?.french_companies?.map((c) =>
        c.ref ? (
          <Link key={c.ref} to={`/company/${c.ref}`}>
            {c.name}
          </Link>
        ) : (
          <>{c.name}</>
        ),
      ),
    [study?.french_companies],
  );
  const foreignCompanies = useMemo(
    () =>
      study?.foreign_companies?.map((c) =>
        c.ref ? (
          <Link key={c.ref} to={`/company/${c.ref}`}>
            {c.name}
          </Link>
        ) : (
          <>{c.name}</>
        ),
      ),
    [study?.foreign_companies],
  );

  return study ? (
    <Container fluid className="h-100 overflow-auto p-sm-4 px-0">
      <Row className="justify-content-center mx-auto my-4" style={{ width: "90%" }}>
        <Col lg={8} xs={12}>
          <div className="bg-white shadow p-4 border h-100 mb-4">
            <Row>
              <Col className="mb-2">
                <h4>{study?.title}</h4>
                <h6 className="font-weight-light text-muted">{study?.subtitle}</h6>
              </Col>
            </Row>
            <Row>
              <Col className="mb-2 text-justify" style={{ fontSize: ".9rem" }}>
                <img
                  style={{ float: "right" }}
                  className="ml-3 cover-image"
                  src={study?.cover || "https://via.placeholder.com/414x600/FAFBFC/AAAAAA?text=COVER"}
                  alt={study.title}
                />
                <p>{parse(study?.foreword || "")}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="mb-0 text-muted" style={{ fontSize: ".8rem" }}>
                  {study?.publication_date ? `Parution : ${study?.publication_date} | ` : null}
                  {study?.pages ? study?.pages + " page" + (study?.pages > 1 ? "s" : "") : null}
                </p>
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={4} xs={12}>
          <div className="bg-white shadow p-4 border h-100 mt-lg-0 mt-4">
            <h4>Plan de l&apos;étude</h4>
            <p style={{ fontSize: ".9rem" }}>{parse(study?.contents || "")}</p>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center mx-auto my-4" style={{ width: "90%" }}>
        <Col lg={8} xs={12}>
          <div
            className="bg-white shadow p-4 border mt-lg-0 mt-4"
            style={{
              opacity: (study.french_companies || []).length > 0 || (study.foreign_companies || []).length > 0 ? 1 : 0,
            }}
          >
            <h4>Entités mentionnées</h4>
            <div style={{ fontSize: ".9rem" }}>
              {(study.french_companies || []).length > 0 || (study.foreign_companies || []).length > 0 ? (
                <>
                  {frenchCompanies && frenchCompanies.length > 0 && (
                    <Row className="mb-2">
                      <Col>
                        <span className="mr-2 font-weight-bold">Entreprises françaises :</span>
                        <span>
                          {frenchCompanies.reduce(
                            (acc: JSX.Element | null, x) =>
                              acc === null ? (
                                x
                              ) : (
                                <>
                                  {acc} | {x}
                                </>
                              ),
                            null,
                          )}
                        </span>
                      </Col>
                    </Row>
                  )}
                  {foreignCompanies && foreignCompanies.length > 0 && (
                    <Row>
                      <Col>
                        <span className="mr-2 font-weight-bold">Entités étrangères :</span>
                        <span>
                          {foreignCompanies.reduce(
                            (acc: JSX.Element | null, x) =>
                              acc === null ? (
                                x
                              ) : (
                                <>
                                  {acc} | {x}
                                </>
                              ),
                            null,
                          )}
                        </span>
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <p>NC</p>
              )}
            </div>
          </div>
        </Col>
        <Col lg={4} xs={12}>
          <div className="bg-white shadow p-4 border d-flex flex-column align-items-center mt-lg-0 mt-4">
            <h3 className="text-muted">{study.price}€</h3>
            <Button
              className="border w-75"
              onClick={(e) => {
                e.stopPropagation();
                asyncDispatch(addCartItem(study, connected));
              }}
              disabled={bought}
              active={inCart && !bought}
              variant="primary"
              size="lg"
              block
            >
              {bought ? "Déjà achetée" : inCart ? "Dans votre panier " : "Ajouter au panier "}
              {bought ? <Check size={20} /> : inCart ? <CartCheckFill size={20} /> : <Cart4 size={20} />}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  ) : (
    <Loading />
  );
};

export default StudyPresentation;
