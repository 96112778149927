import React from "react";
import { Linkedin, Twitter } from "react-bootstrap-icons";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import "./BottomBar.css";
interface BottomBarProps {
  fixed?: boolean;
}
const BottomBar = (props: BottomBarProps): JSX.Element => {
  return (
    <>
      <div
        className={`text-white bg-primary py-2 d-flex bottom-bar-${props.fixed ? "fixed" : "inline"}`}
        style={{ zIndex: 1 }}
      >
        <Link className="text-white w-100 text-center" to="/mentionlegales">
          <span className="d-none d-sm-inline">Mentions légales</span>
          <span className="d-inline d-sm-none">Légal</span>
        </Link>
        <Link className="text-white w-100 text-center" to="/politiqueconfidentialite">
          <span className="d-none d-sm-inline">Politique de</span> Confidentialité
        </Link>
        <a
          className="text-white w-100 text-center"
          href="https://api.affinis-defense.fr/media/cgv.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <span className="d-none d-sm-inline">CGV</span>
          <span className="d-inline d-sm-none">CGV</span>
        </a>
        <Link className="text-white w-100 text-center" to="/profile/contact">
          <span className="d-none d-sm-inline">Contact</span>
          <span className="d-inline d-sm-none">Contact</span>
        </Link>
        <div style={{ width: "33%", minWidth: "70px" }}>
          <a
            href="https://www.linkedin.com/showcase/affinis-defense/"
            className="text-center text-white"
            target="_blank"
            rel="noreferrer"
          >
            <Linkedin />
          </a>
          <a
            href="https://twitter.com/Affinis_Defense/"
            className="text-center text-white"
            style={{ marginLeft: "1rem" }}
            target="_blank"
            rel="noreferrer"
          >
            <Twitter />
          </a>
        </div>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Accepter"
        declineButtonText="Tout refuser"
        style={{ zIndex: 2 }}
        containerClasses="bg-white text-dark shadow-lg border-top"
        enableDeclineButton
        buttonClasses="bg-primary text-white"
        declineButtonClasses="bg-white text-primary border border-primary"
      >
        Nous utilisons des cookies pour le fonctionnement de notre site. En acceptant, vous consentez à notre
        utilisation de ces cookies.
      </CookieConsent>
    </>
  );
};

export default BottomBar;
