import axios from "axios";
import React from "react";
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./utils/auth";
import { CartProvider } from "./utils/cart";
import "./App.scss";
import "./App.css";
import Topbar from "./components/Topbar";
import Main from "./views/Main";

// import ReactGA from "react-ga";

// ReactGA.initialize("G-B6KENCL14K");

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
  config.xsrfCookieName = "csrftoken";
  config.xsrfHeaderName = "X-CSRFToken";
  return config;
});

const App: React.FC = () => {
  return (
    <div className="d-flex flex-column h-100 bg-light">
      <link rel="stylesheet" href={process.env.REACT_APP_API_URL + "/static/flags/sprite-hq.css"}></link>
      <BrowserRouter>
        <AuthProvider>
          <CartProvider>
            <Topbar />
            <Main />
          </CartProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
