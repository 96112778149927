import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, ButtonGroup, Form, Modal } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";

const Subscriptions: React.VFC = () => {
  const history = useHistory();
  const [showCGV, setShowCGV] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [CGVAccepted, setCGVAccepted] = useState(false);

  const closeCGV = () => {
    setShowCGV(false);
    setCGVAccepted(false);
  };
  const closeLoading = () => {
    setShowLoading(false);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/subscriptions/is_subscribed/`)
      .then((res) => (res.data.is_subscribed ? history.push("/") : null));
  }, []);

  const launchOrder = () => {
    if (process.env.REACT_APP_STRIPE_PK) {
      loadStripe(process.env.REACT_APP_STRIPE_PK).then((stripe) => {
        if (stripe) {
          axios
            .get(`${process.env.REACT_APP_API_URL}/stripe/createSession/subscription/`)
            .then((res) => stripe.redirectToCheckout({ sessionId: res.data.id }));
        }
      });
    }
  };

  return (
    <Container className="shadow my-4 adaptative-width">
      <Modal show={showCGV} onHide={closeCGV}>
        <Modal.Header closeButton>{/* <Modal.Title>Conditions générales de vente</Modal.Title> */}</Modal.Header>
        <Modal.Body>
          Vous devez accepter les{" "}
          <a
            className="w-100 text-center"
            href="https://api.affinis-defense.fr/media/cgv.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <span className="d-inline">Conditions Générales de Vente</span>
          </a>
          <Form.Check
            type="checkbox"
            onChange={(e) => {
              setCGVAccepted(e.target.checked);
            }}
            label={"J'accepte les CGV"}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={`success`}
            disabled={!CGVAccepted}
            onClick={() => {
              launchOrder();
              closeCGV();
              setShowLoading(true);
            }}
          >
            Commander
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showLoading} onHide={closeLoading}>
        <Modal.Body>
          Votre commande est en cours de préparation, veuillez patienter. Vous serez bientôt rediriger vers une page de
          paiement sécurisée.
        </Modal.Body>
      </Modal>
      <Row>
        <Col className="col-12 justify-content-center d-flex py-2 mb-3 bg-primary text-light border-bottom">
          ABONNEMENT
        </Col>
      </Row>
      <Row>
        <Col className="p-4">
          <Row className="m-1">
            Afin d&apos;accéder à toutes les fiches entreprises de notre base de données, vous pouvez souscrire à un
            abonnement annuel :
          </Row>
          <Row className="justify-content-center mb-2 mt-4">
            <ButtonGroup className="mr-2" aria-label="First group">
              <LinkContainer to="/profile/contact/">
                <Button>Contactez nous</Button>
              </LinkContainer>
            </ButtonGroup>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Subscriptions;
