import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import FormFileInput from "react-bootstrap/esm/FormFileInput";

const Admin: React.FC = () => {
  return (
    <Container className="shadow mt-4 mb-4 adaptative-width px-0">
      <Col className="col-12  justify-content-center d-flex  pb-2 pt-2 mb-3  bg-primary text-light  border-bottom">
        Administration
      </Col>

      <Col className="col-12  justify-content-center d-flex mb-4">
        <a
          style={{ display: "table-cell" }}
          href={`${process.env.REACT_APP_API_URL}/Ij6p0stVRsH1SpcP/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button className="btn-secondary">Administration django</Button>
        </a>
      </Col>
      <Col className="col-12  justify-content-center d-flex  pb-2 pt-2 mb-3  bg-primary text-light  border-bottom">
        Charger un fichier d&apos;études
      </Col>
      <Col className="col-12  justify-content-center d-flex mb-4">
        <FileLoader uploadRoute={`${process.env.REACT_APP_API_URL}/studies/import/`} askForCountry={false} />
      </Col>
      <Col className="col-12  justify-content-center d-flex  pb-2 pt-2 mb-3  bg-primary text-light  border-bottom">
        Charger un fichier d&apos;entreprises
      </Col>
      <Col className="col-12  justify-content-center d-flex mb-4 pb-4">
        <FileLoader uploadRoute={`${process.env.REACT_APP_API_URL}/companies/import/`} askForCountry={true} />
      </Col>
      <Col className="col-12  justify-content-center d-flex mb-4 pb-4">
        <a href="https://fr.wikipedia.org/wiki/ISO_3166-1">Lien vers la norme ISO 3166</a>
      </Col>
    </Container>
  );
};

const FileLoader = (props: { uploadRoute: string; askForCountry: boolean }) => {
  const [showButton, setShowButton] = useState(false);
  const [file, setFile] = useState<File>();
  const [country, setCountry] = useState("");

  const fileUploadId = `${props.uploadRoute.split("/")[1]}-file-upload`;

  const uploadFile = () => {
    if (file !== undefined && (!props.askForCountry || (country && country.length === 2))) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("country", country);
      axios
        .put(props.uploadRoute, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          setFile(undefined);
          setCountry("");
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (document.getElementById(fileUploadId) as any).value = "";
        })
        .then(() => alert("Modifications enregistrées"))
        .catch((err) => {
          alert(err.response.data);
        });
    } else {
      if (file === undefined) {
        alert("Fichier invalide");
      } else if (!country) {
        alert("Veuillez spécifier un pays (code ISO 3166-1 alpha-2)");
      } else if (country.length !== 2) {
        alert("Format de pays invalide (code ISO 3166-1 alpha-2)");
      }
    }
  };

  return (
    <Row className="justify-content-between w-100">
      <Col className="col-12 mt-0 mt-md-3 col-md-4">
        <Row>
          <FormFileInput
            type="file"
            name="file"
            id={fileUploadId}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: any) => {
              setShowButton(true);
              setFile(e.target.files[0]);
            }}
          />
        </Row>
      </Col>
      {props.askForCountry ? (
        <Col className="col-12 mt-3 col-md-5 col-lg-6">
          <Form.Control
            placeholder="Pays (Code ISO 3166-1 alpha-2)"
            className="mr-4"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </Col>
      ) : null}
      <Col className="col-12 mt-3 col-md-3 col-lg-2 ">
        <Row className="justify-content-end">
          <Button onClick={uploadFile} disabled={!showButton}>
            Importer
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default Admin;
