import axios from "axios";
import React, { useState } from "react";
import { Container, Form, Button, Col } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";

const PasswordResetConfirm: React.VFC = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] = useState("");

  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");

  const resetPassword = () => {
    setPasswordError("");
    setPasswordConfirmationError("");
    if (password !== passwordConfirmation) {
      setPasswordConfirmationError("Les mots de passes doivent être identiques");
      return;
    }
    axios
      .post(process.env.REACT_APP_API_URL + "/password_reset/confirm/", { password, token })
      .then(() => history.push("/login"))
      .catch(({ response }) => setPasswordError(response.data.password.join(" ")));
  };

  return (
    <Container fluid className="h-100 d-flex align-items-center">
      <Form className="w-50 bg-white m-auto shadow">
        <Col className="text-center py-2 mb-3 bg-primary text-light">RÉINITIALISER VOTRE MOT DE PASSE</Col>
        <Form.Group className="mx-4">
          <Form.Label>Nouveau mot de passe</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            isInvalid={passwordError !== ""}
          />
          {passwordError && <Form.Text className="text-danger">{passwordError}</Form.Text>}
        </Form.Group>
        <Form.Group className="mx-4">
          <Form.Label>Confirmez le nouveau mot de passe</Form.Label>
          <Form.Control
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            isInvalid={passwordConfirmationError !== ""}
          />
          {passwordConfirmationError && <Form.Text className="text-danger">{passwordConfirmationError}</Form.Text>}
        </Form.Group>

        <Button className="m-4" variant="primary" type="button" onClick={resetPassword}>
          Valider
        </Button>
      </Form>
    </Container>
  );
};

export default PasswordResetConfirm;
