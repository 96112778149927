import React from "react";
import { Col } from "react-bootstrap";
import { ChevronUp, ChevronDown, X } from "react-bootstrap-icons";
import MultiSelect from "react-multi-select-component";
import { Option } from "react-multi-select-component/dist/lib/interfaces";

const MULTISELECT_OVERRIDES = {
  allItemsAreSelected: "Tous",
  clearSearch: "Vider la recherche",
  noOptions: "Pas de résultat",
  search: "Recherche",
  selectAll: "Tout sélectionner",
  selectSomeItems: "Sélectionner...",
};

interface SelectProps {
  id: string;
  label: string;
  options: Option[];
  value: Option[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: any;
  size?: number;
  className?: string;
  hasSelectAll?: boolean;
}

const Select: React.FC<SelectProps> = (props: SelectProps) => (
  <Col lg={props.size ? props.size : 12} className={props.className ? props.className : ""}>
    <div id={props.id} className="mb-2 text-dark text-center">
      {props.label}
    </div>
    <MultiSelect
      labelledBy={props.id}
      options={props.options}
      value={props.value}
      hasSelectAll={props?.hasSelectAll ? props.hasSelectAll : false}
      onChange={props.onChange}
      overrideStrings={MULTISELECT_OVERRIDES}
      ArrowRenderer={({ expanded }) => (expanded ? <ChevronUp /> : <ChevronDown />)}
      ClearIcon={() => <X />}
    />
  </Col>
);

export default Select;
