import axios /** , { AxiosError }*/ from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import { User } from "../../models/user";
//import { Company } from "../../models/company";

const Contact: React.VFC = () => {
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  //  const params = new URLSearchParams(decodeURI(location.search));
  //  const [companyOrigin, setCompanyOrigin] = useState(null);

  const getUserProfile = () =>
    axios
      .get<User>(process.env.REACT_APP_API_URL + "/users/me/")
      .then((res) => {
        setName(`${res.data.first_name} ${res.data.last_name}`);
        setEmail(res.data.email);
      })
      .catch(() => {
        return;
      });

  useEffect(() => {
    getUserProfile();
  }, []);

  const handleClose = () => setShow(false);
  const closeSuccess = () => setShowSuccess(false);

  const send = () => {
    let hasError = false;
    setNameError("");
    setEmailError("");
    setPhoneNumberError("");
    setSubjectError("");
    setMessageError("");

    if (name.length === 0) {
      setNameError("Ce champ est obligatoire.");
      hasError = true;
    }
    if (email.length === 0) {
      setEmailError("Ce champ est obligatoire.");
      hasError = true;
    }
    if (subject.length === 0) {
      setSubjectError("Ce champ est obligatoire.");
      hasError = true;
    }
    if (message.length === 0) {
      setMessageError("Ce champ est obligatoire.");
      hasError = true;
    }

    if (!hasError) {
      axios
        .post(process.env.REACT_APP_API_URL + "/contact/", { name, email, phone_number: phoneNumber, subject, message })
        .then(() => {
          setName("");
          setEmail("");
          setPhoneNumber("");
          setSubject("");
          setMessage("");
          setShowSuccess(true);
        })
        .catch((err) => {
          setNameError((err.response.data.name || []).join(" "));
          setEmailError((err.response.data.email || []).join(" "));
          setPhoneNumberError((err.response.data.phone_number || []).join(" "));
          setSubjectError((err.response.data.subject || []).join(" "));
          setMessageError((err.response.data.message || []).join(" "));
        });
    }
  };

  return (
    <>
      <Container className="shadow mt-4 mb-4 adaptative-width">
        <Modal show={showSuccess} onHide={closeSuccess}>
          <Modal.Header closeButton>
            <Modal.Title>Succès</Modal.Title>
          </Modal.Header>
          <Modal.Body>Message envoyé avec succès !</Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={closeSuccess}>
              Fermer
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Veuillez confirmer l&apos;envoi du message &laquo;{subject}&raquo;</Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" onClick={handleClose}>
              Annuler
            </Button>
            <Button
              variant="success"
              onClick={() => {
                send();
                handleClose();
              }}
            >
              Confirmer
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col className="justify-content-center d-flex py-2 mb-3 bg-primary text-light border-bottom">
            FORMULAIRE DE CONTACT
          </Col>
        </Row>
        <Row>
          <Col className="pt-2 pb-4 px-4">
            <Form>
              <h4>Formulaire de contact</h4>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Nom*</Form.Label>
                  <Form.Control
                    autoComplete="name"
                    placeholder="Nom*"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    isInvalid={nameError !== ""}
                  />
                  {nameError && <Form.Text className="text-danger">{nameError}</Form.Text>}
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Email*</Form.Label>
                  <Form.Control
                    type="email"
                    autoComplete="email"
                    placeholder="Email*"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isInvalid={emailError !== ""}
                  />
                  {emailError && <Form.Text className="text-danger">{emailError}</Form.Text>}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} className="col-6">
                  <Form.Label>Numéro de téléphone</Form.Label>
                  <Form.Control
                    type="tel"
                    as="input"
                    autoComplete="tel"
                    placeholder="Numéro de téléphone"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    isInvalid={phoneNumberError !== ""}
                  />
                  {phoneNumberError && <Form.Text className="text-danger">{phoneNumberError}</Form.Text>}
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <Form.Label>Sujet*</Form.Label>
                <Form.Control
                  placeholder="Sujet*"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  isInvalid={subjectError !== ""}
                />
                {subjectError && <Form.Text className="text-danger">{subjectError}</Form.Text>}
              </Form.Group>
              <Form.Group>
                <Form.Label>Message*</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Message*"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  isInvalid={messageError !== ""}
                />
                {messageError && <Form.Text className="text-danger">{messageError}</Form.Text>}
              </Form.Group>
              <Form.Row className="d-flex justify-content-center">
                <Button onClick={() => setShow(true)}>Envoyer</Button>
              </Form.Row>
            </Form>
          </Col>
        </Row>
      </Container>
      <Container className="shadow mt-4 mb-4 adaptative-width">
        <Row>
          <Col className="justify-content-center d-flex py-2 mb-3 bg-primary text-light border-bottom">
            INFOS DE CONTACT
          </Col>
        </Row>
        <Row>
          <Col className="pt-2 pb-3 px-4">
            <p>81 Rue d&apos;Amsterdam 75008 Paris</p>
            <p>
              <a href="mailto:contact@affinis-defense.fr">contact@affinis-defense.fr</a>
              <br />
              Tel: +33 1 86 95 95 50
            </p>
          </Col>
        </Row>
      </Container>
      {/* <Container className="shadow mt-4 mb-4 adaptative-width px-0">
        <BottomBar />
      </Container> */}
    </>
  );
};

export default Contact;
