/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import jwt from "jsonwebtoken";
import React from "react";
import { Dispatch } from "./context";

type Action = { type: "connect" } | { type: "disconnect" } | { type: "setIsAdmin" } | { type: "noop" };
type State = { connected: boolean; isAdmin: boolean };
type AuthProviderProps = { children: React.ReactNode };

const AuthContext = React.createContext<{ state: State; dispatch: Dispatch<Action> } | undefined>(undefined);

const authReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "connect":
      return { connected: true, isAdmin: state.isAdmin };
    case "disconnect":
      disconnect();
      return { connected: false, isAdmin: false };
    case "setIsAdmin":
      return { connected: state.connected, isAdmin: true };
    case "noop":
      return { connected: state.connected, isAdmin: state.isAdmin };
    default:
      throw new Error("Unhandled action type.");
  }
};

const AuthProvider: React.FC<AuthProviderProps> = (props: AuthProviderProps) => {
  const [state, dispatch] = React.useReducer(authReducer, {
    connected: accessValid(),
    isAdmin: false,
  });
  const value = { state, dispatch };

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

const useAuth = (): { state: State; dispatch: Dispatch<Action> } => {
  const context = React.useContext(AuthContext);
  if (context == undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

const getToken = (
  username: string,
  password: string,
  onRejected: (reason: any) => void,
): Promise<{ access?: string; refresh?: string }> =>
  axios
    .post<{ access: string; refresh: string }>(process.env.REACT_APP_API_URL + "/token/", {
      username,
      password,
    })
    .then((res) => res.data)
    .catch((reason) => {
      onRejected(reason);
      return { access: undefined, refresh: undefined };
    });

const triggerRefreshToken = async (refreshToken: string | null): Promise<{ access: string; refresh: string }> => {
  const {
    data: { access, refresh },
  } = await axios.post(process.env.REACT_APP_API_URL + "/token/refresh/", {
    refresh: refreshToken,
  });

  return { access, refresh };
};

const updateLocalStorage = (access?: string, refresh?: string): void => {
  localStorage.setItem("accessToken", access || "");
  localStorage.setItem("refreshToken", refresh || "");
};

const connect = (username: string, password: string, onRejected: (reason: any) => void): Promise<boolean> =>
  getToken(username, password, onRejected).then(({ access, refresh }) => {
    updateLocalStorage(access, refresh);
    return access !== undefined;
  });

const disconnect = (): void => {
  localStorage.setItem("accessToken", "");
  localStorage.setItem("refreshToken", "");
};

const login = (username: string, password: string, onRejected: (reason: any) => void): Promise<Action> =>
  connect(username, password, onRejected).then((success) => (success ? { type: "connect" } : { type: "disconnect" }));

const checkToken = (): Promise<boolean> => {
  if (accessValid()) {
    return Promise.resolve(true);
  }

  if (refreshValid()) {
    return triggerRefreshToken(localStorage.getItem("refreshToken"))
      .then((res) => updateLocalStorage(res.access, res.refresh))
      .then(() => true);
  }
  return Promise.resolve(false);
};

const accessValid = (): boolean => {
  const accessToken = localStorage.getItem("accessToken") || "";

  return accessToken !== "" && Math.floor(Date.now() / 1000) <= jwt.decode(accessToken, { json: true })?.exp - 20;
};

const refreshValid = (): boolean => {
  const refreshToken = localStorage.getItem("refreshToken") || "";

  return refreshToken !== "" && Math.floor(Date.now() / 1000) <= jwt.decode(refreshToken, { json: true })?.exp - 1;
};

export { checkToken, useAuth, AuthProvider, login };
