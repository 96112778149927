import axios from "axios";
import React, { useEffect, useState } from "react";
import { Collapse, Fade } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";

import { Company } from "../models/company";
import { PaginatedResponse } from "../models/response";
import CompaniesResults from "../components/Companies/CompaniesResults";
import { CompaniesSearch, SearchState } from "../components/Companies/CompaniesSearch";

import "./Companies.css";

const Companies: React.VFC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [searchState, setSearchState] = useState<SearchState>();
  const [next, setNext] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [companiesCountAvailable, setCompaniesCountAvailable] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [inHome, setInHome] = useState(true);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/companies/count/`)
      .then((res) => (res.data.companies_count ? setCompaniesCountAvailable(res.data.companies_count) : null));
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/subscriptions/is_subscribed/`)
      .then((res) => (res.data.is_subscribed ? setIsSubscribed(res.data.is_subscribed) : null));
  }, []);

  const getCompanies = async (path: string) => {
    setLoading(true);
    setInHome(false);
    await Promise.all([
      axios.get<{ count: number }>(process.env.REACT_APP_API_URL + "/companies/count/" + path).then((res) => {
        setCompaniesCountAvailable(res.data.count || 0);
      }),
      axios.get<PaginatedResponse<Company>>(process.env.REACT_APP_API_URL + "/companies/" + path).then((res) => {
        setCompanies(res.data.results || []);
        setNext(res.data.next || null);
        setHasMore(res.data.next !== null);
        setCount(res.data.count || 0);
      }),
    ]);
    setLoaded(true);
    setLoading(false);
    history.push(encodeURI(path));
  };

  const fetchMore = () => {
    if (next) {
      axios.get<PaginatedResponse<Company>>(next).then((res) => {
        setCompanies((prev) => prev.concat(res.data.results || []));
        setNext(res.data.next || null);
        setHasMore(res.data.next !== null);
      });
    }
  };

  useEffect(() => {
    if (location.search) {
      getCompanies("" + decodeURI(location.search || ""));

      const params = new URLSearchParams(decodeURI(location.search));
      setSearchState({
        activityDomains: params.getAll("activity_domains"),
        countries: params.getAll("country"),
        companyTypes: params.getAll("company_type"),
        search: params.get("search") || "",
      });
    }
  }, []);

  return (
    <div className="w-100 h-100 overflow-auto">
      <div
        className={
          "w-100 d-flex flex-column overflow-auto scrollable-companies" +
          (!loading && !loaded ? " justify-content-around" : "")
        }
        id="scrollable-companies"
      >
        <Collapse in={!loading && !loaded}>
          <Fade in={!loading && !loaded}>
            <div className="display-block">
              <h1 className="text-center text-secondary px-4 mt-4 h4 h1-sm">
                <span className="d-sm-block d-none">
                  Toute l&apos;information sur les acteurs stratégiques
                  <br /> de la défense dans le monde
                </span>
                <span className="d-sm-none d-block h1">
                  Proxxi ID <br />
                </span>

                <span className="d-sm-none d-block h6">+2000 FICHES IDENTITES D&apos;ORGANISATIONS STRATEGIQUES</span>
              </h1>
            </div>
          </Fade>
        </Collapse>
        <CompaniesSearch
          search={(searchString) => getCompanies(searchString)}
          initState={searchState}
          inHome={inHome}
        />
        <Collapse in={!loading && !loaded}>
          <Fade in={!loading && !loaded}>
            <h5 className="text-center px-4 mb-4 mt-4" style={{ color: "rgb(70, 70, 70)" }}>
              <span className="d-sm-block d-none">
                Une base de données au service de vos besoins :<br />
                Identifiez vos concurrents, validez vos partenaires, appréhendez de nouveaux marchés
              </span>
            </h5>
          </Fade>
        </Collapse>
        {loaded && (
          <CompaniesResults
            companies={companies}
            fetchMore={fetchMore}
            hasMore={hasMore}
            count={count}
            companiesAvailable={companiesCountAvailable}
            isSubscribed={isSubscribed}
          />
        )}
      </div>
      {/* <BottomBar fixed /> */}
    </div>
  );
};

export default Companies;
