import React /**, { useEffect, useState }*/, { useEffect, useState } from "react";
import ForceGraph3D, { GraphData } from "react-force-graph-3d";
//import THREE from "three";
import SpriteText from "three-spritetext";

import axios, { AxiosError } from "axios";
import { useAuth } from "../utils/auth";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { linkColors } from "./GraphMappingSidebar";

/**
 import axios from "axios";
 import { PaginatedResponse } from "../models/response";
 import { render } from "react-dom";
 */
//import { Link } from "react-router-dom";

const GraphMapping3D: React.FC = () => {
  const [graphData, setGraphdata] = useState();
  const [nodeCount, setNodeCount] = useState();
  const [linkCount, setLinkCount] = useState();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [textMode, setTextMode] = useState(false);
  const [largeGraph, setLargeGraph] = useState(false);
  const [colorLinks, setColorLinks] = useState(false);
  const params = new URLSearchParams(decodeURI(location.search));
  const [isSubscribed, setIsSubscribed] = useState(false);
  //const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [showGetSubscribedModal, setShowGetSubscribedModal] = useState(false);

  const history = useHistory();

  const {
    state: { connected },
  } = useAuth();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/subscriptions/is_subscribed/`).then((res) => {
      res.data.is_subscribed ? setIsSubscribed(res.data.is_subscribed) : setIsSubscribed(false);
    });
    /**
    axios
      .get<Subscription>(`${process.env.REACT_APP_API_URL}/subscriptions/current/`)
      .then((res) => (res.data ? setSubscription(res.data) : null));
     */
  }, []);

  /**
  useEffect(() => {
    if (connected && isSubscribed && subscription && subscription.subscription_type) {
      setValidAccess(subscription.subscription_type.proxxi_explore_access);
      if (!validAccess) {
        setShowGetSubscribedModal(true);
      }
    }
  }, [connected, isSubscribed, subscription]);
   */

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      event.key === "r" ? setTextMode(true) : null;
      event.key === "t" ? setTextMode(false) : null;
      //event.key === "c" ? setColorLinks(true) : null;
      //event.key === "v" ? setColorLinks(false) : null;
    };
    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    if (params.get("text_mode") !== null) {
      setTextMode(params.get("text_mode")!.toLowerCase() == "true");
    }
  }, []);

  useEffect(() => {
    if (params.get("large_graph") !== null) {
      setLargeGraph(params.get("large_graph")!.toLowerCase() == "true");
    }
  }, []);

  useEffect(() => {
    if (params.get("color_links") !== null) {
      setColorLinks(params.get("color_links")!.toLowerCase() == "true");
    }
  }, []);

  /**
  const changeSideBarOpen = () => {
    setSideBarOpen(!sideBarOpen);
  };
   */

  const loadGraphData = async () => {
    if (!loading && !loaded) {
      setLoading(true);
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/ecosystemmapping/?` + params.toString())
      .then((res) => {
        setNodeCount(res.data.node_count || 0);
        setLinkCount(res.data.link_count || 0);
        setGraphdata(res.data);
      })
      .then(() => {
        setLoaded(true);
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status == 403) {
          setShowGetSubscribedModal(true);
        }
      });
  };

  useEffect(() => {
    if (nodeCount !== null && linkCount !== null) {
      document.title = nodeCount + " nodes / " + linkCount + " links";
    }
  }, [nodeCount, linkCount]);

  useEffect(() => {
    if (!loaded && !loading) {
      loadGraphData();
    }
  }, []);

  if (loading) {
    return (
      <div
        className="w-100 h-100 overflow-auto"
        style={{
          backgroundColor: "#131516",
          width: "100px",
          height: "100px",
        }}
      >
        <div className="center-screen">
          <CirclesWithBar color="red" outerCircleColor="red" innerCircleColor="white" />
        </div>
      </div>
    );
  }

  if (showGetSubscribedModal) {
    return (
      <Modal
        show={showGetSubscribedModal}
        onHide={() => {
          history.push("/");
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Abonnement requis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Pour accéder à cette interface, vous devez bénéficier d&apos;un abonnement incluant Proxxi Explore.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              history.push("/subscriptions/");
            }}
          >
            {connected && isSubscribed ? "Mettre à niveau" : "Souscrire"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <Mapping3DCard graphData={graphData!} textMode={textMode} largeGraph={largeGraph} colorLinks={colorLinks} />
    </>
  );
};

type Mapping3dProps = {
  graphData: GraphData;
  textMode: boolean;
  largeGraph: boolean;
  colorLinks?: boolean;
};

const Mapping3DCard: React.VFC<Mapping3dProps> = (props: Mapping3dProps) => {
  const colors = linkColors;

  return (
    <ForceGraph3D
      graphData={props.graphData!}
      nodeAutoColorBy="group"
      //linkAutoColorBy={props.colorLinks ? "relation" : null!}
      linkColor={(link) => {
        return colors[link["relation"]];
      }}
      nodeThreeObject={
        props.textMode
          ? (node) => {
              const sprite = new SpriteText(node.name);
              sprite.color = node.color;
              sprite.textHeight = 8;
              return sprite;
            }
          : null
      }
      linkDirectionalParticles={props.largeGraph ? 1 : null!}
      onNodeDragEnd={(node) => {
        node.fx = node.x;
        node.fy = node.y;
        node.fz = node.z;
      }}
    />
  );
};

export default GraphMapping3D;
