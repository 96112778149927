type Dispatch<T> = (action: T) => void;
type AsyncDispatch<T> = Dispatch<T | Promise<T>>;

function wrapAsync<T>(dispatch: Dispatch<T>): AsyncDispatch<T> {
  return (action: T | Promise<T>) => {
    if (action instanceof Promise) {
      return action.then(dispatch);
    }
    return dispatch(action);
  };
}

export { wrapAsync };
export type { Dispatch, AsyncDispatch };
