import axios from "axios";
import React, { useEffect } from "react";

import { CartItem } from "../models/cartitem";
import { PaginatedResponse } from "../models/response";
import { useAuth } from "./auth";
import { Dispatch } from "./context";
import { Study } from "../models/study";

type Action = { type: "add"; item: CartItem } | { type: "remove"; item: CartItem } | { type: "set"; items: CartItem[] };
type State = { items: CartItem[] };
type CartProviderProps = { children: React.ReactNode };

const CartContext = React.createContext<{ state: State; dispatch: Dispatch<Action> } | undefined>(undefined);

const cartReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "add":
      if (state.items.filter((i) => i.id === action.item.id).length === 0) {
        return { items: state.items.concat(action.item) };
      }
      return state;
    case "remove":
      return { items: state.items.filter((i) => i.id !== action.item.id) };
    case "set":
      return { items: action.items };
    default:
      throw new Error("Unhandled action type.");
  }
};

const CartProvider: React.FC<CartProviderProps> = (props: CartProviderProps) => {
  const [state, dispatch] = React.useReducer(cartReducer, { items: [] });
  const value = { state, dispatch };

  const {
    state: { connected },
  } = useAuth();

  useEffect(() => {
    if (connected) {
      fetchCart({ items: [] }, dispatch);
    }
  }, [connected]);

  return <CartContext.Provider value={value}>{props.children}</CartContext.Provider>;
};

const useCart = (): { state: State; dispatch: Dispatch<Action> } => {
  const context = React.useContext(CartContext);
  if (context == undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

const addCartItem = (study: Study, connected: boolean): Promise<Action> => {
  if (connected) {
    return axios
      .post<CartItem>(`${process.env.REACT_APP_API_URL}/cartitems/`, { study_id: study.id })
      .then(({ data: item }) => ({ type: "add", item }));
  }
  return Promise.resolve({ type: "add", item: { study } });
};

const removeCartItem = (item: CartItem, connected: boolean): Promise<Action> => {
  if (connected) {
    axios.delete(`${process.env.REACT_APP_API_URL}/cartitems/${item.id}/`);
  }
  return Promise.resolve({ type: "remove", item });
};

const fetchCart = async (state: State, dispatch: Dispatch<Action>): Promise<void> => {
  const items = await axios
    .get<PaginatedResponse<CartItem>>(`${process.env.REACT_APP_API_URL}/cartitems/`)
    .then((res) => res.data.results);
  if (items) {
    dispatch({ type: "set", items: state.items.concat(items) });
  }
};

export { CartProvider, useCart, addCartItem, removeCartItem, fetchCart };
