import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

const SubscriptionCheckoutResult: React.VFC = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showCanceled, setShowCanceled] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(location.search);

    if (query.get("success")) {
      setShowSuccess(true);
    } else if (query.get("canceled")) {
      setShowCanceled(true);
      axios.get(
        `${process.env.REACT_APP_API_URL}/stripe/cancelSession/subscription/?session_id=${query.get("session_id")}`,
      );
    }
  }, []);

  const closeSuccess = () => {
    setShowSuccess(false);
    history.push("/proxxiid");
  };

  const closeCanceled = () => {
    setShowSuccess(false);
    history.push("/proxxiid");
  };

  return (
    <>
      <Modal show={showSuccess} onHide={() => closeSuccess()}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Abonnement souscrit avec succès</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success" onClick={() => closeSuccess()}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCanceled} onHide={() => closeCanceled()}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Abonnement annulé avec succès</Modal.Body>
        <Modal.Footer>
          <LinkContainer to="/cart">
            <Button variant="outline-danger" onClick={() => closeCanceled()}>
              OK
            </Button>
          </LinkContainer>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubscriptionCheckoutResult;
