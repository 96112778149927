import React from "react";
import { Container } from "react-bootstrap";

export const Legal: React.VFC = () => (
  <div className="text-center overflow-auto h-100 w-100">
    <Container className="my-4">
      <p className="font-weight-bold">Mentions légales</p>
      <p>
        Affinis (SIRET 50845799100042) est une SARL immatriculée au RCS de Paris N° 508 457 991  et située à
        l&apos;adresse :
      </p>
      <p>AFFINIS</p>
      <p>81 RUE D&apos;AMSTERDAM</p>
      <p>75008 PARIS</p>
      <p>Tel: 01.86.95.95.50 </p>
      <p>Ce site web est réalisé par Affinis et hébergé par :</p>
      <p>OVH Groupe SAS</p>
      <p className="font-weight-bold">Informations personnelles</p>

      <p>
        Les informations personnelles recueillies dans le formulaire de contact font l&apos;objet d&apos;un traitement
        permettant uniquement la prise en compte de votre demande, ainsi qu’une réponse éventuelle de la part de
        Affinis.
      </p>
      <p>Ces données ne sont ni divulguées, ni revendues.</p>
      <p>
        Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, vous bénéficiez d’un
        droit d’accès et de rectification aux informations qui vous concernent en vous adressant par courrier à :
      </p>
      <p>Affinis, 81 RUE D&apos;AMSTERDAM 75008 PARIS (SIRET 50845799100042), ou via notre formulaire de contact.</p>
      <p>Vous pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous concernant.</p>
      <p className="font-weight-bold">Analyse et cookies</p>
      <p>
        Ce site utilise Google Analytics, un service d&apos;analyse de site internet fourni par Google Inc.
        (&quot;Google&quot;).
      </p>
      <p>
        Google Analytics utilise des cookies, qui sont des fichiers enregistrés sur votre ordinateur, pour aider le site
        internet à analyser l&apos;utilisation du site par ses utilisateurs. Les données générées par les cookies
        concernant votre utilisation du site (y compris votre adresse IP) seront transmises et stockées par Google sur
        des serveurs situés aux Etats-Unis.
      </p>
      <p>
        Google utilisera cette information dans le but d&apos;évaluer votre utilisation du site, de compiler des
        rapports sur l&apos;activité du site à destination de son éditeur et de fournir d&apos;autres services relatifs
        à l&apos;activité du site et à l&apos;utilisation d&apos;Internet. Google est susceptible de communiquer ces
        données à des tiers en cas d&apos;obligation légale ou lorsque ces tiers traitent ces données pour le compte de
        Google, y compris notamment l&apos;éditeur de ce site. Google ne recoupera pas votre adresse IP avec toute autre
        donnée détenue par Google.
      </p>
      <p>
        Vous pouvez désactiver l&apos;utilisation de cookies en sélectionnant les paramètres appropriés de votre
        navigateur. Cependant, une telle désactivation pourrait empêcher l&apos;utilisation de certaines fonctionnalités
        de ce site. En utilisant ce site internet, vous consentez expressément au traitement de vos données nominatives
        par Google dans les conditions et pour les finalités décrites ci-dessus.
      </p>
    </Container>
  </div>
);
