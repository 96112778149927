import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";
import { Option } from "react-multi-select-component/dist/lib/interfaces";

import "./StudiesSearch.css";
import Select from "../Select";
import { PaginatedResponse } from "../../models/response";
import { Sector } from "../../models/Sector";
import { StudyType } from "../../models/studytype";
import { StudyYear } from "../../models/studyyear";
import { Country } from "../../models/country";
import { FileEarmarkText, ChevronCompactDown } from "react-bootstrap-icons";
import { useHistory } from "react-router";
import { namedSort } from "../../utils/sort";

type SearchState = {
  studyTypes: string[];
  sectors: string[];
  studyYears: string[];
  studyCountries: string[];
  search: string;
};

type StudiesResultsProps = {
  search: (searchString: string) => void;
  inline: boolean;
  initState?: SearchState;
};

const StudiesSearch: React.FC<StudiesResultsProps> = (props: StudiesResultsProps) => {
  const [studyTypes, setStudyTypes] = useState<StudyType[]>([]);
  const [studyTypesLoaded, setStudyTypesLoaded] = useState(false);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [sectorsLoaded, setSectorsLoaded] = useState(false);
  const [studyYears, setStudyYears] = useState<StudyYear[]>([]);
  const [studyYearsLoaded, setStudyYearsLoaded] = useState(false);
  const [studyCountries, setStudyCountries] = useState<Country[]>([]);
  const [studyCountriesLoaded, setStudyCountriesLoaded] = useState(false);

  const [searchBar, setsearchBar] = useState<string>("");
  const [searchSectors, setsearchSectors] = useState<Option[]>([]);
  const [searchStudyTypes, setsearchStudyTypes] = useState<Option[]>([]);
  const [searchStudyYears, setsearchStudyYears] = useState<Option[]>([]);
  const [searchStudyCountries, setsearchStudyCountries] = useState<Option[]>([]);
  const [searchLoaded, setSearchLoaded] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (studyTypesLoaded && sectorsLoaded && studyYearsLoaded && studyCountriesLoaded) {
      if (props.initState) {
        setsearchStudyTypes(
          props.initState.studyTypes.map((id) => ({
            value: +id,
            label: studyTypes.find((st) => st.id === +id)?.name || "",
          })),
        );
        setsearchSectors(
          props.initState.sectors.map((id) => ({
            value: +id,
            label: sectors.find((s) => s.id === +id)?.name || "",
          })),
        );
        setsearchStudyCountries(
          props.initState.studyCountries.map((code) => ({
            value: code,
            label: studyCountries.find((c) => c.code === code)?.name || "",
          })),
        );
        setsearchStudyYears(
          props.initState.studyYears.map((year) => ({
            value: +year,
            label: studyYears.find((sy) => sy.year === +year)?.year.toString() || "",
          })),
        );
        setsearchBar(props.initState.search);
      } else {
        search();
      }
      setSearchLoaded(true);
    }
  }, [props.initState, studyTypesLoaded, sectorsLoaded, studyYearsLoaded, studyCountriesLoaded]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      event.key === "Enter" ? document.getElementById("search-bar-btn")?.click() : null;
    };
    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams();
    searchSectors.forEach((c) => params.append("sectors", c.value));
    searchStudyTypes.map((t) => params.append("study_type", t.value));
    searchStudyCountries.map((t) => params.append("countries", t.value));

    axios
      .get<PaginatedResponse<StudyYear>>(`${process.env.REACT_APP_API_URL}/studyyears/?${params.toString()}`)
      .then((res) => (res.data.results ? setStudyYears(res.data.results) : null))
      .then(() => setStudyYearsLoaded(true));
  }, [searchSectors, searchStudyTypes, searchStudyCountries]);

  useEffect(() => {
    const params = new URLSearchParams();
    searchStudyYears.forEach((a) => params.append("year", a.value));
    searchStudyTypes.map((t) => params.append("study_type", t.value));
    searchStudyCountries.map((t) => params.append("countries", t.value));

    axios
      .get<PaginatedResponse<Sector>>(`${process.env.REACT_APP_API_URL}/sectors/?${params.toString()}`)
      .then((res) => (res.data.results ? setSectors(res.data.results) : null))
      .then(() => setSectorsLoaded(true));
  }, [searchStudyYears, searchStudyTypes, searchStudyCountries]);

  useEffect(() => {
    const params = new URLSearchParams();
    searchStudyYears.forEach((a) => params.append("year", a.value));
    searchSectors.map((t) => params.append("sectors", t.value));
    searchStudyCountries.map((t) => params.append("countries", t.value));

    axios
      .get<PaginatedResponse<StudyType>>(`${process.env.REACT_APP_API_URL}/studytypes/?${params.toString()}`)
      .then((res) => (res.data.results ? setStudyTypes(res.data.results) : null))
      .then(() => setStudyTypesLoaded(true));
  }, [searchStudyYears, searchSectors, searchStudyCountries]);

  useEffect(() => {
    const params = new URLSearchParams();
    searchStudyYears.forEach((a) => params.append("year", a.value));
    searchSectors.map((t) => params.append("sectors", t.value));
    searchStudyTypes.map((t) => params.append("study_type", t.value));

    axios
      .get<PaginatedResponse<Country>>(`${process.env.REACT_APP_API_URL}/studycountries/?${params.toString()}`)
      .then((res) => (res.data.results ? setStudyCountries(namedSort(res.data.results)) : null))
      .then(() => setStudyCountriesLoaded(true));
  }, [searchStudyYears, searchSectors, searchStudyTypes]);

  useEffect(() => {
    axios
      .get<PaginatedResponse<StudyType>>(process.env.REACT_APP_API_URL + "/studytypes/")
      .then((res) => (res.data.results ? setStudyTypes(res.data.results) : null));
    axios
      .get<PaginatedResponse<StudyYear>>(process.env.REACT_APP_API_URL + "/studyyears/")
      .then((res) => (res.data.results ? setStudyYears(res.data.results) : null));
    axios
      .get<PaginatedResponse<Sector>>(process.env.REACT_APP_API_URL + "/sectors/")
      .then((res) => (res.data.results ? setSectors(res.data.results) : null));
    axios
      .get<PaginatedResponse<Country>>(process.env.REACT_APP_API_URL + "/studycountries/")
      .then((res) => (res.data.results ? setStudyCountries(namedSort(res.data.results)) : null));
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => (searchLoaded ? search() : null), 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchBar]);

  useEffect(() => {
    if (searchLoaded) {
      search();
    }
  }, [searchSectors, searchStudyTypes, searchStudyYears, searchStudyCountries]);

  const search = () => {
    let searchString = "?";
    const params = new URLSearchParams();

    searchStudyTypes.forEach((studyType) => params.append("study_type", studyType.value));
    searchSectors.forEach((sector) => params.append("sectors", sector.value));
    searchStudyYears.forEach((studyYear) => params.append("year", studyYear.value));
    searchStudyCountries.forEach((studyCountry) => params.append("country", studyCountry.value));

    if (searchBar !== "") {
      params.append("search", searchBar);
    }

    searchString = searchString + params.toString();
    // console.log(searchString, searchStudyCountries);
    props.search(searchString);
  };

  return (
    <Col className="col-lg-4 col-12 border px-0 h-100">
      <div className={`h-100 px-4 py-2 ${props.inline ? "" : "cont"}`}>
        <Row className="justify-content-center mt-2">
          <h1 className="text-center text-secondary">
            <span className="d-sm-none d-block h1">
              Proxxi INTEL <br />
            </span>
            <span className="d-sm-none d-block h6">ETUDES OPERATIONNELLES</span>
          </h1>
        </Row>
        <Row>
          <Col xs={12} className="my-2">
            <InputGroup>
              <FormControl
                placeholder="Titre, pays, technologie..."
                value={searchBar}
                aria-label="search-bar"
                aria-describedby="search-bar"
                onChange={(event) => setsearchBar(event.target.value)}
              />
            </InputGroup>
          </Col>
          <Col lg={12} sm={6} xs={12} className="my-2">
            <Select
              id="study-years"
              label="Année de l'étude"
              options={studyYears.map((a) => ({ label: a.year.toString(), value: a.year }))}
              value={searchStudyYears}
              onChange={setsearchStudyYears}
            />
          </Col>
          <Col lg={12} sm={6} xs={12} className="my-2">
            <Select
              id="study-sectors"
              label="Secteurs"
              options={sectors.map((a) => ({ label: a.name, value: a.id }))}
              value={searchSectors}
              onChange={setsearchSectors}
            />
          </Col>
          <Col lg={12} sm={6} xs={12} className="my-2">
            <Select
              id="study-types"
              label="Type d'étude"
              options={studyTypes.map((a) => ({ label: a.name, value: a.id }))}
              value={searchStudyTypes}
              onChange={setsearchStudyTypes}
            />
          </Col>
          <Col lg={12} sm={6} xs={12} className="mt-2 mb-2">
            <Select
              id="study-countries"
              label="Pays de l'étude"
              options={studyCountries.map((a) => ({ label: a.name, value: a.code }))}
              value={searchStudyCountries}
              onChange={setsearchStudyCountries}
            />
          </Col>
          <Col lg={12} sm={12} xs={12} className=" mt-2 text-center py-4">
            <Button
              size={props.inline ? undefined : "lg"}
              onClick={() => history.push("/profile/contact")}
              className=""
            >
              Demander une étude personnalisée <FileEarmarkText />
            </Button>
          </Col>
          <Col xs={12} className="mb-2">
            <Row>
              {props.inline ? (
                <ChevronCompactDown
                  onClick={() =>
                    document.getElementById("studies-found-number")?.scrollIntoView({ behavior: "smooth" })
                  }
                  size={50}
                  className="m-auto cursor-pointer"
                />
              ) : null}
            </Row>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default StudiesSearch;
export type { SearchState };
