import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Cart4, CartCheckFill, Check } from "react-bootstrap-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { LinkContainer } from "react-router-bootstrap";

import { Study } from "../../models/study";
import { addCartItem, useCart } from "../../utils/cart";
import { wrapAsync } from "../../utils/context";
import FlagIcon from "../../utils/flags";
import { Website } from "../../models/website";
import { useAuth } from "../../utils/auth";
import Loading from "../Loading";
import "./StudiesResults.css";
import { useHistory } from "react-router";

type StudiesResultsProps = {
  studies: Study[];
  fetchMore: () => void;
  hasMore: boolean;
  count: number;
  studiesBought: number[];
  studiesSearchComponent: JSX.Element | null;
};

type StudyCardProps = {
  study: Study;
  bought: boolean;
};

const StudiesResults: React.FC<StudiesResultsProps> = (props: StudiesResultsProps) => {
  const [bg, setBg] = useState("");

  const {
    state: { connected },
  } = useAuth();

  useEffect(() => {
    axios
      .get<Website[]>(process.env.REACT_APP_API_URL + "/website/")
      .then((res) => {
        setBg(res.data[0].studies_search_bg);
      })
      .catch(() => {
        return;
      });
  }, [connected]);

  return (
    <>
      {props.studiesSearchComponent ? (
        <div
          className="w-100 h-100 overflow-auto"
          style={{ backgroundImage: `url(${bg})`, backgroundPosition: "top" }}
          id="scrollable-studies"
        >
          <div className="bg-white w-100">{props.studiesSearchComponent}</div>
          <Col className="col-lg-8 col-12 h-100  p-4" id="scrollable-studies">
            <p className="text-white m-0" id="studies-found-number">
              Recherche : {props.count} {props.count > 1 ? "études trouvées" : "étude trouvée"}
            </p>
            <InfiniteScroll
              dataLength={props.studies.length}
              next={props.fetchMore}
              hasMore={props.hasMore}
              loader={<Loading />}
              scrollableTarget="scrollable-studies"
              scrollThreshold={0.8}
            >
              {props.studies.map((study) => (
                <StudyCard
                  key={study.id}
                  study={study}
                  bought={study.id ? props.studiesBought.includes(study.id) : false}
                />
              ))}
            </InfiniteScroll>
          </Col>
        </div>
      ) : (
        <Col
          className="col-lg-8 col-12 overflow-auto h-100 p-4"
          style={{ backgroundImage: `url(${bg})`, backgroundPosition: "top" }}
          id="scrollable-studies"
        >
          <p className="text-white m-0">
            Recherche : {props.count} {props.count > 1 ? "études trouvées" : "étude trouvée"}
          </p>
          <InfiniteScroll
            dataLength={props.studies.length}
            next={props.fetchMore}
            hasMore={props.hasMore}
            loader={<Loading />}
            scrollableTarget="scrollable-studies"
            scrollThreshold={0.8}
          >
            {props.studies.map((study) => (
              <StudyCard
                key={study.id}
                study={study}
                bought={study.id ? props.studiesBought.includes(study.id) : false}
              />
            ))}
          </InfiniteScroll>
        </Col>
      )}
    </>
  );
};

const StudyCard: React.FC<StudyCardProps> = (props: StudyCardProps) => {
  const {
    state: { connected },
  } = useAuth();
  const {
    state: { items },
    dispatch,
  } = useCart();
  const asyncDispatch = useMemo(() => wrapAsync(dispatch), [dispatch]);

  const history = useHistory();

  const inCart = items.filter((i) => i.study.id === props.study.id).length > 0;

  return (
    <Col className="h-100">
      <LinkContainer to={`/study/${props.study.id}`}>
        <Card className="w-100 h-100 study-card my-4">
          <Card.Body style={{ minHeight: "100px" }}>
            <Row>
              <Col>
                <Card.Title>{props.study.title}</Card.Title>
              </Col>
              {props.study.country?.code ? (
                <Col xs="auto">
                  <FlagIcon className="border" code={props.study.country?.code?.toLowerCase()} />
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col>
                <Card.Subtitle className="text-muted">{props.study.subtitle}</Card.Subtitle>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="bg-light p-0">
            <Row className="px-0 py-2 m-0">
              <Col xs={7} className="text-muted d-flex align-items-center">
                <span className="d-inline d-sm-none">
                  {props.study.publication_date}
                  {props.study.pages && " | " + props.study.pages + " page" + (props.study.pages > 1 ? "s" : "")}
                </span>
                <span className="d-none d-sm-inline">
                  {props.study.publication_date}
                  {props.study.pages && " | " + props.study.pages + " page" + (props.study.pages > 1 ? "s" : "")}
                  {props.study.study_type && " | " + props.study.study_type.name}
                </span>
              </Col>
              <Col xs={2} className="display-6 d-flex align-items-center justify-content-end text-nowrap">
                {props.study.price ? props.study.price + " € " : ""}
              </Col>
              <Col xs={3} className="p-0">
                <Button
                  className="btn-primary buy-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (connected) {
                      asyncDispatch(addCartItem(props.study, connected));
                    } else {
                      history.push("/login");
                    }
                  }}
                  disabled={connected && props.bought}
                  active={connected && inCart && !props.bought}
                  block
                >
                  <span className="d-none d-lg-inline">
                    {connected && props.bought ? "Déjà achetée" : inCart ? "Dans votre panier " : "Ajouter au panier "}
                  </span>
                  {connected && props.bought ? (
                    <Check size={20} />
                  ) : inCart ? (
                    <CartCheckFill size={20} />
                  ) : (
                    <Cart4 size={20} />
                  )}
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </LinkContainer>
    </Col>
  );
};

export default StudiesResults;
