import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import { Company } from "../../models/company";
import FlagIcon from "../../utils/flags";
import "./CompaniesResults.css";
import Loading from "../Loading";

type CompanyCardProps = {
  company: Company;
};

type CompaniesResultsProps = {
  companies: Company[];
  fetchMore: () => void;
  hasMore: boolean;
  count: number;
  companiesAvailable?: number;
  isSubscribed?: boolean;
};

const CompanyCard: React.FC<CompanyCardProps> = (props: CompanyCardProps) => (
  <a href={`/company/${props.company.id}`} target="_blank" rel="noopener noreferrer">
    <Card className="w-100 text-center highlightable">
      <Card.Img
        src={props.company?.logo || "https://via.placeholder.com/500x200/FAFBFC/AAAAAA?text=LOGO"}
        className="w-75 align-self-center mt-2"
        variant="top"
        style={{ objectFit: "contain", height: "30%" }}
      />
      <Card.Body>
        <Card.Text>{props.company.name}</Card.Text>
        <Row className="justify-content-center">
          {props.company.country?.code ? <FlagIcon code={props.company.country?.code.toLowerCase()} /> : null}
          <span className="text-muted font-weight-light ml-1 my-1">| {props.company.company_type?.name}</span>
        </Row>
      </Card.Body>
      <Card.Footer className="text-truncate bg-light p-3">
        {props.company.activity_domains?.map((a) => (
          <span key={a.id} className="mx-1 text-secondary">
            &#35;{a.name.split(" ").join("")}
          </span>
        ))}
      </Card.Footer>
    </Card>
  </a>
);

const HiddenCompanyCard: React.FC = () => {
  return (
    <Card className="w-100 text-center">
      <div className="hidden">
        <Card className="w-100 text-center highlightable">
          <Card.Img
            src={"https://via.placeholder.com/500x200/FAFBFC/AAAAAA?text=LOGO"}
            className="w-75 align-self-center mt-2"
            variant="top"
            style={{ objectFit: "contain", height: "30%" }}
          />
          <Card.Body>
            <Card.Text>{"test"}</Card.Text>
            <Row className="justify-content-center">
              {"test" ? <FlagIcon code="fr" /> : null}
              <span className="text-muted font-weight-light ml-1">| {"test"}</span>
            </Row>
          </Card.Body>
          <Card.Footer className="text-truncate bg-light p-3">
            {["test", "test1"]?.map((a) => (
              <span key={a} className="mx-1 text-secondary">
                &#35;{a}
              </span>
            ))}
          </Card.Footer>
        </Card>
      </div>
      <div className="shader" />
      <LinkContainer to="/subscriptions/">
        <a className="text-center font-weight-bold text-primary text-subscribe px-4">
          Pour accéder à cette fiche, souscrivez à un abonnement incluant Proxxi ID (abonnement Classic, Medium ou
          Premium).
        </a>
      </LinkContainer>
    </Card>
  );
};

const CompaniesResults: React.FC<CompaniesResultsProps> = (props: CompaniesResultsProps) => (
  <div>
    {props.companiesAvailable === 0 && props.companies.length === 0 ? (
      <div className="text-muted text-center">La recherche ne correspond à aucun résultat.</div>
    ) : (
      <>
        <p className="text-muted text-center my-0 mt-4">
          Recherche: {props.companiesAvailable} entité{(props?.companiesAvailable || 0) > 1 ? "s" : ""} trouvées
        </p>
        <InfiniteScroll
          dataLength={props.companies.length}
          className="d-flex flex-wrap justify-content-center px-md-5 px-0"
          next={props.fetchMore}
          hasMore={props.hasMore}
          loader={
            <Row className="w-100 mx-auto mb-4">
              <Col style={{ textAlign: "center" }}>
                <Loading />
              </Col>
            </Row>
          }
          scrollableTarget="scrollable-companies"
          scrollThreshold={0.8}
        >
          {props.companies.map((company) => (
            <>
              {company.published ? (
                <div className="m-4 col-auto" style={{ width: "20em", height: "290px" }} key={company.name}>
                  <CompanyCard key={company.name} company={company} />
                </div>
              ) : null}
            </>
          ))}
          {props.companiesAvailable && props.companies.length === props.count
            ? [...Array(Math.min(Math.max(0, props.companiesAvailable - props.count), 24))].map((_, id) => {
                return (
                  <div className="m-4" style={{ width: "20em", height: "290px" }} key={id}>
                    <HiddenCompanyCard key={id} />
                  </div>
                );
              })
            : null}
        </InfiniteScroll>
      </>
    )}
  </div>
);

export default CompaniesResults;
