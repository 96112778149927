import axios from "axios";
import React, { useState } from "react";
import { Container, Form, Button, Col } from "react-bootstrap";
import { useHistory } from "react-router";

const PasswordReset: React.FC = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const history = useHistory();

  const sendRequestPasswordReset = () => {
    setEmailError("");
    axios
      .post(process.env.REACT_APP_API_URL + "/password_reset/", { email })
      .then(() => history.push("/password_reset/done"))
      .catch((err) => setEmailError(err.response.data.email.join(" ")));
  };

  return (
    <Container fluid className="h-100 d-flex align-items-center">
      <Form className="w-50 bg-white m-auto shadow">
        <Col className="text-center py-2 mb-3 bg-primary text-light">RÉINITIALISER VOTRE MOT DE PASSE</Col>
        <Form.Group className="mx-4">
          <Form.Label>Entrez votre adresse email</Form.Label>
          <Form.Control
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isInvalid={emailError !== ""}
          />
          {emailError && <Form.Text className="text-danger">{emailError}</Form.Text>}
        </Form.Group>
        <Button className="m-4" variant="primary" type="button" onClick={sendRequestPasswordReset}>
          Envoyer
        </Button>
      </Form>
    </Container>
  );
};

export default PasswordReset;
