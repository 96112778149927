import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Container, Form, Modal, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { Order } from "../models/order";
import { PaginatedResponse } from "../models/response";

import { loadStripe } from "@stripe/stripe-js";
const stripePromise = process.env.REACT_APP_STRIPE_PK ? loadStripe(process.env.REACT_APP_STRIPE_PK) : null;

const OrderPage: React.VFC = () => {
  const [order, setOrder] = useState<Order>();

  const params = useParams<{ order_ref: string }>();

  useEffect(() => {
    axios
      .get<PaginatedResponse<Order>>(`${process.env.REACT_APP_API_URL}/orders/?order_ref=${params.order_ref}`)
      .then((res) => (res.data.results ? setOrder(res.data.results[0]) : null));
  }, []);

  return <>{order ? <OrderDisplay order={order} /> : null}</>;
};

const OrderDisplay: React.VFC<{ order: Order }> = (props: { order: Order }) => {
  const history = useHistory();

  const [showCGV, setShowCGV] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [CGVAccepted, setCGVAccepted] = useState(false);

  const closeCGV = () => {
    setShowCGV(false);
    setCGVAccepted(false);
  };
  const closeLoading = () => {
    setShowLoading(false);
  };
  const pay_unpaid_order = async () => {
    const stripe = await stripePromise;
    await axios
      .get(`${process.env.REACT_APP_API_URL}/stripe/recreateCheckoutSession/order/?order_ref=${props.order.order_ref}`)
      .then((res) =>
        stripe
          ? stripe.redirectToCheckout({
              sessionId: res.data.id,
            })
          : null,
      );
  };
  const cancel_order = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/stripe/cancelSession/order/?order_ref=${props.order.order_ref}`)
      .then(() => {
        history.push("/order_checkout_result/?canceled=true");
      });
  };
  return (
    <div className="overflow-auto h-100">
      <Modal show={showCGV} onHide={closeCGV}>
        <Modal.Header closeButton>{/* <Modal.Title>Conditions générales de vente</Modal.Title> */}</Modal.Header>
        <Modal.Body>
          Vous devez accepter les{" "}
          <a
            className="w-100 text-center"
            href="https://api.affinis-defense.fr/media/cgv.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <span className="d-inline">Conditions Générales de Vente</span>
          </a>
          <Form.Check
            type="checkbox"
            onChange={(e) => {
              setCGVAccepted(e.target.checked);
            }}
            label={"J'accepte les CGV"}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={`success`}
            disabled={!CGVAccepted}
            onClick={() => {
              pay_unpaid_order();
              closeCGV();
              setShowLoading(true);
            }}
          >
            Commander
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showLoading} onHide={closeLoading}>
        <Modal.Body>
          Votre commande est en cours de préparation, veuillez patienter. Vous serez bientôt rediriger vers une page de
          paiement sécurisée.
        </Modal.Body>
      </Modal>
      <Container className="mt-4 mb-4 pb-4 shadow">
        <Row className="p-4">
          <h1> Commande n°{props.order?.order_ref}</h1>
        </Row>
        <Row className="justify-content-center bg-primary text-center p-2 mb-4 text-white">Contenu</Row>
        {props.order?.studies.map((study) => (
          <LinkContainer key={study.id} to={`/study/${study.id}`}>
            <Row
              className="d-flex p-2 mt-2 mx-1 rounded border btn justify-content-start"
              style={{ cursor: "pointer" }}
            >
              <p className="mb-0">{study.title}</p>
              <p className="text-muted mb-0">{study.subtitle}</p>
            </Row>
          </LinkContainer>
        ))}
        <Row className="justify-content-center bg-primary mt-4 text-center p-2 text-white">Détails</Row>
        <Row className="ml-1 pt-2">
          <p>
            Prix : {props.order?.studies.map((study) => (study.price ? study.price : 0)).reduce((a, b) => a + b, 0)}€{" "}
            <br />
            Statut du paiement :{" "}
            {props.order?.payment_status === "PAID"
              ? "Payé"
              : props.order?.payment_status === "PENDING"
              ? "En attente de paiement"
              : props.order?.payment_status === "UNPAID"
              ? "Non payé"
              : props.order?.payment_status}
          </p>
        </Row>
        <Row className="ml-1 px-2">
          {props.order?.payment_status === "PENDING" && (
            <>
              <Button className="ml-2 btn-primary" onClick={() => setShowCGV(true)}>
                Payer
              </Button>
              <Button className="ml-2 btn-secondary" onClick={() => cancel_order()}>
                Annuler cette commande
              </Button>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export { OrderPage, OrderDisplay };
