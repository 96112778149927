import React, { useEffect, useState } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import { checkToken, useAuth } from "../utils/auth";
import "../utils/misc.css";
import Cart from "./Cart";
import Companies from "./Companies";
import CompanyPresentation from "./CompanyPresentation";
import CreateAccount from "./CreateAccount";
import { LoginComponent } from "./Login";
import Profile from "./Profile";
import Studies from "./Studies";
import Map from "./Map";
import StudyPresentation from "./StudyPresentation";
import Home from "./Home";
import { Legal } from "./Legal";
import { Privacy } from "./Privacy";
import { OrderPage } from "./Order";
import OrderCheckoutResult from "./OrderCheckoutResult";
import PasswordReset from "./PasswordReset";
import PasswordResetConfirm from "./PasswordResetConfirm";
import PasswordResetDone from "./PasswordResetDone";
import Admin from "./Admin";
import Subscriptions from "./Subscriptions";
import SubscriptionCheckoutResult from "./SubscriptionCheckoutResult";
import About from "./About";
import ProductPresentation from "./ProductPresentation";
import { LoadingPage } from "../components/LoadingPage";
import GraphMapping3D from "./GraphMapping3D";
import GraphMapping2D from "./GraphMapping2D";
import Ecosystems from "./Ecosystems";
import { Button, Modal } from "react-bootstrap";

interface ConnectedRouteProps {
  connected: boolean;
  exact?: boolean;
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<RouteComponentProps<any>>;
}

const ConnectedRoute: React.VFC<ConnectedRouteProps> = (props: ConnectedRouteProps) => {
  const [authorized, setAuthorized] = useState(true);

  useEffect(() => setAuthorized(props.connected), []);

  return (
    <Route
      path={props.path}
      exact={props.exact}
      render={(nextProps) => (authorized ? <props.component {...nextProps} /> : <Redirect to="/login" />)}
    />
  );
};

const Main: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const {
    state: { connected, isAdmin },
    dispatch,
  } = useAuth();

  useEffect(() => {
    checkToken().then((valid) => {
      if (valid) {
        dispatch({ type: "connect" });
      } else {
        dispatch({ type: "disconnect" });
      }
      setLoading(false);
    });
    const interval = setInterval(() => checkToken(), 10000); // check every 10s
    setLoaded(true);
    return () => {
      window.clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!loaded && !loading) {
      setLoaded(true);
    }
  }, [connected, isAdmin]);

  const AdminRoute: React.FC<ConnectedRouteProps> = (props: ConnectedRouteProps) => (
    <Route
      path={props.path}
      exact={props.exact}
      render={(nextProps) =>
        isAdmin ? (
          <props.component {...nextProps} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: nextProps.location } }} />
        )
      }
    />
  );

  return loading ? (
    <LoadingPage loading />
  ) : (
    // { !loading && loaded && process.env.REACT_APP_DEV_MODE === "true" && (!connected || !isAdmin) ? (
    <div className="overflow-hidden" style={{ height: "calc(100% - 56.8px)" }}>
      {process.env.REACT_APP_DEV_MODE === "true" && !connected ? (
        false && connected && !isAdmin ? (
          <Modal
            show={true}
            onHide={() => {
              dispatch({ type: "disconnect" });
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Abonnement requis</Modal.Title>
            </Modal.Header>
            <Modal.Body>Cette partie du site est réservée aux administrateurs</Modal.Body>
            <Modal.Footer>
              <a href="https://affinis-defense.fr">
                <Button
                  variant="primary"
                  onClick={() => {
                    dispatch({ type: "disconnect" });
                  }}
                >
                  Retour au site principal
                </Button>
              </a>
            </Modal.Footer>
          </Modal>
        ) : (
          <Switch>
            <Route path="/*" component={LoginComponent} />
          </Switch>
        )
      ) : (
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about/:id?" component={About} />

          <Route path="/login" component={LoginComponent} />
          <Route path="/password_reset/confirm" exact component={PasswordResetConfirm} />
          <Route path="/password_reset/done" exact component={PasswordResetDone} />
          <Route path="/password_reset" component={PasswordReset} />
          <Route path="/create_account" component={CreateAccount} />
          <Route path="/profile/:page" component={Profile} />

          <Route path="/mentionlegales" component={Legal} />
          <Route path="/politiqueconfidentialite" component={Privacy} />
          <Route path="/proxxiid/:searchString?" component={Companies} />
          <Route path="/company/:id" component={CompanyPresentation} />
          <Route path="/proxximap/" component={Map} />
          <Route path="/proxxiexplore/" component={Ecosystems} />
          <Route path="/proxxiintel/:searchString?" component={Studies} />
          <Route path="/proxxi2d/:searchString?" component={GraphMapping2D} />
          <Route path="/proxxi3d/:searchString?" component={GraphMapping3D} />
          <Route path="/study/:id" component={StudyPresentation} />
          <ConnectedRoute path="/order/:order_ref" component={OrderPage} connected={connected} />
          <AdminRoute path="/ponQGwRfNz" component={Admin} connected={connected} />
          <ConnectedRoute path="/order_checkout_result/" component={OrderCheckoutResult} connected={connected} />
          <ConnectedRoute
            path="/subscription_checkout_result/"
            component={SubscriptionCheckoutResult}
            connected={connected}
          />
          <Route path="/cart/" component={Cart} />
          <ConnectedRoute path="/subscriptions/" component={Subscriptions} connected={connected} />
          <Route path="/product/:id?" component={ProductPresentation} />
        </Switch>
      )}
    </div>
  );
};

export default Main;
