import axios, { AxiosError } from "axios";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";

import { Company } from "../models/company";
import FlagIcon from "../utils/flags";
import "./CompanyPresentation.css";
import { Website } from "../models/website";
import { LoadingPage } from "../components/LoadingPage";
import { useAuth } from "../utils/auth";
import { FileEarmarkText } from "react-bootstrap-icons";
//import MapChart from "./ExportMap";
//import MapChartSVG from "./ExportSVG";

const TitleLine = ({ text }: { text: string }) => (
  <h5 className="decorated">
    <span>{text}</span>
  </h5>
);

type showPartnerProps = {
  company: Company;
  renderFlag?: boolean;
};

const ShowPartner: React.VFC<showPartnerProps> = (props: showPartnerProps) => {
  const comp = props.company;
  if (comp?.published && comp?.country && props?.renderFlag) {
    return (
      <Link to={"../company/" + comp.id}>
        {comp.name + " "}
        <FlagIcon code={comp!.country!.iso!.toLowerCase()} />
      </Link>
    );
  }
  if (comp?.published && comp?.country && !props?.renderFlag) {
    return <Link to={"../company/" + comp.id}>{comp.name}</Link>;
  }
  if (comp?.country && props?.renderFlag) {
    return (
      <>
        {comp.name + " "}
        <FlagIcon code={comp!.country!.iso!.toLowerCase()} />
      </>
    );
  }
  return <>{comp.name}</>;
};

const CompanyPresentation: React.VFC = () => {
  const [showGetSubscribedModal, setShowGetSubscribedModal] = useState(false);
  const [company, setCompany] = useState<Company>();
  const [defaultBanner, setDefaultBanner] = useState("");
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const {
    state: { connected, isAdmin },
  } = useAuth();

  useEffect(() => {
    axios
      .get<Website[]>(`${process.env.REACT_APP_API_URL}/website/`)
      .then((res) => setDefaultBanner(res.data[0].company_default_banner))
      .catch((err) => console.log(err));
  }, []);
  /* eslint-disable */
  useEffect(() => {
    axios
      .get<Company>(`${process.env.REACT_APP_API_URL}/companies/${params.id}/`)
      .then((res) => setCompany(res.data))
      .catch((err: AxiosError) => {
        if (err.response?.status == 403) {
          setShowGetSubscribedModal(true);
        }
      });
  }, [params.id]);

  return (
    <>
      <Modal
        show={showGetSubscribedModal}
        onHide={() => {
          history.push("/");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Abonnement requis</Modal.Title>
        </Modal.Header>
        <Modal.Body>Pour accéder à cette fiche entreprise, souscrivez à un abonnement incluant Proxxi ID (abonnement Classic, Medium ou Premium).</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              history.push("/subscriptions/");
            }}
          >
            Souscrire
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid className="bg-white h-100 mb-4 pb-4" style={{ overflowY: "scroll" }}>
        <LoadingPage loading={company === undefined} />
        <Row
          className="justify-content-center align-items-center py-4 border-bottom mb-4 text-dark"
          style={{
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url(${
              company?.banner || defaultBanner
            })`,
            backgroundSize: "cover",
          }}
        >
          <Col lg={8} sm={12} className="text-left text-lg-left">
            <h1>{company?.name.toUpperCase()}</h1>
            <h6 className="mt-3 text-secondary">
              {company?.activity_domains?.map((a) => (
                <span key={a.id} className="mx-1">
                  <Link to={"/proxxiid/?search=" + encodeURIComponent(encodeURIComponent(a.name))}>&#35;{a.name.split(" ").join("")}</Link>
                </span>
              ))}
              <Link to={"/proxxiid/?country=" + company?.country?.code}>
                {company?.country?.code && <FlagIcon code={company?.country?.code.toLowerCase()} className="ml-3" />}
              </Link>
            </h6>
          </Col>
          <Col lg={{ span: 3, order: "last" }} xs={{ span: 10, order: "first" }} className="text-right">
            {company?.logo && (
              <img className="mw-100" style={{ maxHeight: "10rem" }} src={company.logo} alt={company?.name} />
            )}
          </Col>
        </Row>
        <Row className="p-1 mb-3 p-md-4 justify-content-around h-75">
          <Col lg={4} xs={10} className="my-lg-0 my-4 px-0 h-md-100 text-dark">
            <div style={{ minHeight: "50%" }}>
              <TitleLine text="Présentation" />
              <p className="text-justify">
                {parse(
                  company?.presentation?.replace(
                    "/media/django-summernote",
                    process.env.REACT_APP_API_URL + "/media/django-summernote",
                  ) || "",
                )}
              </p>
              {company?.news ? (
                <>
                  <b style={{ fontSize: "1.1rem" }}>Actualités : </b>
                  <p className="text-justify">
                    {parse(
                      company?.news?.replace(
                        "/media/django-summernote",
                        process.env.REACT_APP_API_URL + "/media/django-summernote",
                      ) || "",
                    )}
                  </p>
                </>
              ) : null}
              {company?.new_exportation && company?.new_exportation!.length > 0 ? (
                <p className="text-justify">
                  <b style={{ fontSize: "1.1rem" }}>Exportations : </b>
                  {company?.new_exportation?.map((n_e, i) => (
                    <span key={n_e.iso!}>
                      {n_e.name}
                      {i < company?.new_exportation!.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </p>
              ) : null}
            </div>
            <div className="text-justify" style={{ minHeight: "50%" }}>
              <TitleLine text="Actionnaires" />
              {company?.new_stakeholders && company?.new_stakeholders!.length > 0 ? (
                <p>
                  <b style={{ fontSize: "1.1rem" }}>Actionnaire(s) ou entité(s) référente(s) : </b>
                  {company?.new_stakeholders?.map((n_k, i) => (
                    <span key={n_k.name}>
                      <ShowPartner company={n_k} renderFlag={true} />
                      {i < company?.new_stakeholders!.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </p>
              ) : null}
            </div>
          </Col>
          <Col lg={4} xs={10} className="my-lg-0 my-4 pb-4 px-0 h-md-100">
            <div className="text-justify" style={{ minHeight: "50%" }}>
              <TitleLine text="Technologie" />
              <p className="text-justify">
                {parse(
                  company?.tech?.replace(
                    "/media/django-summernote",
                    process.env.REACT_APP_API_URL + "/media/django-summernote",
                  ) || "",
                )}
              </p>
            </div>
            <p />
            <div className="text-justify">
              <TitleLine text="Ecosystème" />
              {company?.new_public_partners && company?.new_public_partners!.length > 0 ? (
                <p>
                  <b style={{ fontSize: "1.1rem" }}>Partenaires publics <FlagIcon code={company!.country!.code!.toLowerCase()} /> : </b>
                  {company?.new_public_partners?.map((n_k, i) => (
                    <span key={n_k.name}>
                      <ShowPartner company={n_k} />
                      {i < company?.new_public_partners!.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </p>
              ) : null}
              {company?.new_private_partners && company?.new_private_partners!.length > 0 ? (
                <p>
                  <b style={{ fontSize: "1.1rem" }}>Partenaires privés locaux <FlagIcon code={company!.country!.code!.toLowerCase()} /> : </b>
                  {company?.new_private_partners?.map((n_k, i) => (
                    <span key={n_k.name}>
                      <ShowPartner company={n_k} />
                      {i < company?.new_private_partners!.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </p>
              ) : null}
              {company?.new_french_partners && company?.new_french_partners!.length > 0 ? (
                <p>
                  <b style={{ fontSize: "1.1rem" }}>Partenaires français <FlagIcon code="fr" /> : </b>
                  {company?.new_french_partners?.map((n_k, i) => (
                    <span key={n_k.name}>
                      <ShowPartner company={n_k} />
                      {i < company?.new_french_partners!.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </p>
              ) : null}
              {company?.new_international_partners && company?.new_international_partners!.length > 0 ? (
                <p>
                  <b style={{ fontSize: "1.1rem" }}>Partenaires internationaux : </b>
                  {company?.new_international_partners?.map((n_k, i) => (
                      !company?.country || (company?.country && company?.country.iso != "FR") ? (
                          <span key={n_k.name}>
                            <ShowPartner company={n_k} renderFlag={true}/>
                            {i < company?.new_international_partners!.length - 1 ? ", " : ""}
                          </span>
                      ) : null
                  ))}
                </p>
              ) : null}
              <br />
            </div>
          </Col>
          <Col
            lg={{ span: 3, order: "last" }}
            xs={{ span: 10, order: "first" }}
            className="border bg-light py-3"
            style={{ height: "min-content" }}
          >
            <h5 className="text-center">Fiche d&apos;identité</h5>
            <div className="text-left pb-4">
              {company?.country?.name ? (
                <>
                  <b style={{ fontSize: "1.1rem" }}>Pays</b>
                  <p>
                    <Link to={"/proxxiid/?country=" + company?.country?.code}>
                      {company?.country?.name + " "}
                      <FlagIcon code={company?.country?.code.toLowerCase()} /></Link>
                  </p>
                </>
              ) : null}
              {company?.company_type?.name ? (
                <>
                  <b style={{ fontSize: "1.1rem" }}>Type</b>
                  <p>{company?.company_type?.name || "NC"}</p>
                </>
              ) : null}
              {company?.new_keywords && company?.new_keywords!.length > 0 ? (
                <>
                  <b style={{ fontSize: "1.1rem" }}>Mots-clés</b>
                  <p className="text-justify">
                    {company?.new_keywords?.map((n_k, i) => (
                      <span key={n_k.name}>
                        <Link to={"/proxxiid/?search=" + encodeURIComponent(encodeURIComponent(n_k.name))}>{n_k.name}</Link>
                        {i < company?.new_keywords!.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </p>
                </>
              ) : null}
              {company?.ceo ? (
                <>
                  <b style={{ fontSize: "1.1rem" }}>Président</b>
                  <p>{company?.ceo || "NC"}</p>
                </>
              ) : null}
              {company?.employee_count ? (
                <>
                  <b style={{ fontSize: "1.1rem" }}>Nombre d&apos;employés</b>
                  <p>{company?.employee_count || "NC"}</p>
                </>
              ) : null}
              {company?.sales ? (
                <>
                  <b style={{ fontSize: "1.1rem" }}>Chiffre d&apos;affaires</b>
                  <p>{company?.sales || "NC"}</p>
                </>
              ) : null}
              {company?.address ? (
                <>
                  <b style={{ fontSize: "1.1rem" }}>Adresse</b>
                  <p className="text-justify">
                    <a href={"/proxximap/?from_comp=" + company.id} target="_blank" rel="noreferrer">
                      {company.address}
                    </a>
                  </p>
                </>
              ) : null}
              {company?.website ? (
                <>
                  <b style={{ fontSize: "1.1rem" }}>Site internet</b>
                  <p style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                    {company?.website ? (
                      <a href={company.website} target="_blank" rel="noreferrer">
                        {company.website}
                      </a>
                    ) : (
                      "NC"
                    )}
                  </p>
                </>
              ) : null}
              {company?.phone_number ? (
                <>
                  <b style={{ fontSize: "1.1rem" }}>Numéro de téléphone</b>
                  <p>{company?.phone_number || "NC"}</p>
                </>
              ) : null}
              {isAdmin && connected ? (
                <>
                  <h5 className="text-center">
                    Apercu fiche (
                    <a
                      href={
                        process.env.REACT_APP_API_URL +
                        "/Ij6p0stVRsH1SpcP/companies/company/" +
                        company?.id +
                        "/change/"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <>Editer</>
                    </a>
                    )
                  </h5>

                  <>
                    <b style={{ fontSize: "1.1rem" }}>Score actuel</b>
                    <p>{company?.score || "NC"}</p>
                  </>
                  <>
                    <b style={{ fontSize: "1.1rem" }}>Etat de publication</b>
                    <p>{company?.published ? <>Publiée</> : <>Non publiée</>}</p>
                  </>
                  <>
                    <b style={{ fontSize: "1.1rem" }}>Etat d&apos;abonnement</b>
                    <p>{company?.is_free ? <>Sans abonnement</> : <>Avec abonnement</>}</p>
                  </>
                </>
              ) : null}
            </div>

            <div className="col text-center">
              <Button
                size="lg" // props.inline ? undefined : "lg"
                onClick={() => history.push("/profile/contact?fichRens=" + company!.id)}
                className=""
              >
                Commander une fiche de renseignement <FileEarmarkText />
              </Button>
            </div>
            <br />
            <br />
          </Col>
        </Row>
        {/**
        <Row>
          <Col>
              <MapChart company={company!}/>
          </Col>
        </Row>
        */}
      </Container>
    </>
  );
};

export default CompanyPresentation;
